import React, {useState} from 'react';
import {Col, Button, Label, Row} from "reactstrap";
import Select from "react-select";
import {useSelector} from "react-redux";
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

const BankAccount = ({onNext}) => {
    const simpleStyles = {

        dropdownIndicator: () => ({
            color: '#556ee6',
            paddingRight: '10px',
            paddingLeft: '10px'
        }),
        indicatorSeparator: () => ({
            width: 0
        }),

    }
    const [country, setCountry] = useState(null);
    const staticData = useSelector(state => state?.Layout?.staticData)
    return (
        <Row className="justify-content-center">
            <Col className="p-0" lg={8}>
                <Row className="justify-content-center">
                    <Col lg={8} className="border-bottom border-light">
                        <h1 className="font-weight-light text-center">Select an account</h1>
                    </Col>
                </Row>
            </Col>
            <Col lg={8}>
                <Row className="justify-content-center mt-5">
                    <Col lg={6}>
                        <Label className="col-form-label pt-0 pt-0 font-weight-light">Where will you be sending money from?</Label>
                        <Select
                            className="border-0"
                            name="country"
                            onChange={(s) => {
                                setCountry(s?.value);
                            }}
                            styles={simpleStyles}
                            options={staticData?.countries?.map(e => ({
                                value: e.id, label: e.name
                            }))}
                        />
                        <Button onClick={() => {
                            if (country){
                            onNext(country)
                            }else {
                                toastr.error('Please select country first')
                            }
                        }} color="primary" className="btn-block mt-3">Next</Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default BankAccount;

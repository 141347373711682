import {useEffect, useState} from 'react';
import {useSelector} from "react-redux";

const useFetch = (params) => {
    const {autoFetch, initResult, action, body, onComplete} = params;
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(initResult);
    const [completed, setCompleted] = useState(false);
    const user = useSelector(state => state.Account?.user);
    const getErrorMessage = (err) => {
        if (typeof err == "string") {
            return err
        }
        return typeof err == "object" ? err?.message || err[0]?.message : "Error Occurred"
    }
    const startFetch = (data) => {
        setIsLoading(true);
        action(user?.token, data)
            .then((res) => {
                if (res.data.success) {
                    setData(res.data?.data);
                    setCompleted(true);
                    onComplete(res.data?.data)
                } else if (res.status == 200 && !(res.data.data?.error || res.data?.error)) {
                    setData(res.data);
                    setCompleted(true);
                    onComplete(res.data)
                } else {
                    setError(getErrorMessage(res.data.data?.error || res.data?.error))
                }
                setIsLoading(false);
            })
            .catch((e) => {
                console.log('reqDebug', e);
                setIsLoading(false);
            });
    };
    useEffect(() => {
        console.log('debugME', autoFetch);
        if (autoFetch) {
            startFetch(body);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return {error, isLoading, data, startFetch, completed, setData};
};

export {useFetch};

import React from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner} from "reactstrap";

const ConfirmationModal = ({isOpen, toggle, title, message, onConfirm, loading, caption = "Ok"}) => {
    return (
        <Modal isOpen={isOpen} role="dialog" autoFocus={true} centered={true} className="exampleModal" tabindex="-1"
               toggle={toggle}>
            <div className="modal-content">
                <ModalHeader toggle={toggle}>
                    {title}
                </ModalHeader>
                <ModalBody>
                    {loading ? <Row className="justify-content-center align-items-center">
                        <Spinner type="grow" className="mr-2" color="primary"/>
                    </Row> : <p>{message}</p>}
                </ModalBody>
                <ModalFooter>
                    <Button type="button" color="secondary" onClick={toggle}>Cancel</Button>
                    <Button type="button" color="primary" onClick={onConfirm}>{caption}</Button>
                </ModalFooter>
            </div>
        </Modal>
    );
};

export default ConfirmationModal;

import React, {useEffect, useState} from 'react';
import {Button, Card, CardBody, Col, Container, Input, Row, Spinner} from "reactstrap";
import {withNamespaces} from 'react-i18next';
import {Link} from "react-router-dom";
import CtList from "./ContractList";
import {getContractList} from "../../../services/api";
import {useSelector} from 'react-redux';
import NoContent from "../../../components/NoContent";
import {IntercomAPI} from "react-intercom";

const ContractList = (props) => {
    const user = useSelector(state => state.Account.user)
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        getContractList(user?.token).then(r => {
            console.log(r);
            setLoading(false)
            if (r.data.success) {
                IntercomAPI('trackEvent', 'Viewed Contracts');
                setData(r.data.data)
            }
        }).catch(e => {
            setLoading(false)
        })
    }, [])
    return (
        <div className="page-content">
            {loading ? (<Col style={{minHeight: '30rem'}}>
                <Row style={{minHeight: '30rem'}} className="justify-content-center align-items-center">
                    <Spinner type="grow" className="mr-2" color="primary"/>
                </Row>
            </Col>) : <Container fluid>
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                {data?.length == 0 ? <CtList Orders={data}/> : <>
                                    <Row className="mb-2 flex-nowrap">
                                        <Col sm="4">
                                        </Col>
                                        <Col sm="8">
                                            <div className="text-sm-right">
                                                <Link to="/contract/create">
                                                    <Button
                                                        color="success"
                                                        className="waves-effect waves-light"
                                                    ><i style={{"margin-right": "10px"}} className="fas fa-plus"></i>
                                                        New Contract
                                                    </Button>
                                                </Link>
                                            </div>
                                        </Col>
                                    </Row>
                                    <CtList Orders={data}/>
                                </>}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>}
        </div>
    );
}

export default withNamespaces()(ContractList);

import React, {useEffect, useState} from 'react';
import {Card, CardHeader, Col, Container, Input, Label, Row} from "reactstrap";
import {useDropzone} from "react-dropzone";

const ContractTypeSelect = ({onContractChanged, value, onFileUploaded}) => {
    const [selected, setSelected] = useState(value);
    const options = [
        {title: 'Use RemoteWise\'s Template', description: "Use RemoteWise's legally compliant contract template"},
        {
            title: 'Upload My Contract', description: "Click here to upload your signed contract\n" +
                "*PDF only"
        }
    ];

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });


    const {acceptedFiles, getRootProps, getInputProps, inputRef} = useDropzone();
    const files = acceptedFiles.map(file => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ));
    const removeAll = () => {
        console.log('removeAll...')
        acceptedFiles.length = 0
        acceptedFiles.splice(0, acceptedFiles.length)
        inputRef.current.value = ''
        console.log(acceptedFiles)
    }
    useEffect(() => {
        console.log(acceptedFiles);
        if (!!acceptedFiles && acceptedFiles?.length > 0) {
            toBase64(acceptedFiles[0]).then(r => {
                onFileUploaded(r)
            })
        }
    }, [acceptedFiles]);

    useEffect(() => {
        console.log("valueDebug", value, selected);
    }, [value, selected])

    return (
        <Container fluid={true}>
            <Row className="justify-content-center">
                <Col>
                    {options.map((e, i) =>
                        <a href="#">
                            <Card
                                outline color={i == selected ? "primary" : "secondary"}
                                className={i == selected ? "text-primary border" : "text-secondary border"}>
                                <CardHeader className="bg-transparent">
                                    <Row className="align-items-start pl-2">
                                        <div className="custom-control custom-radio custom-control-inline mr-2">
                                            <Input onClick={() => {
                                                if (i == 0){
                                                    removeAll()
                                                }
                                                setSelected(i);
                                                onContractChanged(i)
                                            }} type="radio" value={selected} id={`CRadioInline${i}`}
                                                   name="customRadioInline1" className="custom-control-input"/>
                                            <Label className="custom-control-label mr-0" htmlFor={`CRadioInline${i}`}>
                                                <Col>
                                                    <h5 className={i == selected ? "my-0 text-primary mb-2 bold" : "my-0 text-secondary mb-2 bold"}
                                                        style={{textJustify: 'center', alignItems: 'center'}}>
                                                        {e.title}
                                                    </h5>
                                                    <p className="text-secondary text-muted font-size-12 mb-0">{e.description}</p>
                                                </Col>
                                            </Label>
                                        </div>
                                    </Row>
                                </CardHeader>
                            </Card>
                        </a>
                    )}
                </Col>
            </Row>
            {selected == 1 && <Row className="justify-content-center">
                <Col>
                    <section className="p-0">
                        <div className="dropzone">
                            <div
                                className="dz-message needsclick"
                                {...getRootProps()}
                            >
                                <input {...getInputProps()} />

                                {!!acceptedFiles && acceptedFiles?.length > 0 ? <aside>
                                    <ul>{files}</ul>
                                </aside> : <>
                                    <div>
                                        <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                                    </div>
                                    <Row>
                                        <Col className="justify-content-center">
                                            <h4>Drop files here or click to upload.</h4>
                                            <p className="text-muted font-size-12">*PDF only</p>
                                        </Col>
                                    </Row></>}
                            </div>

                        </div>
                    </section>
                </Col>
            </Row>}
        </Container>
    );
}

export default ContractTypeSelect;

import React, {useEffect, useState} from 'react';
import {
    Badge,
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from "reactstrap";
import classnames from "classnames";
import ContractDetail from "../ContractDetail";
import PaymentHistory from "./PaymentHistory";
import Documents from "./Documents";
import NoContent from "../../../components/NoContent";
import {approveMilestone, approveWork as approve_work, getContractDetail} from "../../../services/api";
import {useSelector} from "react-redux";
import AddMilestoneModal from "../ContractDetail/components/AddMilestoneModal";
import SubmitMilestoneModal from "../ContractDetail/components/SubmitMilestoneModal";
import SubmitWorkModal from "../ContractDetail/components/SubmitWorkModal";
import DeclineModal from "../ContractDetail/components/DeclineModal";
import './style.css'
import NeedSignModal from "../ContractDetail/components/needSignModal";
import {IntercomAPI} from "react-intercom";
import ConfirmationModal from "../../../components/Common/ConfirmationModal";
const ContractPage = (props) => {
    const [activeTab, setActiveTab] = useState("5");
    const id = new URLSearchParams(props.location.search).get("id")
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const user = useSelector(state => state.Account?.user)
    const [addMilestone, setAddMilestone] = useState(false);
    const [submitMilestone, setSubmitMilestone] = useState(false);
    const [submitWork, setSubmitWork] = useState(false);
    const [milestoneToSubmit, setMilestoneToSubmit] = useState(null);
    const [needSign, setNeedSign] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [terminationDate, setTerminationDate] = useState(null);
    const getBadgeColor = (id) => {
        switch (id) {
            case 1:
                return 'primary';
            case 2:
                return 'warning';
            case 3:
                return 'success';
            case 4:
                return 'danger';
        }
    }
    const getRate = (id) => {
        console.log(id);
        switch (id) {
            case 1:
                return "Hours";
            case 2:
                return "Days";
            case 3:
                return "Months";
        }
    }
    useEffect(() => {
        switch (activeTab){
            case "6":
                IntercomAPI('trackEvent','Viewed contract - Payment', {
                    contract_id: data.contract_id,
                    contract_type: data.type
                })
                break;
            case "7":
                IntercomAPI('trackEvent','Viewed contract - Document', {
                    contract_id: data.contract_id,
                    contract_type: data.type
                })
                break;
            case "8":
                IntercomAPI('trackEvent','Viewed contract - Work submission', {
                    contract_id: data.contract_id,
                    contract_type: data.type
                })
                break;
            case "9":
                IntercomAPI('trackEvent','Viewed contract - Work submission', {
                    contract_id: data.contract_id,
                    contract_type: data.type
                })
                break;
        }
    }, [activeTab]);

    console.log("terminationDate", terminationDate);

    const updateContractDetail = () => {
        setLoading(true)
        getContractDetail(user?.token, id).then(r => {
            console.log('Contract Detail', r);
            IntercomAPI('trackEvent', 'Viewed contract', {
                contract_id: r.data.data.contract_id,
                contract_type: r.data.data.type
            });
            if (r.data.success) {
                setLoading(false)
                setData(r.data.data)
                setTerminationDate(r.data.data?.timeline?.find(e => e.name == "Contract Terminated")?.date)
            }
        }).catch(e => {
            setLoading(false)
        })
    }
    const WorkCard = ({e}) => {
        const declineWork = () => {
            setDeclineModal(true)
        }
        const approveWork = () => {

            approve_work(user?.token, {
                work_id: e.id
            }).then(r => {
                console.log(r);
                if (r.data.success) {
                    IntercomAPI('trackEvent','Approved work', {
                        contract_id: data.ref,
                        contract_type: data.type,
                        work_name: e?.name,
                        work_value: e?.qty,
                        currency: data.currency?.code
                    })
                    updateContractDetail()
                }
            })
        }
        const [declineModal, setDeclineModal] = useState(false);
        return (<Card className="overflow-hidden">
            <Col className={"border-card border-" + getBadgeColor(e.status?.id)}>
                <DeclineModal data={data} isOpen={declineModal} e={e} onDeclined={() => {
                    // IntercomAPI('trackEvent','Added work', {
                    //     contract_id: data.id,
                    //     contract_type: data.type,
                    //     work_name: v?.details,
                    //     work_value: v?.qty,
                    //     currency: contract.currency?.code
                    // })
                    updateContractDetail()
                }}
                              toggle={() => setDeclineModal(false)}/>
                <Col className="p-2">
                    <Row className="justify-content-between p-2 ">
                        <h5 className="font-weight-bold">{e.submitted_at}</h5>
                        {e.status?.id == 2 && user?.type == 'client' &&
                        <Row className="px-3">
                            <button
                                className="btn btn-danger mx-2 py-1 font-size-11 font-weight-bold rounded-lg"
                                onClick={declineWork}
                            >Decline
                            </button>
                            <button
                                className="btn btn-success py-1 font-size-11 font-weight-bold rounded-lg"
                                onClick={approveWork}
                            >Approve
                            </button>
                        </Row>
                        }
                        {e.status?.id == 2 && user?.type != 'client' && <Row>
                            <div>
                                <Badge
                                    className={"font-size-12 mx-2 rounded align-items-center badge-soft-warning"}
                                    color="warning">{e.status?.name}</Badge>
                            </div>
                        </Row>}
                        {e.status?.id == 3 && <div>
                            <Badge
                                className={"font-size-12 rounded mx-2 align-items-center badge-soft-success"}
                                color="success">{e.status?.name}</Badge>
                        </div>
                        }{e.status?.id == 4 && <div>
                        <Badge
                            className={"font-size-12 rounded mx-2 align-items-center badge-soft-danger"}
                            color="danger">{e.status?.name}</Badge>
                    </div>
                    }
                    </Row>
                    <Row className="justify-content-between px-2">
                        <h6>{e.name}</h6>
                        <h6 className="mx-2 font-weight-bold">{e.qty} {getRate(data?.rate_id?.id)}</h6>
                    </Row>
                </Col>
            </Col>
        </Card>)
    }

    const MilestoneCard = ({e, i}) => {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: data?.currency?.code,
        });
        const decline_milestone = () => {
            setDeclineModal(true)
        }
        const approve_milestone = () => {
            approveMilestone(user?.token, {
                work_id: e.id
            }).then(r => {
                if (r.data.success) {
                    updateContractDetail()
                }
                console.log(r);
            }).catch(e => {
                console.log(e);
            })
        }
        const [declineModal, setDeclineModal] = useState(false);
        return (<Card className="overflow-hidden"><Col className={"border-card border-" + getBadgeColor(e.status?.id)}>
            <DeclineModal isOpen={declineModal} toggle={() => setDeclineModal(false)} onDeclined={updateContractDetail}
                          isMilestone e={e}/>
            <Col className="p-2">
                <Row className="justify-content-between p-2">
                    <h5>Milestone {i + 1}</h5>
                    <Row className="mr-2 align-items-center">{user.type == 'client' && e.status.id == 2 &&
                    <Row className="mx-0">
                        <button
                            className="btn btn-danger mx-2 py-1 font-size-11 font-weight-bold rounded-lg"
                            onClick={decline_milestone}
                        >
                            Decline
                        </button>
                        <button
                            className="btn btn-success py-1 font-size-11 font-weight-bold rounded-lg"
                            onClick={approve_milestone}
                        >
                            Approve
                        </button>
                    </Row>}
                        {user.type == 'client' && e.status?.id == 1 && <button
                            className="text-primary btn btn-link font-weight-bold waves-effect waves-light pr-0"
                            onClick={approve_milestone}
                        >
                            <i className="bx bx-check text-primary"/> Mark as completed
                        </button>}
                        {user.type != 'client' && e.status?.id == 1 && <button
                            className="text-primary btn btn-link font-weight-bold waves-effect waves-light px-0"
                            onClick={() => {
                                if (user?.type == 'client' || data.can_submit) {
                                    setMilestoneToSubmit(e)
                                    setSubmitMilestone(true)
                                } else if (terminationDate) {
                                    setShowConfirmationModal(true)
                                } else {
                                    setNeedSign(true)
                                }
                            }}
                        >
                            Submit
                        </button>}
                        {user.type == 'client' && ![1, 2].includes(e.status.id) &&
                        <div>
                            <Badge
                                className={"font-size-12 rounded align-items-center badge-soft-" + getBadgeColor(e.status?.id)}
                                color={getBadgeColor(e.status?.id)}>{e.status?.name}</Badge>
                        </div>}
                        {user.type != 'client' && e.status.id != 1 &&
                        <div>
                            <Badge
                                className={"font-size-12 rounded align-items-center badge-soft-" + getBadgeColor(e.status?.id)}
                                color={getBadgeColor(e.status?.id)}>{e.status?.name}</Badge>
                        </div>}
                    </Row>
                </Row>
                <Row className="justify-content-between px-2">
                    <h6>{e.details}</h6>
                    <Row className="mr-2">
                        <h6 className="font-weight-bold">{formatter.format(e.amount)}</h6>
                    </Row>
                </Row>
            </Col>
        </Col>
        </Card>)
    }


    return (
        <div className="page-content">
            <Container fluid>
                <Nav tabs className="nav-tabs-custom ">
                    <NavItem>
                        <NavLink
                            style={{cursor: "pointer"}}
                            className={classnames({
                                active: activeTab === "5"
                            })}
                            onClick={() => {
                                setActiveTab("5");
                            }}
                        >
                            Contract Detail
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            style={{cursor: "pointer"}}
                            className={classnames({
                                active: activeTab === "6"
                            })}
                            onClick={() => {
                                setActiveTab("6");
                            }}
                        >
                            Payments
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            style={{cursor: "pointer"}}
                            className={classnames({
                                active: activeTab === "7"
                            })}
                            onClick={() => {
                                setActiveTab("7");
                            }}
                        >
                            Documents
                        </NavLink>
                    </NavItem>
                    {data.type == 'Milestones' && <NavItem>
                        <NavLink
                            style={{cursor: "pointer"}}
                            className={classnames({
                                active: activeTab === "8"
                            })}
                            onClick={() => {
                                setActiveTab("8");
                            }}
                        >
                            Milestones
                        </NavLink>
                    </NavItem>}
                    {data.type == 'Pay as you Go' && <NavItem>
                        <NavLink
                            style={{cursor: "pointer"}}
                            className={classnames({
                                active: activeTab === "9"
                            })}
                            onClick={() => {
                                setActiveTab("9");
                            }}
                        >
                            Submitted Work
                        </NavLink>
                    </NavItem>}
                </Nav>

                <TabContent activeTab={activeTab} className="p-0">
                    <TabPane tabId="5" className="p-3">
                        <ContractDetail history={props.history} id={id} data={data}
                                        updateContractDetail={updateContractDetail}
                                        loading={loading}
                        onShowTerminatedModal={() => setShowConfirmationModal(true)}
                        />
                    </TabPane>
                    <TabPane tabId="6" className="p-3">
                        <PaymentHistory contract={data}/>
                    </TabPane>
                    <TabPane tabId="7" className="p-3">
                        {1 == 0 ? <Documents/> :
                            <Card>
                                <CardBody>
                                    <NoContent
                                        headline={"No documents added"}
                                        subtitle={user?.type == "client" ? "All compliance documents will be shown here when added by the contractor" : "Your KYC & compliance documents will be shown here"}
                                        image={require('./../../../assets/images/empty_docs.png')}/>
                                </CardBody>
                            </Card>}
                    </TabPane>
                    <TabPane tabId="9" className="p-3">
                        <SubmitWorkModal
                            contract={data}
                            isOpen={submitWork}
                            toggle={() => setSubmitWork(false)}
                            onWorkSubmitted={updateContractDetail}
                        />
                        {data.works?.length > 0 ? <Col className="p-0">
                                {data.works?.map((e, i) => <WorkCard e={e}/>)}
                                <Row className="justify-content-center">
                                    <Button
                                        color="primary"
                                        size="lg"
                                        outline
                                        className="waves-effect waves-light rounded"
                                        onClick={() => {
                                            console.log("terminationDate", terminationDate, !!terminationDate);
                                            if (user?.type == 'client' || data?.can_submit) {
                                                setSubmitWork(true)
                                            } else if (!!terminationDate) {
                                                setShowConfirmationModal(true)
                                            } else {
                                                setNeedSign(true)
                                            }
                                        }}
                                    >
                                        Submit
                                    </Button>
                                </Row>
                            </Col> :
                            <Card>
                                <CardBody>
                                    <NoContent
                                        headline="No work submitted"
                                        subtitle="All work submissions will be shown here and will be subject to approval by the client."
                                        image={require('./../../../assets/images/empty_contract.png')}
                                        withAction actionTitle={"Submit Work"}
                                        onAction={() => {
                                            if (user?.type == 'client' || data?.can_submit) {
                                                setSubmitWork(true)
                                            } else if (terminationDate) {
                                                setShowConfirmationModal(true)
                                            } else {
                                                setNeedSign(true)
                                            }
                                        }}
                                    />
                                </CardBody>
                            </Card>}
                    </TabPane>
                    <TabPane tabId="8" className="p-3">
                        <AddMilestoneModal
                            contract={data} isOpen={addMilestone} toggle={() => setAddMilestone(false)}
                            onMilestoneAdded={updateContractDetail} currency={data?.currency}/>

                        <SubmitMilestoneModal
                            isOpen={submitMilestone}
                            toggle={() => setSubmitMilestone(false)}
                            milestone={milestoneToSubmit}
                            onMilestoneSubmitted={updateContractDetail}
                        />
                        {data?.milestones?.length > 0 ? <Col>
                                {data?.milestones?.map((e, i) => {
                                        return (<MilestoneCard e={e} i={i}/>)
                                    }
                                )}
                                <Row className="justify-content-center">
                                    <Button
                                        color="primary"
                                        size="lg"
                                        outline
                                        className="waves-effect waves-light rounded"
                                        onClick={() => {
                                            if (terminationDate){
                                                setShowConfirmationModal(true)
                                            }else {
                                                setAddMilestone(true)
                                            }
                                        }}
                                    >
                                        Add milestone
                                    </Button>
                                </Row>
                            </Col> :
                            <Card>
                                <CardBody>
                                    <NoContent
                                        headline="No work submitted"
                                        subtitle="All work submissions will be shown here and will be subject to approval by the client."
                                        image={require('./../../../assets/images/empty_contract.png')}
                                        withAction
                                        onAction={() => {
                                            setAddMilestone(true)
                                        }}
                                    />
                                </CardBody>
                            </Card>}
                            <ConfirmationModal
                                toggle={() => setShowConfirmationModal(false)}
                                isOpen={showConfirmationModal}
                                title="Contract Terminated"
                                message="You can't perform this action, this contract is terminated"
                                onConfirm={() => {
                                    setShowConfirmationModal(false)
                                }}
                            />
                    </TabPane>

                </TabContent>
                <NeedSignModal isOpen={needSign} toggle={() => setNeedSign(false)} onSignNow={() => {
                    setActiveTab("5");
                    setNeedSign(false)
                }}/>
            </Container>
        </div>
    );
};


export default ContractPage;

import React, {useEffect} from 'react';
import {CardSubtitle, CardTitle, Col, Form, FormGroup, Input, Label, Row, CardBody} from "reactstrap";
import {IntercomAPI} from "react-intercom";
import CreditCards from "../payInvoices/creditCards";

const PaymentInfo = (props) => {
    useEffect(() => {
        IntercomAPI('trackEvent', 'Viewed settings - payment info');
    }, []);

    return (
        <CardBody>
            <CardTitle>Payment information</CardTitle>
            {/*<CardSubtitle className="mb-3">Fill all information below</CardSubtitle>*/}
            <Row>
                <Col>
                    <Label className="card-radio-label mb-3">
                        <Input type="radio" name="pay-method" id="pay-methodoption1"
                               className="card-radio-input"/>

                        <div className="card-radio">
                            <i className="fab fa-cc-mastercard font-size-24 text-primary align-middle mr-2"></i>

                            <span>Credit / Debit Card</span>
                        </div>
                    </Label>
                </Col>
            </Row>

            <CreditCards edit/>

        </CardBody>
    );
}

export default PaymentInfo;

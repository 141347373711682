import React, {useEffect} from "react";
import {Route, Redirect, withRouter} from "react-router-dom";
import {useSelector, useDispatch} from 'react-redux';
import {updateDeepLink} from "../../store/auth/register/actions";
import Intercom from "react-intercom";
import { IntercomProvider } from 'react-use-intercom';
import {useFetch} from "../../helpers/hooks";
import {getContractorInfo, getClientInfo} from "../../services/api";
import {updateUserProfileInfo} from "../../store/profile/actions";

const Authmiddleware = (props) => {
    const Account = useSelector(state => state.Account)
    const user = Account?.user
    const dispatch = useDispatch()
    const {loggedIn} = Account
    const Component = props.component;
    const Layout = props.layout;
    const {history} = props

    const newProps = {...props}
    delete newProps?.layout;
    delete newProps?.component

    const userInfo = useFetch({
        autoFetch: false,
        initResult: null,
        action: user?.type == 'client' ? getClientInfo : getContractorInfo,
        body: null
    })
    useEffect(() => {
        console.log(userInfo.data);
        if (userInfo.data) {
            console.log(userInfo.data);
            dispatch(updateUserProfileInfo(userInfo.data))
        }
    }, [userInfo])
    useEffect(() => {
        if (loggedIn) {
            userInfo.startFetch(null)
        }
    }, []);

    return (
        <Route
            {...newProps}
            render={props => {
                // here you can apply condition
                if (!loggedIn) {
                    const stateFrom = history.location
                    if (stateFrom) {
                        dispatch(updateDeepLink(`${stateFrom?.pathname}${stateFrom?.search}`))
                    }
                    return (
                        <Redirect to={{pathname: "/login", state: {from: props.location}}}/>
                    );
                }

                console.log('userInfo', user, userInfo.data);
                const intercomData = {
                    name: userInfo?.data?.first_name,
                    email: userInfo?.data?.email,
                    user_id: userInfo?.data?.id,
                    type: userInfo?.data?.type,
                    "kyc-verified": userInfo?.data?.kyc_verified == 1,
                    country: userInfo?.data?.country?.name || userInfo?.data?.company?.country?.name,
                    phone: userInfo?.data?.phone,
                    "company-name": userInfo?.data?.company?.name

                }
                return (<>
                    <Layout>
                        <IntercomProvider appId="wilza99s">

                        <Component {...props} />
                        </IntercomProvider>
                    </Layout>
                    <Intercom appID="wilza99s" {...intercomData} />
                </>);
            }}
        />
    );
}

export default withRouter(Authmiddleware);


import {
    REGISTER_USER,
    REGISTER_USER_SUCCESSFUL,
    REGISTER_USER_FAILED,
    OTP_VERIFICATION,
    OTP_VERIFICATION_SUCCESSFUL,
    OTP_VERIFICATION_FAILED,
    LOGOUT,
    LOGIN_USER_SUCCESSFUL,
    UPDATE_USER_INFO,
    UPDATE_DEEP_LINK
} from './actionTypes';

export const registerUser = (type, data, token) => {
    return {
        type: REGISTER_USER,
        payload: {type, data, token},
    }
}

export const registerUserSuccessful = (user) => {
    return {
        type: REGISTER_USER_SUCCESSFUL,
        payload: user
    }
}

export const loginUserSuccessful = (user) => {
    return {
        type: LOGIN_USER_SUCCESSFUL,
        payload: user
    }
}

export const registerUserFailed = (user) => {
    return {
        type: REGISTER_USER_FAILED,
        payload: user
    }
}
export const verifyOTPRequest = (payload) => {
    return {
        type: OTP_VERIFICATION,
        payload,
    }
}

export const updateDeepLink = (payload) => {
    return {
        type: UPDATE_DEEP_LINK,
        payload,
    }
}

export const updateUserInfo = (payload) => {
    return {
        type: UPDATE_USER_INFO,
        payload,
    }
}

export const OTPSuccessful = (user) => {
    return {
        type: OTP_VERIFICATION_SUCCESSFUL,
        payload: user
    }
}

export const OTPFailed = (user) => {
    return {
        type: OTP_VERIFICATION_FAILED,
        payload: user
    }
}

export const logout = () => {
    return {
        type: LOGOUT
    }
}

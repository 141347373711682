import axios from 'axios';
import {contractTypes, userTypes} from "../helpers/enum";

const host = process.env.REACT_APP_API_HOST;
console.log(host);
const path = '/api';
const api = axios.create({
    baseURL: `${host}${path}`,
    headers: {
        'Content-Type': 'application/json',
    },
});

export const signup = (email, type) => {
    return api.post(type == userTypes.CONTRACTOR ? 'contractor/signup' : 'client/signup', {email});
};

export const login = (email, password) => {
    return api.post('/login' , {email, password});
};

export const resetPassword = (email) => {
    return api.post('/password/reset' , {email});
};

export const updatePassword = (data) => {
    return api.post('/password/update' , data);
};

export const verifyOTP = (otp, email, type) => {
    return api.post(type == userTypes.CONTRACTOR ? 'contractor/verify' : 'client/verify', {email, otp})
};

export const completeRegistration = (type, data, token) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.post(type == userTypes.CONTRACTOR ? 'contractor/update' : 'client/update', data)
};

export const getStaticData = () => {
    return api.post('static/list', {
        tables: [
            'countries',
            'frequencies',
            'rates',
            'contract_statuses',
            'contract_types',
            'company_types',
            'currencies',
            'roles',
            'occurrences',
            'document_types'
        ]
    })
};

export const getStates = (data) => {
    return api.post('static/states', data)
};

export const getCities = (data) => {
    return api.post('static/cities', data)
};

export const getCompanyInfo = (token) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.get('company/info')
}

export const getClientInfo = (token) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.get('client/info')
}

export const getContractorInfo = (token) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.get('contractor/info')
}

export const updateCompanyInfo = (token, data) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.post('company/update', data)
}

export const updateMemberProfile = (token, data) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.post('client/update', data)
}

export const updateContractorProfile = (token, data) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.post('contractor/update', data)
}

export const getUsers = (token) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.post('company/users')
}

export const requestKYC = (token) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.get('veriff/create')

}

export const sendUserInvitation = (token, data) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.post('client/invite/send', data)
}

export const verifyInvitationToken = (token) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.post('client/invite/verify')
}

export const confirmInvitation = (data) => {
    return api.post('client/invite/confirm', data)
}

export const createContract = (token, data, type) => {
    console.log(type)
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    switch (type) {
        case contractTypes.FIXED:
            return api.post('contract/fixed/create', data)
        case contractTypes.PAYG:
            return api.post('contract/payg/create', data)
        case contractTypes.MILESTONES:
            return api.post('contract/milestone/create', data)
        default:
            return
    }
}

export const getContractList = (token) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.get('contract/list');
}

export const getContractDetail = (token, id) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.get(`contract/${id}/details`)
}

export const inviteContractPart = (token, data) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.post('contract/invite', data)
}
export const signContract = (token, data) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.post('contract/signature', data)
}

export const getTWCountries = () => {
    return api.get('transferwise/countries');
}

export const getTWState = (code) => {
    return api.get(`transferwise/${code}/states`)
}

export const getTWBanks = (code) => {
    return api.get(`transferwise/${code}/banks`)
}

export const createAccount = (data) => {
    return api.post('transferwise/account/create', data)
}

export const estimateAmount = (data) => {
    return api.post('transferwise/estimate', data)
}

export const TWTransfer = (data) => {
    return api.post('transferwise/transfer', data)
}

export const getRequirements = (data) => {
    return api.post('transferwise/fields', data)
}
export const getCurrencies = () => {
    return api.get('transferwise/currencies');
}

export const addPayPalAccount = (token, email) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.post('contractor/paypal/add', {email})
}

export const deletePayPalAccount = (token, id) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.get(`contractor/paypal/${id}/delete`)
}

export const getInvoicesList = (token) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.get('invoice/list')
}
export const getUnpaidInvoicesList = (token) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.get('invoice/list/unpaid')
}
export const getInvoiceDetail = (token, id) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.get(`invoice/${id}/details`)
}
export const getOngoingContract = (token) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.get('cron/contracts/ongoing')
}
export const newPaymentMethod = (token) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.get('payment/method/new')
}
export const generateInvoice = (token, data) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.post('cron/contracts/invoices', data)
}
export const addPaymentMethod = (token, data) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.post('payment/method/add', data)
}
export const getPaymentMethods = (token) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.get('payment/method/list')

}
export const getTransactionList = (token, data) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.post('admin/transaction/list', data)
}

export const getAllPaymentList = (token, data) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.post('admin/payment/list', data)
}

export const getPaymentHistory = (token) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.get('transaction/list')
}

export const getUnpaidList = (token) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.get('payment/unpaid')
}

export const getUpcomingPaymentList = (token) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.get('payment/upcoming')
}

export const payWithCC = (token, data) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.post('payment/create/cc', data)
}

export const payWithTransfer = (token, data) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.post('payment/create/transfer', data)
}

export const downloadInvoice = (ref, token, invoiceToken) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.get(`invoice/download/${ref}/${invoiceToken}`, {responseType: 'blob',})
}

export const downloadReceipt = (ref, token, invoiceToken) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.get(`transaction/receipt/${ref}/${invoiceToken}`, {responseType: 'blob',})
}

export const downloadContract = (ref, token, contractToken) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.get(`contract/download/${ref}/${contractToken}`, {responseType: 'blob',})
}

export const getDocs = (token) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.get('contractor/documents')
}

export const addMilestone = (token, data) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.post('contract/milestone/add', data)
}

export const submitMilestone = (token, data) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.post('contract/milestone/submit', data)
}

export const submitWork = (token, data) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.post('contract/payg/submit', data)
}

export const addWork = (token, data) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.post('contract/payg/add', data)
}

export const approveMilestone = (token, data) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.post('contract/milestone/approve', data)
}

export const declineMilestone = (token, data) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.post('contract/milestone/decline', data)
}

export const approveWork = (token, data) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.post('contract/payg/approve', data)
}

export const declineWork = (token, data) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.post('contract/payg/decline', data)
}

export const createTransactionCC = (token, data) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.post('transaction/cc/create', data)
}

export const createTransactionTransfer = (token, data) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.post('transaction/transfer/create', data)
}

export const getContractPayment = (token, id) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.get(`contract/${id}/payments`)
}

export const getOverview = (token) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.get('contract/overview')
}

export const getActivity = (token) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.get('contract/activity')
}

export const getTransactionRef = (token, data) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.post('transaction/prepare', data)
}

export const confirmTransaction = (token, data) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.post('admin/transaction/confirm', data)
}

export const prepareTransaction = (token, data) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.post('transaction/transfer/prepare', data)
}
export const transferTransaction = (token, data) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.post('admin/transfer/confirm', data)
}

export const getTransferList = (token, data) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.post('admin/transfer/list', data)
}

export const getNotificationList = (token) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.get('notification/list')
}

export const prepareWithdraw = (token, data) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.post('contractor/withdraw/prepare', data)
}

export const createWithdraw = (token, data) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.post('contractor/withdraw/create', data)
}

export const getContractors = (token) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.get('company/contractors')
}

export const deleteCC = (token, data) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.get(`payment/method/${data?.id}/delete`)
}

export const getMethods = (token, data) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.post(`transaction/methods`, data)
}

export const markNotificationAsRead = (token, data) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.post('notification/global/read', data)
}

export const cancelInvitation = (token, data) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.post('contract/cancel/invitation', data)
}

export const downloadDoc = (token, data) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.get(`company/document/${data?.id}/image`, {responseType: 'blob'})
}

export const getContractorDocs = (token, data) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.get(`company/documents/${data?.token}`)
}

export const changeUserStatus = (token, data) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.post('client/status', data)
}
export const terminateContract = (token, data) => {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
    return api.post(`/contract/terminate`, data)
}










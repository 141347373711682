import React from 'react';

import {Row, Col, CardBody, Card, Alert, Container} from "reactstrap";

// Redux
import {connect} from 'react-redux';
import {withRouter, Link} from 'react-router-dom';

// availity-reactstrap-validation
import {AvForm, AvField} from 'availity-reactstrap-validation';
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";
import {useDispatch} from 'react-redux';
import {confirmInvitation, login} from "../../services/api";

const Invite = (props) => {

    const dispatch = useDispatch();

    // handleValidSubmit
    function handleValidSubmit(event, values) {
        if (values["confirm-password"] === values.password){
            confirmInvitation({
                token: new URLSearchParams(props.location.search).get("token"),
                password: values.password
            }).then(r => {
                // dispatch(loginUserSuccessful(r.data.data))
                if (r.data.success){
                    props.history.push("/login")
                }else {
                    toastr.error(r.data.message, "Error")
                }
                console.log(r);
            })
        }else {
            toastr.error("invalid password", "Error")
        }
        console.log(values);
    }

    return (
        <React.Fragment>
            <div className="account-pages my-5 pt-sm-5">
                <Row className="justify-content-center p-4">
                    <img src={require('./../../assets/images/logo-dark.png')} width={150} height={48}/>
                </Row>
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden">
                                <CardBody className="pt-0">
                                    <div className="p-2">
                                        <Row className="justify-content-center mb-3">
                                            <h4>Welcome to RemoteWise</h4>
                                        </Row>
                                        <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
                                            handleValidSubmit(e, v)
                                        }}>

                                            {props.error && props.error ?
                                                <Alert color="danger">{props.error}</Alert> : null}

                                            <div className="form-group">
                                                <AvField name="confirm-password" label="Password" type="password"
                                                         required placeholder="Enter Password"/>
                                            </div>

                                            <div className="form-group">
                                                <AvField name="password" label="Confirm Password" type="password"
                                                         required placeholder="Confirm Password"/>
                                            </div>
                                            <div className="mt-3">
                                                <button className="btn btn-primary btn-block waves-effect waves-light"
                                                        type="submit">Update Password
                                                </button>
                                            </div>
                                        </AvForm>
                                    </div>
                                </CardBody>
                            </Card>
                            <div className="mt-5 text-center">
                                <p>© {new Date().getFullYear()} RemoteWise</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    const {error} = state.Login;
    return {error};
}

export default withRouter(Invite);


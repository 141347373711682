import React, {useEffect} from 'react';
import {Card, CardBody, Col, Container, Row} from "reactstrap";
// availity-reactstrap-validation

// action
import {apiError} from "../../store/actions";

// Redux
import {connect} from "react-redux";
import {Link} from "react-router-dom";

// import images
import FormWizard from "../../components/Forms/FormWizard";

const Register = (props) => {

   useEffect(() => {
        // props.registerUserFailed("");
      });

    return (
         <React.Fragment>
        <div className="account-pages pt-5">
          <Row className="justify-content-center p-4">
            <img src={require('./../../assets/images/logo-dark.png')} width={150} height={48}/>
          </Row>
          <Container>
            <Row className="justify-content-center">
              <Col md={9} lg={16} xl={50}>
                <Card className="overflow-hidden">
                  <CardBody className="pt-0">
                    <FormWizard/>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    Already have an account ?{" "}
                    <Link
                      to="/login"
                      className="font-weight-medium text-primary"
                    >
                      {" "}
                      Login
                    </Link>{" "}
                  </p>
                  <p>
                    © {new Date().getFullYear()} RemoteWise
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
      );
    }

const mapStatetoProps = state => {
  const { user, registrationError, loading } = state.Account;
  return { user, registrationError, loading };
};

export default connect(mapStatetoProps, { apiError })(Register);

import React, {useEffect} from 'react';

import {Switch, BrowserRouter as Router, Route} from "react-router-dom";
import {connect} from "react-redux";

// Import Routes all
import {userRoutes, authRoutes, adminPanelRoutes, modalRoutes} from "./routes/allRoutes";
import {useDispatch} from 'react-redux';
// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";
import AdminLayout from "./pages/AdminPanel/components/VerticalLayout"

// Import scss
import "./assets/scss/theme.scss";


// Import Firebase Configuration file
import {initFirebaseBackend} from "./helpers/firebase_helper";

import fakeBackend from './helpers/AuthType/fakeBackend';
import {getStaticData} from "./services/api";
import {updateStaticData} from "./store/layout/actions";

// Activating fake backend
fakeBackend();

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// };

// init firebase backend
// initFirebaseBackend(firebaseConfig);

const App = (props) => {

    const dispatch = useDispatch();

    useEffect(() => {
        getStaticData().then(r => {
            if (r.data?.success) {
                dispatch(updateStaticData(r.data?.data))
            }
            console.log(r);
        })
    }, []);


    function getLayout() {
        let layoutCls = VerticalLayout;

        switch (props.layout.layoutType) {
            case "horizontal":
                layoutCls = HorizontalLayout;
                break;
            default:
                layoutCls = VerticalLayout;
                break;
        }
        return layoutCls;
    };

    const Layout = getLayout();

    const NonAuthmiddleware = ({
                                   component: Component,
                                   layout: Layout
                               }) => (
        <Route
            render={props => {
                return (
                    <Layout>
                        <Component {...props} />
                    </Layout>
                );
            }}
        />
    );

    return (
        <React.Fragment>
            <Router>
                <Switch>
                    {authRoutes.map((route, idx) => (
                        <NonAuthmiddleware
                            layout={NonAuthLayout}
                            {...route}
                            key={idx}
                        />
                    ))}

                    {adminPanelRoutes.map((route, idx) => (
                        <Authmiddleware
                            layout={AdminLayout}
                            {...route}
                            key={idx}
                        />
                    ))}

                    {userRoutes.map((route, idx) => (
                        <Authmiddleware
                            {...route}
                            layout={Layout}
                            key={idx}
                        />
                    ))}
                    {modalRoutes.map((route, idx) => (
                        <Authmiddleware
                            {...route}
                            layout={({children}) => <div>
                                {children}
                            </div>}
                            key={idx}
                        />
                    ))}

                </Switch>
            </Router>
        </React.Fragment>

    );
}


const mapStateToProps = state => {
    return {
        layout: state.Layout
    };
};

export default connect(mapStateToProps, null)(App);

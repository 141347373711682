import React from 'react';
import {useFetch} from "../../../../helpers/hooks";
import {Badge, Card, CardBody, Col, Container, Row, Spinner, Table} from "reactstrap";
import {getTransferList} from "../../../../services/api";
import {Link} from "react-router-dom";
import moment from "moment";
const ConfirmedTransfers = () => {
    const transfers = useFetch({
        autoFetch: true, initResult: null, action: getTransferList, body: {transferred: 1}
    })
    return (
        <div className="page-content">
            {transfers.isLoading ? <Container style={{minHeight: '30rem'}}>
                <Col style={{minHeight: '30rem'}}>
                    <Row style={{minHeight: '30rem'}} className="justify-content-center align-items-center">
                        <Spinner type="grow" className="mr-2" color="primary"/>
                    </Row>
                </Col>
            </Container> : <Container fluid>
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <div className="table-responsive">
                                    <Table className="table table-centered table-nowrap text-muted">
                                        <thead className="thead-light">
                                        <tr>
                                            <th className="border-top-0">TRX ID</th>
                                            <th className="border-top-0">Item ID</th>
                                            <th className="border-top-0">Contract ID</th>
                                            <th className="border-top-0">Client name</th>
                                            <th className="border-top-0">Contractor name</th>
                                            <th className="border-top-0">Amount</th>
                                            <th className="border-top-0">Payment Method</th>
                                            <th className="border-top-0">Due date</th>
                                            <th className="border-top-0">TRX date</th>
                                            <th className="border-top-0">TRX Confirmed date</th>
                                            <th className="border-top-0">Status</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {transfers?.data?.map((order, key) => {
                                            const formatter = new Intl.NumberFormat('en-US', {
                                                style: 'currency',
                                                currency: order?.currency?.code || "USD",
                                            });
                                            return <tr key={"_order_" + key}>
                                                <td>
                                                    <Link to="#"
                                                          className="text-body font-weight-bold">{order.transaction_ref}</Link>
                                                </td>
                                                <td>
                                                    {order.transaction_item_id}
                                                </td>
                                                <td>
                                                    {order.contract_ref}
                                                </td>
                                                <td>
                                                    {order.client?.first_name} {order.client?.last_name}
                                                </td>
                                                <td>
                                                    {order.contractor?.first_name} {order.contractor?.last_name}
                                                </td>
                                                <td className="text-right">
                                                    {formatter.format(order.amount)}
                                                </td>
                                                <td>
                                                    {order.method?.name}
                                                </td>
                                                <td>
                                                    {moment(order.due_date).format('MM/DD/YYYY')}
                                                </td>
                                                <td>
                                                    {moment(order.created_at).format('MM/DD/YYYY')}
                                                </td>
                                                <td>
                                                    {moment(order.confirmed_by).format('MM/DD/YYYY')}
                                                </td>
                                                <td>
                                                    <Badge
                                                        className={"font-size-12 rounded badge-success my-2"}
                                                        color={"white"}
                                                        pill>{order.transferred == 0 ? "Not transferred" : "Transferred"}</Badge>
                                                </td>
                                            </tr>
                                        })}

                                        </tbody>
                                    </Table>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>}

        </div>
    );
};

export default ConfirmedTransfers;

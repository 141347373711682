import React, {useEffect, useState} from 'react';
import {Card, CardBody, Col, Container, Row, Spinner} from "reactstrap";
import {withNamespaces} from 'react-i18next';
import InvoicesList from "./InvoicesList";
import {useSelector} from 'react-redux';
import {getInvoicesList} from "../../services/api";
import {IntercomAPI} from "react-intercom";


const Invoices = (props) => {
    const [modal, setModal] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const user = useSelector(state => state.Account?.user)
    useEffect(() => {
        getInvoicesList(user?.token).then(r => {
            if (r.data.success) {
                IntercomAPI('trackEvent', 'Viewed Invoices');
                setData(r.data.data)
            }
            setLoading(false)
            console.log(r);
        }).catch(e => {
            setLoading(false)
        })
    }, [])

    return (
        <div className="page-content">
            {loading ? <Container style={{minHeight: '30rem'}}>
                <Col style={{minHeight: '30rem'}}>
                    <Row style={{minHeight: '30rem'}} className="justify-content-center align-items-center">
                        <Spinner type="grow" className="mr-2" color="primary"/>
                    </Row>
                </Col>
            </Container> : <Container fluid>
                <Card>
                    <CardBody>
                        <InvoicesList Orders={data}/>
                    </CardBody>
                </Card>
            </Container>}
        </div>
    );
}

export default withNamespaces()(Invoices);

import React, {useEffect, useRef, useState} from 'react';

import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label,
    Row,
    TabContent,
    TabPane,
    UncontrolledTooltip
} from "reactstrap";
import {Link} from "react-router-dom";
import ContractTypeSelect from "./ContractTypeSelect";
import ContractSelect from "./ContractSelect";
import {useSelector} from 'react-redux'
import {AvField, AvForm, AvInput} from 'availity-reactstrap-validation';
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import {createContract} from "../../../services/api";
import {contractTypes} from "../../../helpers/enum";
import {withRouter} from 'react-router'
import DatePicker, {CalendarContainer} from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import moment from "moment";
import Wootric from "../../../components/Wootric/Wootric";
import {IntercomAPI} from "react-intercom";
import Quill from 'quill';
import 'react-quill/dist/quill.snow.css';

const FormWizard = ({history}) => {

    const [activeTab, setActiveTab] = useState(1);
    const [loading, setLoading] = useState(false);
    const [contractType, setContractType] = useState(-1);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(null);
    const [firstPayment, setFirstPayment] = useState(new Date());
    const [milestones, setMilestones] = useState([{
        currency_id: 1
    }]);
    const [frequencyId, setFrequencyId] = useState(-1);
    const [occurrence, setOccurrence] = useState(-1);
    const [amount, setAmount] = useState(null);
    const [currency, setCurrency] = useState({value: 1, label: 'USD'});
    const [paygType, setPaygType] = useState(null);
    const [completed, setCompleted] = useState(false);

    const options = [
        {label: 'Fixed', value: contractTypes.FIXED},
        {label: 'Pay as you go', value: contractTypes.PAYG},
        {label: 'Milestones', value: contractTypes.MILESTONES}
    ]
    const [data, setData] = useState({});
    const refForm1 = useRef(null);
    const refForm2 = useRef(null);
    const refForm3 = useRef(null);
    const types = [
        {label: 'Fixed', value: contractTypes.FIXED},
        {label: 'Pay as you go', value: contractTypes.PAYG},
        {label: 'Milestones', value: contractTypes.MILESTONES}
    ]
    const staticData = useSelector(state => state.Layout.staticData)
    const user = useSelector(state => state.Account.user)


    function toggleTab(tab) {
        if (activeTab !== tab) {
            if (tab >= 1 && tab <= 4) {
                setActiveTab(tab);
            }
        }
    }

    const QuillEditor = ({
                             onChange = () => {
                             }
                         }) => {

        useEffect(() => {
            let quill = new Quill('#editor-container');
            const handleTextChange = (delta, oldDelta, source) => {
                if (source == 'user') {
                    // const html = document.getElementById('#editor-container').root.innerHTML
                    console.log(quill.container.firstChild.innerHTML);
                    onChange(quill.container.firstChild.innerHTML)
                }
            }
            quill.on('text-change', handleTextChange);
            return () => {
                quill.off('text-change', handleTextChange);
            }
        }, [])
        return <div className="form-control p-0" id="editor-container" style={{minHeight: '200px'}}/>
    }

    const InfoForm = () => {
        const [scope, setScope] = useState(null);

        return (<div>
            <h4 className="card-title mb-4">Contract</h4>
            <AvForm ref={refForm1} onValidSubmit={(e, v) => {
                if (contractType != -1) {
                    IntercomAPI('trackEvent', 'Create-contract step 1', {
                        "contract-type": types[contractType]?.label,
                        "contract-name": v?.name
                    });
                    setData({...data, ...v, scope,})
                    toggleTab(activeTab + 1);
                } else {
                    toastr.error("Contract type is missing", "error")
                }
            }}>
                <Row className="justify-content-center">
                    <Col className="p-0">
                        <ContractTypeSelect
                            onContractTypeChanged={type => {
                                setContractType(type)
                            }}
                            value={contractType}
                        />
                    </Col>
                </Row>
                {contractType != -1 &&
                <Row className="justify-content-center">
                    <Col>
                        <FormGroup>
                            <Label className="text-secondary">Contract
                                Name</Label>
                            <AvField required name="name" type="text"
                                     className="form-control"
                                     id="basicpill-pancard-input5"/>
                        </FormGroup>
                        <FormGroup>
                            <Label className="text-secondary">
                                Scope of Work</Label>
                            <QuillEditor onChange={setScope}/>
                        </FormGroup>
                    </Col>
                </Row>
                }
            </AvForm>
        </div>)
    }

    const MilestonesForm = () => {
        return (<Row>
            <Col>
                <AvForm ref={refForm2} onValidSubmit={(e, v) => {
                    console.log(v);
                    setData({...data, ...v})
                    toggleTab(activeTab + 1);
                }}>
                    <Row>

                        <Col xl={4}>
                            <AvField
                                type="select"
                                name={`currency_id`}
                                className="input-group-text form-control"
                                style={{
                                    // height: '70%',
                                    marginLeft: "-2px"
                                }}
                                value={1}>
                                {staticData?.currencies.map(e =>
                                    <option
                                        value={e.id}>{e.code}</option>)}
                            </AvField>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {milestones.map((e, i) =>
                                <Row
                                    className="justify-content-center">
                                    <Col>
                                        <FormGroup>
                                            <AvField
                                                name={`name-${i}`}
                                                placeholder="Milestone Name"
                                                type="text"
                                                defaultValue={e.name}
                                                className="form-control"
                                                onChange={(e) => {
                                                    const newMilestones = [...milestones];
                                                    newMilestones[i].name = e.target.value
                                                }}
                                                id="basicpill-pancard-input5"/>
                                        </FormGroup>

                                    </Col>
                                    <Col xl={3}>
                                        <FormGroup>
                                            <AvField
                                                name={`amount-${i}`}
                                                type="number"
                                                placeholder="Amount"
                                                id="basicpill-pancard-input7"
                                                className="form-control"
                                                defaultValue={e.amount}
                                                onChange={(e) => {
                                                    const newMilestones = [...milestones];
                                                    newMilestones[i].amount = e.target.value
                                                }}
                                            />
                                        </FormGroup>

                                    </Col>
                                    {milestones.length > 1 &&
                                    <Col xl={1} className="pt-1">
                                        <Link to="#"
                                              onClick={() => {
                                                  setMilestones(milestones.filter((_, index) => i != index))
                                              }}>
                                            <i className="bx bx-trash text-danger font-size-24 mr-1"
                                               id="deletetooltip"></i>
                                        </Link>
                                    </Col>}
                                </Row>
                            )
                            }
                            <Row
                                className="justify-content-center mb-2">
                                <Col>
                                    <Row
                                        className="justify-content-center">
                                        <Col lg="4">
                                            <Button
                                                color="primary"
                                                size="lg"
                                                outline
                                                className="waves-effect waves-light"
                                                onClick={() => {
                                                    console.log(milestones);
                                                    setMilestones([...milestones, {}])
                                                }}
                                            >
                                                Add milestone
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                        </Col>
                    </Row>

                </AvForm>
            </Col>
        </Row>)
    }

    const ComplianceForm = () => {
        const [notice, setNotice] = useState(15);
        const [file, setFile] = useState(null);
        const [contractMedia, setContractMedia] = useState(-1);
        return (<AvForm
            ref={refForm3}
            onValidSubmit={(e, v) => {
                if (contractMedia == -1) {
                    toastr.error("Contract is missing", "error")
                    return
                }
                setLoading(true)
                IntercomAPI('trackEvent', 'Create-contract step 3', {
                    "contract-type": types[contractType]?.label,
                    "contract-name": v?.name,
                    "currency": currency?.label
                });

                console.log(milestones);
                createContract(user?.token, {
                    ...data,
                    ...v,
                    notice_period: notice,
                    extra: "extra",
                    client_can_submit: true,
                    milestones,
                    file
                }, options[contractType].value).then(r => {
                    setLoading(false)
                    console.log(r);
                    if (r.data.success) {
                        toastr.success('Contract created successfully')
                        const intercomData = {
                            "contract-id": r.data.data?.ref,
                            "contract-type": types[contractType]?.label,
                            "contract-name": v?.name,
                            "currency": currency?.label
                        }
                        console.log("intercomData", intercomData);
                        IntercomAPI('trackEvent', 'Create-contract step 3');
                        setCompleted(true)
                        history.push(`/contract/detail?id=${r.data.data?.ref}`)
                    } else {
                        toastr.error(r.data?.message || "An error occurred", 'error')
                    }
                }).catch(e => {
                    setLoading(false)
                    toastr.error('An error occurred', 'error')
                })
                setData({...data, ...v})
            }}>
            <Row className="justify-content-center">
                <Col>
                    <Row className="justify-content-center">
                        <ContractSelect
                            onContractChanged={i => {
                                setContractMedia(i)
                                if (i == 0) {
                                    setFile(null)
                                }
                            }}
                            value={contractMedia}
                            onFileUploaded={setFile}
                        />
                    </Row>
                    <Row className="justify-content-center">
                        <Col>
                            <FormGroup className="border-top pt-3">
                                <Label for="basicpill-pancard-input6"
                                       className="text-muted">Notice
                                    period (days) to terminate the
                                    contract</Label>
                                <InputGroup>
                                    <Input
                                        // name="notice_period"
                                        type="number"
                                        id="noticeDays"
                                        className="form-control"
                                        value={notice}
                                        onChange={e => {
                                            setNotice(e.target.value)
                                        }}
                                    />
                                    <InputGroupAddon
                                        addonType="append">
                                        <InputGroupText>Days</InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>

                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col>
                            <FormGroup className="border-top pt-3">
                                <div
                                    className="custom-control custom-switch mb-2"
                                    dir="ltr">
                                    <AvInput name="kyc"
                                             type="checkbox"
                                             className="custom-control-input"
                                             id="customSwitchKyc"
                                    />
                                    <label
                                        className="custom-control-label text-muted"
                                        htmlFor="customSwitchKyc">
                                        <span>Require KYC verification <i id="kycReq"
                                                                          className="bx bx-info-circle"/></span>
                                        <UncontrolledTooltip
                                            placement="top"
                                            target="kycReq">
                                            Require contractor compliance & KYC verification based on their tax
                                            residence.
                                        </UncontrolledTooltip>
                                    </label>
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </AvForm>)
    }

    useEffect(() => {
        console.log(data);
    }, [data])

    return (
        <>
            <div>
                <Container fluid={true}>
                    <Row className="justify-content-center">
                        <Col>
                            <Card>
                                <CardBody>
                                    <Row className="justify-content-center">
                                        <Col xl={12} className="justify-content-center">
                                            <Row className="justify-content-center mt-3">
                                                <Col xl={9}>
                                                    <Row className="align-items-center">
                                                        {activeTab == 1 && <span className="bg-primary"
                                                                                 style={{
                                                                                     width: 10,
                                                                                     height: 10,
                                                                                     borderRadius: 5
                                                                                 }}/>}
                                                        <Col
                                                            className={activeTab >= 2 ? "bg-primary" : "bg-soft-secondary"}
                                                            style={{height: 2}}/>
                                                        {activeTab == 2 && <span className="bg-primary"
                                                                                 style={{
                                                                                     width: 10,
                                                                                     height: 10,
                                                                                     borderRadius: 5
                                                                                 }}/>}
                                                        <Col
                                                            className={activeTab >= 3 ? "bg-primary" : "bg-soft-secondary"}
                                                            style={{height: 2}}/>
                                                        {activeTab == 3 && <span className="bg-primary"
                                                                                 style={{
                                                                                     width: 10,
                                                                                     height: 10,
                                                                                     borderRadius: 5
                                                                                 }}/>}
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row className="justify-content-center">
                                                <Col xl={11}>
                                                    <Row
                                                        className="justify-content-between pt-3 flex-nowrap">
                                                        <Col xl={2}
                                                             className={`${activeTab >= 1 ? "text-primary" : "text-muted"}`}
                                                             style={{textAlign: 'center'}}>Info</Col>
                                                        <Col xl={2}
                                                             className={`${activeTab >= 2 ? "text-primary" : "text-muted"}`}
                                                             style={{textAlign: 'center'}}>Payment</Col>
                                                        <Col xl={2}
                                                             className={`${activeTab >= 3 ? "text-primary" : "text-muted"}`}
                                                             style={{textAlign: 'center'}}>Compliance</Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-center mt-3">
                                        <Col lg={8}>
                                            <div id="basic-pills-wizard" className="twitter-bs-wizard">
                                                <TabContent activeTab={activeTab}
                                                            className="twitter-bs-wizard-tab-content">
                                                    <TabPane tabId={1}>
                                                        <InfoForm/>
                                                    </TabPane>
                                                    {contractType != 2 ? <TabPane tabId={2}>
                                                            <AvForm ref={refForm2} className="align-items-center"
                                                                    onValidSubmit={(e, v) => {

                                                                        if (amount && firstPayment && frequencyId && startDate && currency && occurrence) {
                                                                            IntercomAPI('trackEvent', 'Create-contract step 2', {
                                                                                "contract-type": types[contractType]?.label,
                                                                                "contract-name": v?.name
                                                                            });
                                                                            setData({
                                                                                ...data, ...v,
                                                                                amount,
                                                                                first_payment_date: moment(firstPayment?.value, 'dddd, Do MMM').format('YYYY-MM-DD'),
                                                                                frequency_id: frequencyId?.value,
                                                                                start_date: moment(startDate).format('YYYY-MM-DD'),
                                                                                currency_id: currency?.value,
                                                                                occurrence_id: occurrence?.value,
                                                                                rate_id: paygType,
                                                                                end_date: endDate ? moment(endDate).format('YYYY-MM-DD') : null,
                                                                            })
                                                                            toggleTab(activeTab + 1);
                                                                        } else {
                                                                            toastr.error('You need to fill all required fields')
                                                                        }
                                                                    }}>
                                                                <FormGroup>
                                                                    <Label className="col-form-label pt-0 pt-0">Start Date
                                                                        *</Label>
                                                                    <Col className="p-0">
                                                                        <DatePicker
                                                                            name="start-date"
                                                                            className="form-control"
                                                                            selected={startDate}
                                                                            onChange={setStartDate}
                                                                            style={{width: '100%'}}
                                                                            popperPlacement="top-start"
                                                                            popperModifiers={{
                                                                                offset: {
                                                                                    enabled: true,
                                                                                    offset: "5px, 10px"
                                                                                },
                                                                                preventOverflow: {
                                                                                    enabled: true,
                                                                                    escapeWithReference: false,
                                                                                    boundariesElement: "viewport"
                                                                                }
                                                                            }}
                                                                            customInput={<CustomInput/>}
                                                                        />
                                                                        <CalendarIcon/>
                                                                    </Col>
                                                                </FormGroup>

                                                                <FormGroup>
                                                                    <Label className="col-form-label pt-0">End Date</Label>
                                                                    <Col className="p-0">
                                                                        <DatePicker
                                                                            autocomplete="autocomplete_off_hack_xfr4!k"
                                                                            name="end-date"
                                                                            className="form-control"
                                                                            minDate={startDate}
                                                                            selected={endDate}
                                                                            onChange={setEndDate}
                                                                            popperPlacement="top-start"
                                                                            popperModifiers={{
                                                                                offset: {
                                                                                    enabled: true,
                                                                                    offset: "5px, 10px"
                                                                                },
                                                                                preventOverflow: {
                                                                                    enabled: true,
                                                                                    escapeWithReference: false,
                                                                                    boundariesElement: "viewport"
                                                                                }
                                                                            }}
                                                                            customInput={<CustomInput/>}
                                                                            placeholderText="(Optional)"/>
                                                                        <CalendarIcon/>
                                                                    </Col>
                                                                </FormGroup>
                                                                <FormGroup>
                                                                    <Label className="col-form-label pt-0">Amount *</Label>
                                                                    <InputGroup>
                                                                        <Input
                                                                            placeholder="Amount" name="amount"
                                                                            type="number"
                                                                            onChange={(e) => {
                                                                                console.log(e.target.value);
                                                                                setAmount(e.target.value)
                                                                            }}/>
                                                                        <InputGroupAddon
                                                                            style={{width: '25%', zIndex: 2}}
                                                                            addonType="append">
                                                                            <InputGroupText className="p-0"
                                                                                            style={{width: '100%'}}>
                                                                                <div style={{width: '100%'}}>
                                                                                    <Select
                                                                                        className="border-0"
                                                                                        name="currency_id"
                                                                                        value={currency}
                                                                                        onChange={(s) => {
                                                                                            setCurrency(s);
                                                                                        }}
                                                                                        isSearchable={false}
                                                                                        styles={customStyles}
                                                                                        options={staticData?.currencies?.map(e => ({
                                                                                            value: e.id, label: e.code
                                                                                        }))}
                                                                                    />
                                                                                </div>
                                                                            </InputGroupText>
                                                                        </InputGroupAddon>
                                                                    </InputGroup>

                                                                </FormGroup>


                                                                {contractType == 1 &&
                                                                <FormGroup>
                                                                    <label className="col-form-label pt-0">Unit (of work)
                                                                        *</label>
                                                                    <div>
                                                                        <Select
                                                                            className="border-0"
                                                                            name="rate_id"
                                                                            onChange={(s) => {
                                                                                setPaygType(s?.value);
                                                                            }}
                                                                            isSearchable={false}
                                                                            styles={simpleStyles}
                                                                            options={staticData?.rates?.map(e => ({
                                                                                value: e.id, label: e.name
                                                                            }))}
                                                                        />
                                                                    </div>
                                                                </FormGroup>}
                                                                <FormGroup style={{zIndex: 9}}>
                                                                    <label
                                                                        className="col-form-label pt-0">Frequency *</label>
                                                                    <Select
                                                                        className="border-0"
                                                                        name="currency_id"
                                                                        onChange={(s) => {
                                                                            setFrequencyId(s);
                                                                            setOccurrence(null)
                                                                            setFirstPayment(null)
                                                                        }}
                                                                        isSearchable={false}
                                                                        value={frequencyId}
                                                                        styles={simpleStyles}
                                                                        options={staticData?.frequencies?.map(e => ({
                                                                            value: e.id, label: e.name
                                                                        }))}
                                                                    />
                                                                </FormGroup>

                                                                {frequencyId != -1 && <FormGroup style={{zIndex: 8}}>
                                                                    <Label
                                                                        className="col-form-label pt-0">Occurrence *</Label>
                                                                    <Select
                                                                        className="border-0"
                                                                        name="currency_id"
                                                                        onChange={(s) => {
                                                                            setOccurrence(s);
                                                                            setFirstPayment(null)
                                                                        }}
                                                                        isSearchable={false}
                                                                        value={occurrence}
                                                                        styles={simpleStyles}
                                                                        options={staticData?.occurrences.filter(o => o.frequency_id == frequencyId?.value)?.map(e => ({
                                                                            value: e.id, label: e.name
                                                                        }))}
                                                                    />
                                                                </FormGroup>}

                                                                {occurrence != -1 && <FormGroup style={{zIndex: 8}}>
                                                                    <Label
                                                                        className="col-form-label pt-0">First
                                                                        Payment *</Label>
                                                                    <Select
                                                                        className="border-0"
                                                                        name="first_payment"
                                                                        onChange={(s) => {
                                                                            setFirstPayment(s);
                                                                        }}
                                                                        isSearchable={false}
                                                                        value={firstPayment}
                                                                        styles={simpleStyles}
                                                                        options={staticData?.occurrences.find(o => o.id == occurrence?.value)?.dates?.map(e => ({
                                                                            value: e, label: e
                                                                        }))}
                                                                    />
                                                                </FormGroup>}
                                                                {contractType == 0 &&
                                                                <FormGroup className="border-top pt-2">
                                                                    <div className="custom-control custom-switch mb-2"
                                                                         dir="ltr">
                                                                        <AvInput
                                                                            name="first_payment_prorata"
                                                                            type="checkbox"
                                                                            className="custom-control-input"
                                                                            id="customSwitch1" defaultChecked/>
                                                                        <label className="custom-control-label"
                                                                               htmlFor="customSwitch1">
                                                                            <span>Prorata on the first payment <i
                                                                                id="prorata"
                                                                                className="bx bx-info-circle"/></span>
                                                                            <UncontrolledTooltip
                                                                                placement="top"
                                                                                target="prorata">
                                                                                If enabled, the first
                                                                                payment will be calculated based on the period
                                                                                worked during the first cycle
                                                                            </UncontrolledTooltip>
                                                                        </label>
                                                                    </div>
                                                                </FormGroup>}
                                                            </AvForm>
                                                        </TabPane> :
                                                        <TabPane tabId={2}>
                                                            <MilestonesForm/>
                                                        </TabPane>}
                                                    <TabPane tabId={3}>
                                                        <ComplianceForm/>
                                                    </TabPane>
                                                </TabContent>
                                                {contractType != -1 && <Row>
                                                    <Col>
                                                        <button
                                                            className="btn btn-primary btn-block waves-effect waves-light"
                                                            onClick={() => {
                                                                if (activeTab == 1) {
                                                                    refForm1.current.submit()
                                                                }
                                                                if (activeTab == 2) {
                                                                    refForm2.current.submit()
                                                                }
                                                                if (activeTab == 3 && !loading) {
                                                                    refForm3.current.submit()
                                                                }
                                                            }}>{loading &&
                                                        <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>}{activeTab == 3 ? "Confirm" : "Continue"}
                                                        </button>
                                                    </Col>
                                                </Row>}
                                                {activeTab != 1 &&
                                                <Row className="p-0">
                                                    <ul className="pager wizard twitter-bs-wizard-pager-link">
                                                        <Link onClick={() => {
                                                            if (activeTab != 1) {
                                                                toggleTab(activeTab - 1);
                                                            }
                                                        }} className="btn-lg text-muted btn-link font-size-13">
                                                            <i className="mdi mdi-arrow-left mr-1"></i> Back </Link>

                                                    </ul>
                                                </Row>}
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
                {completed && <Wootric/>}
            </div>
        </>
    );
}
export const CustomInput = ({ value, onClick, placeholder }) => {
    return(<div className="form-control" onClick={onClick}>{value || placeholder}</div>)
}
export const CalendarIcon = () => <div style={{
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0
}}>
    <Row className="m-0 p-0 px-2 align-items-center h-100"><i
        className='bx bx-calendar font-size-16 text-primary'/></Row>
</div>

const customStyles = {

    dropdownIndicator: () => ({
        color: '#325ae7',
        paddingRight: '10px',
        paddingLeft: '10px'
    }),
    indicatorSeparator: () => ({
        width: 0
    }),
    control: () => ({
        display: 'flex',
        flexDirection: 'row',
    }),

}
const simpleStyles = {

    dropdownIndicator: () => ({
        color: '#325ae7',
        paddingRight: '10px',
        paddingLeft: '10px'
    }),
    indicatorSeparator: () => ({
        width: 0
    }),

}

export default withRouter(FormWizard);

import React from 'react';

import {Alert, Card, CardBody, Col, Container, Row} from "reactstrap";

// Redux
import {useDispatch, useSelector} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';

// availity-reactstrap-validation
import {AvField, AvForm} from 'availity-reactstrap-validation';

// actions
import {loginUserSuccessful, updateDeepLink} from '../../store/actions';

// import images
import {login} from "../../services/api";
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import './style.css'

const Login = (props) => {

    const dispatch = useDispatch();
    const link = useSelector(state => state.Account?.link)

    // handleValidSubmit
    function handleValidSubmit(event, values) {
        console.log(values);
        login(values.email, values.password).then(r => {
            console.log(r);
            if (r.data.success) {
                dispatch(loginUserSuccessful(r.data.data))
                if (link && (typeof link) == 'string') {
                    props.history.push(link)
                    dispatch(updateDeepLink(null))
                } else {
                    props.history.push("/activity")
                }
            } else {
                toastr.error(r.data?.data?.error)
            }
        })
        // props.loginUser(values, props.history);
    }
    return (
        <React.Fragment>
            <div className={`account-pages ${link?.startsWith('/contract/detail') ? "blur-image" : "blur-container"}`}>
                <Row className="align-items-center blur-container">
                    <Col>
                        <Row className="justify-content-center py-4">
                            <img src={link?.startsWith('/contract/detail') ? require('./../../assets/images/logo-light.png') : require('./../../assets/images/logo-dark.png')} width={150} height={48}/>
                        </Row>
                        <Container>
                            <Row className="justify-content-center">
                                <Col md={8} lg={6} xl={5}>
                                    <Card className="overflow-hidden pt-3">
                                        <CardBody className="pt-0">
                                            <div className="p-2">
                                                <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
                                                    handleValidSubmit(e, v)
                                                }}>

                                                    {props.error && props.error ?
                                                        <Alert color="danger">{props.error}</Alert> : null}

                                                    <div className="form-group">
                                                        <AvField name="email" label="Email"
                                                                 className="form-control" placeholder="Enter email"
                                                                 type="email"
                                                                 required/>
                                                    </div>

                                                    <div className="form-group">
                                                        <AvField name="password" label="Password" type="password"
                                                                 required placeholder="Enter Password"/>
                                                    </div>

                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input"
                                                               id="customControlInline"/>
                                                        <label className="custom-control-label"
                                                               htmlFor="customControlInline">Remember
                                                            me</label>
                                                    </div>

                                                    <div className="mt-3">
                                                        <button
                                                            className="btn btn-primary btn-block waves-effect waves-light"
                                                            type="submit">Log In
                                                        </button>
                                                    </div>

                                                    <div className="mt-4 text-center">
                                                        <Link to="/forgot-password" className="text-muted"><i
                                                            className="mdi mdi-lock mr-1"></i> Forgot your
                                                            password?</Link>
                                                    </div>
                                                </AvForm>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <div className={`mt-5 text-center ${link?.startsWith('/contract/detail') ? "text-white" : ""}`}>
                                        <p>New to RemoteWise? <Link to="register"
                                                                         className="font-weight-medium text-primary"> Sign
                                            up
                                            here </Link></p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    const {error} = state.Login;
    return {error};
}

export default withRouter(Login);


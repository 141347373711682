import React, {useState} from 'react';
import {
    Col,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label,
    Modal,
    Row,
    Spinner
} from "reactstrap";
import {AvField, AvForm} from "availity-reactstrap-validation";
import {addMilestone} from "../../../../services/api";
import {useSelector} from "react-redux";

const AddMilestoneModal = ({isOpen, toggle, contract, onMilestoneAdded}) => {
    const user = useSelector(state => state.Account.user)
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState(null);
    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
        >

            <AvForm onValidSubmit={(e, v) => {
                setLoading(true)
                addMilestone(user.token, {
                    ...v, contract_id: contract.id, amount: value
                }).then(r => {
                    setLoading(false)
                    if (r.data.success){
                        toggle();
                        onMilestoneAdded()
                    }
                    console.log(r);
                }).catch(e => {
                    setLoading(false)
                })
            }}>
                <div className="modal-content">
                    {loading ?
                        (<Col style={{minHeight: '10rem'}}>
                            <Row style={{minHeight: '10rem'}} className="justify-content-center align-items-center">
                                <Spinner type="grow" className="mr-2" color="primary"/>
                            </Row>
                        </Col>) : <>
                            <div className="modal-header">
                                <h5 className="modal-title mt-0">Add milestone</h5>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={toggle}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <Row
                                    className="justify-content-center">
                                    <Col>
                                        <FormGroup>
                                            <Label>Name</Label>
                                            <AvField
                                                name={`name`}
                                                placeholder="Milestone Name"
                                                type="text"
                                                className="form-control"
                                                required
                                                id="basicpill-pancard-input5"/>
                                        </FormGroup>

                                        <FormGroup>
                                            <Label>Amount</Label>
                                            <InputGroup>
                                                <Input
                                                    placeholder="Value" name="amount"
                                                    type="number"
                                                    required
                                                    onChange={(e) => {
                                                        setValue(e.target.value)
                                                    }}/>
                                                <InputGroupAddon addonType="append">
                                                    <InputGroupText>{contract?.currency?.code}</InputGroupText>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </FormGroup>

                                    </Col>

                                </Row>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="submit"
                                    className="btn btn-primary">
                                    Add
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-dismiss="modal"
                                    onClick={toggle}
                                >
                                    Cancel
                                </button>
                            </div>
                        </>}
                </div>
            </AvForm>

        </Modal>
    );
};

export default AddMilestoneModal;

import React, {useState, useRef} from 'react';
import {Link, withRouter} from "react-router-dom";
import {Col, Dropdown, DropdownMenu, DropdownToggle, Row} from "reactstrap";
import SimpleBar from "simplebar-react";

//Import images

//i18n
import {withNamespaces} from 'react-i18next';
import {useFetch} from "../../../helpers/hooks";
import {getNotificationList, markNotificationAsRead} from "../../../services/api";
import moment from "moment";


const NotificationDropdown = (props) => {

    // Declare a new state variable, which we'll call "menu"
    const [menu, setMenu] = useState(false);
    const notifications = useFetch({
        autoFetch: true, initResult: null, action: getNotificationList
    })
    console.log(notifications);
    const markAsRead = useFetch({
        action: markNotificationAsRead, onComplete: notifications.startFetch
    })

    const getLink = (notification) => {
        if (notification.contract_ref) {
            return `/contract/detail?id=${notification?.contract_ref}`
        }
        switch (notification?.info_type) {
            case "profile":
                return "/settings/#profile";
            case "kyc":
                return "/settings/#kyc";
        }
    }
    const handleNotificationClick = (notification) => {
        if (!['profile', 'kyc'].includes(notification.info_type)) {
            markAsRead.startFetch({
                id: notification.id
            })
        }
        const link = getLink(notification)
        console.log(link);
        if (link) {
            props.history.push(link)
        }
        setMenu(false)
    }
    const unreadCount = notifications.data?.filter(n => n.status == 0)?.length
    return (
        <>
            <Dropdown
                isOpen={menu}
                toggle={() => setMenu(!menu)}
                className="dropdown d-inline-block"
                tag="li"
            >
                <DropdownToggle
                    className="btn header-item noti-icon waves-effect"
                    tag="button" id="page-header-notifications-dropdown">
                    <i className={`bx bx-bell ${unreadCount > 0 ? "bx-tada" : ""}`}></i>
                    {unreadCount > 0 && <span className="badge badge-danger badge-pill">{unreadCount}</span>}
                </DropdownToggle>

                <DropdownMenu className="dropdown-menu dropdown-menu-lg p-0" right>
                    <div className="p-3">
                        <Row className="align-items-center">
                            <Col>
                                <h6 className="m-0"> {props.t('Notifications')} </h6>
                            </Col>
                        </Row>
                    </div>

                    <SimpleBar style={{height: "70vh"}}>


                        {notifications.data?.length == 0 ?
                            <img height={'100%'} width={'100%'} src={require('./../../../assets/images/empty_notification_page.png')}/> : notifications.data?.map(n =>
                                <a onClick={() => handleNotificationClick(n)}
                                   className={`text-reset notification-item ${n.status == 0 ? "bg-primary" : ""}`}>
                                    <div className={`media border-top border-light ${n.status == 0 ? "bg-soft-primary" : ""}`}>
                                        <div className="avatar-xs mr-3">
                    <span className="avatar-title bg-primary rounded-circle font-size-16">
                      <i className="bx bx-badge-check"></i>
                    </span>
                                        </div>
                                        <div className="media-body">
                                            <Row className="justify-content-between px-2">
                                                <h6 className="mt-0 mb-1">{n.title}</h6>
                                                <p className="mb-1">
                                                    <i className="mdi mdi-clock-outline mr-1"/>
                                                    {moment(n.created_at).startOf('day').fromNow()}
                                                </p>
                                            </Row>
                                            <div className="font-size-12 text-muted">
                                                <p className="mb-1">{n.content}</p>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                        )}

                    </SimpleBar>
                </DropdownMenu>
            </Dropdown>
        </>
    );
}

export default withRouter(withNamespaces()(NotificationDropdown));

import React, {useEffect, useState} from 'react';
import {Col, Row, Table, UncontrolledTooltip} from "reactstrap";
import {Link} from "react-router-dom";
import {storage} from "../../helpers/config";
import Collapse from "reactstrap/lib/Collapse";
import Input from "reactstrap/lib/Input";
import {differenceInDays} from 'date-fns'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import moment from "moment";
import {IntercomAPI} from "react-intercom";

const PaymentList = ({data, onInvoiceSelected, currency, withStatus, withAdvance, onAdvanceSet}) => {
    const [opened, setOpened] = useState(-1);
    const [list, setList] = useState(data);
    const [expanded, setExpanded] = useState(-1);

    useEffect(() => {
        setList(data)
    }, [data]);

    const handleInvoiceChecked = (i) => {
        onInvoiceSelected(i)
    }
    const formatter = new Intl.NumberFormat('en-US', {
        maximumSignificantDigits: 20
    });
    console.log(currency);
    return (
        <Row>
            <Col xs="12">
                <div className="table-responsive">
                    <Table className="table table-centered table-nowrap text-muted">
                        <thead className="thead-light">
                        <tr>
                            <th className="border-top-0 text-muted">{""}</th>
                            <th className="border-top-0 text-muted">Contract Ref</th>
                            <th className="border-top-0 text-muted">Contractor</th>
                            <th className="border-top-0 text-muted">Contract Name</th>
                            <th className="text-right border-top-0 text-muted">Total</th>
                            <th className="border-top-0 text-muted">Due Date</th>
                            {withStatus && <th className="border-top-0 text-muted">Payment Status</th>}
                            <th className="border-top-0 text-muted">{""}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {list.map((e, key) => {
                            const formatter = new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: e?.currency?.code || "USD",
                            });
                                return <>
                                    <tr key={"_order_" + key}>
                                        <th>
                                            {e?.works?.length != 0 && <a onClick={() => {
                                                if (expanded == key) {
                                                    setExpanded(-1)
                                                } else {
                                                    setExpanded(key)
                                                }
                                            }}>
                                                <i className="bx bx-plus"/>
                                            </a>}
                                        </th>
                                        <td>
                                            <Link to={"contract/detail?id=" + e.contract_ref}
                                                  className="text-muted border-bottom border-secondary">{e.contract_ref}</Link>
                                        </td>
                                        <td>
                                            <Col>
                                                <Row className="align-items-center flex-nowrap">
                                                    {
                                                        !e.contractor?.photo
                                                            ? <div className="avatar-xs">
                                                                                <span
                                                                                    className="avatar-title rounded-circle">
                                                                                    {e.contractor?.first_name?.charAt(0)}
                                                                                </span>
                                                            </div>
                                                            : <div>
                                                                <img className="rounded-circle avatar-xs"
                                                                     src={`${storage}${e.contractor?.photo}`} alt=""/>
                                                            </div>
                                                    }
                                                    <div
                                                        style={{marginLeft: "10px"}}>{e.contractor?.first_name} {e.contractor?.last_name}</div>
                                                </Row>
                                            </Col>
                                        </td>
                                        <td>
                                            {e.contract_name}
                                        </td>
                                        <td className="text-right">
                                            {formatter.format(e.amount)}
                                        </td>
                                        <td>
                                            {e.due_date}
                                        </td>
                                        <td>
                                            <Col>
                                                <Row className="justify-content-center">
                                                    {withAdvance ? <>
                                                        <Col sm={8}>
                                                            {e.can_pay ? <Link className="font-weight-bold mb-3"
                                                                               to={`/payment/${e.id}`}>
                                                                <span id="paytooltip">Pay</span>
                                                                <UncontrolledTooltip placement="top"
                                                                                     target="paytooltip">
                                                                    Click to pay
                                                                </UncontrolledTooltip>
                                                            </Link> : <>
                                                                <Link className="font-weight-bold mb-3"
                                                                      onClick={() => {
                                                                          IntercomAPI('trackEvent', 'Clicked offcycle payment')
                                                                          setOpened(key)
                                                                      }}>
                                                                    <span id="paytooltip">Off-cycle Payment</span>
                                                                    <UncontrolledTooltip placement="top"
                                                                                         target="paytooltip">
                                                                        Click to pay
                                                                    </UncontrolledTooltip>
                                                                </Link>
                                                                <Collapse isOpen={key == opened}>
                                                                    <Row className="mt-2 flex-nowrap">
                                                                        <Col sm={7}>
                                                                            <Input type="number" step="any"
                                                                                   onChange={event => {
                                                                                       if (parseFloat(event.target.value) > e.amount) {
                                                                                           toastr.error('Amount should be less or equal ' + e.amount)
                                                                                           return
                                                                                       }
                                                                                       setList(list.map(p => ({
                                                                                           ...p,
                                                                                           advance: parseFloat(event.target.value)
                                                                                       })))
                                                                                   }} value={e.advance}
                                                                                   placeholder="Amount"/>
                                                                        </Col>
                                                                        <Link
                                                                            className="btn btn-primary" color="primary"
                                                                            onClick={() => {
                                                                                IntercomAPI('trackEvent', 'Saved offcycle payment')
                                                                                onAdvanceSet(e)
                                                                            }}
                                                                        >Pay</Link>
                                                                    </Row>
                                                                </Collapse></>}
                                                        </Col>
                                                    </> : <div className="custom-control custom-switch mb-2"
                                                               dir="ltr">
                                                        <input type="checkbox"
                                                               className="custom-control-input"
                                                               id={`invoiceToPay${key}`} checked={e.selected}
                                                               onClick={() => {
                                                                   handleInvoiceChecked(key)
                                                               }}/>
                                                        <label className="custom-control-label"
                                                               htmlFor={`invoiceToPay${key}`} onClick={(e) => {
                                                        }}></label>
                                                    </div>}
                                                </Row>
                                            </Col>
                                        </td>
                                    </tr>
                                    {expanded == key &&
                                    <>
                                        {e?.works?.map(t => <tr
                                            className="font-weight-light font-size-12 bg-soft-primary"
                                            key={"_trans" + t?.id}>
                                            <th>{""}</th>
                                            <th>{""}</th>
                                            <th className="font-weight-normal font-size-12">{t?.details}</th>
                                            <th className="font-weight-normal font-size-12">{t?.name}</th>
                                            <th className="font-weight-normal font-size-12 text-right">{formatter.format(t?.amount)}</th>
                                            <th>{""}</th>
                                            <th>{""}</th>
                                        </tr>)}
                                    </>
                                    }
                                </>
                            }
                        )}
                        </tbody>
                    </Table>
                </div>
            </Col>
        </Row>
    );
};

export default PaymentList;

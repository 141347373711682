import React, {useState, useEffect} from 'react';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Row, Col, Badge} from 'reactstrap';

//i18n
import {withNamespaces} from 'react-i18next';
// Redux
import {connect} from 'react-redux';
import {withRouter, Link} from 'react-router-dom';


// users
import user1 from '../../../assets/images/users/avatar-1.jpg';
import {useSelector} from 'react-redux';
import {userTypes} from "../../../helpers/enum";
import {storage} from "../../../helpers/config";

const ProfileMenu = (props) => {

    // Declare a new state variable, which we'll call "menu"
    const [menu, setMenu] = useState(false);
    const [username, setusername] = useState("Admin");
    const user = useSelector(state => state.Account?.user);

    useEffect(() => {
        if (localStorage.getItem("authUser")) {
            if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
                const obj = JSON.parse(localStorage.getItem("authUser"));
                setusername(obj.displayName);
            } else if ((process.env.REACT_APP_DEFAULTAUTH === 'fake') || (process.env.REACT_APP_DEFAULTAUTH === 'jwt')) {
                const obj = JSON.parse(localStorage.getItem("authUser"));
                setusername(obj.username);
            }
        }
    }, [props.success]);

    return (
        <React.Fragment>
            <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block">
                <DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">
                    <Row>
                        {user?.photo ?
                            <img className="rounded-circle header-profile-user" src={`${storage}${user?.photo}`} alt="Header Avatar"/> :
                            <div className="rounded-circle header-profile-user">
                                <span
                                    className="avatar-title rounded-circle">
                                    {user?.first_name?.charAt(0)}
                                </span>
                            </div>
                        }
                        <Col className="jutify-centent-start">
                            <Row>
                                <span
                                    className="d-none d-xl-inline-block ml-2 mr-1">{user?.first_name} {user?.last_name}</span>
                            </Row>
                            {!props.isMobile && <Row>
                                <Badge className={"font-size-12 rounded badge-soft-primary mt-1 ml-2 px-1"} color="primary"
                                       pill><span
                                    className="d-none d-xl-inline-block ml-2 mr-1 text-primary">{user?.type}</span></Badge>
                            </Row>}

                        </Col>
                        <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                    </Row>
                </DropdownToggle>
                <DropdownMenu right>
                    {user?.type == userTypes.COMPANY &&
                    <DropdownItem tag="a">
                        <Link className="text-dark" to={'/profile-settings'}>
                            <i className="bx bx-user font-size-16 align-middle mr-1"/>{props.t('Profile')}
                        </Link>
                    </DropdownItem>}
                    <DropdownItem tag="a">
                        <Link className="text-dark" to={'/settings'}>
                            <i className="bx bx-cog font-size-17 align-middle mr-1"/>{props.t(user?.type == userTypes.COMPANY ? 'Settings' : 'Profile Settings')}
                        </Link>
                    </DropdownItem>
                    <div className="dropdown-divider"></div>
                    <Link to="/logout" className="dropdown-item">
                        <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
                        <span>{props.t('Logout')}</span>
                    </Link>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    const {error, success} = state.Profile;
    return {error, success};
}

export default withRouter(connect(mapStatetoProps, {})(withNamespaces()(ProfileMenu)));


import React from 'react';
import {useFetch} from "../../../../helpers/hooks";
import {Badge, Card, CardBody, Col, Container, Row, Spinner, Table} from "reactstrap";
import {Link} from "react-router-dom";
import moment from "moment";
import {getTransferList, transferTransaction} from "../../../../services/api";

const PendingTransfers = () => {
    const transfers = useFetch({
        autoFetch: true, initResult: null, action: getTransferList, body: {transferred: 0}
    })
    const transfer = useFetch({
        autoFetch: false, initResult: null, action: transferTransaction, onComplete: () => {
            transfers.startFetch({transferred: 0})
        }
    })
    const handleTransfer = () => {
        const selectedList = transfers.data?.filter(e => e.selected)
        console.log('debugTTR', selectedList);
        if (selectedList?.length > 0) {
            transfer.startFetch({
                "transaction_item_ids": selectedList.map(t => t?.transaction_item_id)
            })
        }
    }
    const handleCheck = (i, e) => {
        transfers.setData(transfers?.data.map((c, idx) => ({...c, selected: i == idx ? e.target.checked : c.selected})))
    }
    return (
        <div className="page-content">
            {transfers.isLoading ? <Container style={{minHeight: '30rem'}}>
                <Col style={{minHeight: '30rem'}}>
                    <Row style={{minHeight: '30rem'}} className="justify-content-center align-items-center">
                        <Spinner type="grow" className="mr-2" color="primary"/>
                    </Row>
                </Col>
            </Container> : <Container fluid>
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <Row className="justify-content-end">
                                    <button onClick={handleTransfer}
                                            className={`btn px-3 m-3 ${transfers.data?.filter(e => e.selected)?.length > 0 ? 'btn-primary' : 'btn-secondary'}`}>{transfer?.isLoading &&
                                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>}Transfer
                                    </button>
                                </Row>
                                <div className="table-responsive">
                                    <Table className="table table-centered table-nowrap text-muted">
                                        <thead className="thead-light">
                                        <tr>
                                            <th className="border-top-0 text-muted">{""}</th>
                                            <th className="border-top-0 text-muted">TRX ID</th>
                                            <th className="border-top-0 text-muted">Item ID</th>
                                            <th className="border-top-0 text-muted">Contract ID</th>
                                            <th className="border-top-0 text-muted">Client name</th>
                                            <th className="border-top-0 text-muted">Contractor name</th>
                                            <th className="border-top-0 text-muted">Amount</th>
                                            <th className="border-top-0 text-muted">Payment Method</th>
                                            <th className="border-top-0 text-muted">Due date</th>
                                            <th className="border-top-0 text-muted">TRX date</th>
                                            <th className="border-top-0 text-muted">TRX Confirmed date</th>
                                            <th className="border-top-0 text-muted">Status</th>
                                        </tr>
                                        </thead>
                                        <tbody>

                                        {transfers?.data?.map((order, key) => {
                                            const formatter = new Intl.NumberFormat('en-US', {
                                                style: 'currency',
                                                currency: order?.currency?.code || "USD",
                                            });
                                            return <tr key={"_order_" + key}>
                                                <th style={{width: "20px"}}>
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input"
                                                               onChange={(e) => handleCheck(key, e)}
                                                               checked={order.checked ? "checked" : null}
                                                               id={`genInv${key}`}/>
                                                        <label className="custom-control-label"
                                                               htmlFor={`genInv${key}`}>&nbsp;</label>
                                                    </div>
                                                </th>
                                                <td>
                                                    <Link to="#"
                                                          className="text-muted border-bottom border-secondary">{order.transaction_ref}</Link>
                                                </td>
                                                <td>
                                                    {order.transaction_item_id}
                                                </td>
                                                <td>
                                                    {order.contract_ref}
                                                </td>
                                                <td>
                                                    {order.client?.first_name} {order.client?.last_name}
                                                </td>
                                                <td>
                                                    {order.contractor?.first_name} {order.contractor?.last_name}
                                                </td>
                                                <td className="text-right">
                                                    {formatter.format(order.amount)}
                                                </td>
                                                <td>
                                                    {order.method?.name}
                                                </td>
                                                <td>
                                                    {moment(order.due_date).format('MM/DD/YYYY')}
                                                </td>
                                                <td>
                                                    {moment(order.created_at).format('MM/DD/YYYY')}
                                                </td>
                                                <td>
                                                    {moment(order.confirmed_by).format('MM/DD/YYYY')}
                                                </td>
                                                <td>
                                                    <Badge
                                                        className={"font-size-12 rounded badge-success my-2"}
                                                        color={"white"}
                                                        pill>{order.transferred == 0 ? "Not transferred" : "Transferred"}</Badge>
                                                </td>
                                            </tr>
                                        })}
                                        </tbody>
                                    </Table>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>}

        </div>
    );
};

export default PendingTransfers;

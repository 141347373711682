export const userTypes = {
    CONTRACTOR: 'contractor',
    COMPANY: 'client'
}

export const contractTypes = {
    FIXED: 'FIXED',
    PAYG: 'PAYG',
    MILESTONES: 'MILESTONES'
}

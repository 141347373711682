import React, {useState} from 'react';
import {Link} from "react-router-dom";
import {Alert, Card, CardBody, Col, Container, Row} from "reactstrap";
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";
import {AvField, AvForm} from "availity-reactstrap-validation";
import {updatePassword} from "../../services/api";
import {useSelector} from 'react-redux';
import ReactInputVerificationCode from "react-input-verification-code";
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
const UpdatePassword = (props) => {

    const [code, setCode] = useState(null);
    const email = useSelector(state => state.ForgetPassword?.email);
    console.log(email);

    function handleValidSubmit(event, values) {
        console.log(values);
        updatePassword({...values, email, otp: code}).then(r => {
            if (r.data.success) {
                props.history.push('/login')
            }else {
                toastr.error(r.data.data.error)
            }
        })
        // props.userForgetPassword(values, props.history);
    }

    return (
        <React.Fragment>
            <div className="account-pages my-5 pt-sm-5">
                <Row className="justify-content-center p-4">
                    <img src={require('./../../assets/images/logo-dark.png')} width={150} height={48}/>
                </Row>
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden">
                                <CardBody className="pt-0">
                                    <div className="p-2">

                                        {props.forgetError && props.forgetError ? (
                                            <Alert color="danger" style={{marginTop: "13px"}}>
                                                {props.forgetError}
                                            </Alert>
                                        ) : null}
                                        {props.forgetSuccessMsg ? (
                                            <Alert color="success" style={{marginTop: "13px"}}>
                                                {props.forgetSuccessMsg}
                                            </Alert>
                                        ) : null}

                                        <AvForm
                                            className="form-horizontal mt-4"
                                            onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                                        >

                                            <div className="form-group">
                                                <Row className="justify-content-center p-4">
                                                    <ReactInputVerificationCode
                                                        type='number'
                                                        length={4}
                                                        inputStyle={{
                                                            padding: '12px',
                                                            margin: '12px'
                                                        }}
                                                        onChange={(e) => {
                                                            setCode(e);
                                                        }}
                                                    />
                                                </Row>
                                            </div>
                                            <div className="form-group">
                                                <AvField
                                                    name="password"
                                                    label="Password"
                                                    className="form-control"
                                                    placeholder="Enter new password"
                                                    type="password"
                                                    required
                                                    autocomplete="autocomplete_off_hack_xfr4!k"
                                                />
                                            </div>
                                            <Row className="form-group">
                                                <Col className="text-right">
                                                    <button
                                                        className="btn btn-primary w-md waves-effect waves-light"
                                                        type="submit"
                                                    >
                                                        Reset
                                                    </button>
                                                </Col>
                                            </Row>
                                        </AvForm>
                                    </div>
                                </CardBody>
                            </Card>
                            <div className="mt-5 text-center">
                                <p>
                                    Go back to{" "}
                                    <Link
                                        to="login"
                                        className="font-weight-medium text-primary"
                                    >
                                        Login
                                    </Link>{" "}
                                </p>
                                <p>© {new Date().getFullYear()} RemoteWise. </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default UpdatePassword;

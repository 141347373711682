import React, {useEffect, useState} from 'react';
import {Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import classnames from 'classnames';
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import Button from "reactstrap/lib/Button";
import Input from "reactstrap/lib/Input";
import {updateWithdrawAmount} from "../../store/withdraw/actions";
import {IntercomAPI} from "react-intercom";
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

const Withdrawal = (props) => {
    const info = useSelector(state => state?.userProfile?.userProfile)
    const dispatch = useDispatch()
    const balances = info?.balances || []
    const [edit, setEdit] = useState(false);
    const [value, setValue] = useState(0);

    const [toWithdraw, setToWithdraw] = useState(balances[0]?.balance);

    useEffect(() => {
        IntercomAPI('trackEvent', 'Clicked Withdraw homepage')
    }, [])
    useEffect(() => {
        if (balances) {
            setToWithdraw(balances[0]?.balance)
        }
    }, [balances])

    useEffect(() => {
        console.log("toWithdraw", toWithdraw);
    }, [toWithdraw])

    useEffect(() => {
        setToWithdraw(balances[value]?.balance)
    }, [value])

    const handleWithdrawClick = () => {
        dispatch(updateWithdrawAmount({amount: parseInt(toWithdraw), currency: balances[value]?.currency}))
        props.history.push('/withdrawal-process')
    }
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: balances[value]?.currency?.code || 'EUR',
    });
    return (
        <div className="page-content">
            <Container fluid>
                <Row>
                    <Col lg="12">
                        <Card>
                            <CardBody>
                                <div className="crypto-buy-sell-nav">
                                    <Nav tabs className="nav-tabs-custom" role="tablist">
                                        <NavItem>
                                            <NavLink className={classnames({active: true})}>
                                                Withdrawal
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    <TabContent activeTab={"1"} className="crypto-buy-sell-nav-content p-4">
                                        {balances?.length > 0 &&<TabPane tabId="1" id="buy">
                                            <Col lg={4} className="mb-3">
                                                {<>
                                                    {!edit ?
                                                        <Col>
                                                            <Row
                                                                className="justify-content-between align-items-center rounded border p-2 px-3 pt-3 mb-2">
                                                                <h3 className="font-weight-bold">{formatter.format(toWithdraw)}</h3>
                                                                {!edit && <Link onClick={() => {
                                                                    setEdit(true)
                                                                }} to='#'><h6 className="text-primary">Edit</h6>
                                                                </Link>}
                                                            </Row>
                                                        </Col>
                                                        :
                                                        <Input
                                                            value={toWithdraw} onBlur={(e) => {
                                                            IntercomAPI('trackEvent', 'Modified amount', {
                                                                balance_amount: balances[value].balance,
                                                                balance_currency: balances[value].currency?.code,
                                                                selected_amount: e.target.value
                                                            })
                                                            setEdit(false)
                                                        }}
                                                            onChange={e => {
                                                                console.log(balances[value]);
                                                                if (parseInt(e.target.value) > parseInt(balances[value]?.balance)) {
                                                                    toastr.error(`Sorry can't selected more than ${balances[value]?.balance}`)
                                                                } else {
                                                                    setToWithdraw(e.target.value)
                                                                }
                                                            }}
                                                            className="font-weight-bold font-size-24 m-0 mb-2"/>}
                                                </>}
                                                <Col>
                                                    {balances?.map((e, i) => {
                                                        const formatter = new Intl.NumberFormat('en-US', {
                                                            style: 'currency',
                                                            currency: e.currency?.code || "USD",
                                                        });
                                                        return (<a onClick={() => {
                                                            IntercomAPI('trackEvent', 'Selected balance', {
                                                                balance_amount: e.balance,
                                                                balance_currency: e.currency?.code
                                                            })
                                                            setValue(i)
                                                        }}>
                                                            <Row
                                                                className={`justify-content-between align-items-center rounded border ${value == i ? " border-primary" : ""} p-2 px-3 pt-3 mb-2`}>
                                                                <h6 className="font-weight-bold">{e.label} {e.currency?.code}</h6>
                                                                <h6 className="font-weight-bold">{formatter.format(e.balance)}</h6>
                                                            </Row>
                                                        </a>)
                                                    })}
                                                </Col>

                                                <Button onClick={handleWithdrawClick} color="primary"
                                                        className="font-weight-bold btn-block mt-3">Confirm &
                                                    Withdraw</Button>
                                            </Col>
                                        </TabPane>}
                                    </TabContent>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Withdrawal;

import React from 'react';
import {Col, Row, Modal} from "reactstrap";

const KYCModal = ({isOpen, toggle, proceed}) => {
    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
        >
            <div className="modal-content">
                <div className="modal-body">
                    <Row className="justify-content-center text-center">
                        <Col md={10}>
                            <h2>KYC Verification</h2>
                            <h6 className="text-muted">You client has requested to verify your identity before you can
                                sign the contract.</h6>
                            <Row className="justify-content-center">
                            <Col sm="6" xs="8">
                                <div>
                                    <img src={require('./../../../../assets/images/verification-img.png')} alt=""
                                         className="img-fluid"/>
                                </div>
                            </Col>
                            </Row>

                            <h6 className="text-muted">It'll take only 2 minutes to complete the process</h6>
                        </Col>
                    </Row>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        className="btn btn-link"
                        onClick={toggle}
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        className="btn btn-primary"
                        data-dismiss="modal"
                        onClick={proceed}
                    >
                        Proceed
                    </button>
                </div>
            </div>

        </Modal>
    );
};

export default KYCModal;

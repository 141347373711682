import React, {useEffect, useState} from 'react';
import Row from "reactstrap/es/Row";
import {Button, Col} from "reactstrap";
import {useSelector} from 'react-redux';
import {getContractorInfo, requestKYC} from "../../services/api";
import {Link} from "react-router-dom";
import {IntercomAPI} from "react-intercom";

const Kyc = (props) => {

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});

    const user = useSelector(state => state?.Account?.user)

    const handleRequestKYC = () => {
        setLoading(true);
        IntercomAPI('trackEvent', 'Clicked on KYC verification')
        requestKYC(user?.token).then(r => {
            setLoading(false)
            window.self.open(r.data.data?.url)
            console.log(r);
        }).catch(e => {
            setLoading(false)
        })
    }

    useEffect(() => {
            IntercomAPI('trackEvent', 'Viewed KYC')
            getContractorInfo(user?.token).then(r => {
                console.log(r);
                if (r.data.success) {
                    setData(r.data.data)
                }
            })
        }
        , [])

    return <div className="hori-timeline">
        <Row className="owl-carousel owl-theme flex-wrap navs-carousel events"
             id="timeline-carousel">
            <Col md={6} className="item event-list p-0" style={{display: "inline-table"}}>
                <div>
                    <div className="event-date">
                        <div className="text-primary mb-1">KYC Verification</div>
                        <h5 className="mb-4">Level 1</h5>
                    </div>
                    <div className="event-down-icon">
                        <i className="bx bx-check-shield h1 text-success down-arrow-icon"></i>
                    </div>

                    <Row className="justify-content-center">

                        <Col xl={7} className="m-4 bg-light pt-3 rounded">
                            <Row>
                                <div className="px-3">
                                    <h6 className="text-secondary">
                                        <i className="bx bxs-check-circle h5 text-primary mr-2"></i>
                                        Name</h6>
                                </div>
                            </Row>
                            <Row>
                                <div className="px-3">
                                    <h6 className="text-secondary">
                                        <i className="bx bxs-check-circle h5 text-primary mr-2"></i>
                                        Email</h6>
                                </div>
                            </Row>
                            <Row>
                                <div className="pl-3 pr-3">
                                    <h6 className="text-secondary">
                                        <i className="bx bxs-check-circle h5 text-primary mr-2"></i>
                                        Accept policy & terms</h6>
                                </div>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Col>


            <Col md={6} className="item event-list p-0" style={{display: "inline-table"}}>
                <div>
                    <div className="event-date">
                        <div className="text-primary mb-1">KYC Verification</div>
                        <h5 className="mb-4">Level 2</h5>
                    </div>
                    <div className="event-down-icon">
                        {data?.kyc_verified == 1 ?
                            <i className="bx bx-check-shield h1 text-success down-arrow-icon"></i> :
                            <i className="bx bx-shield-x h1 text-danger down-arrow-icon"></i>}
                    </div>
                    <Row className="justify-content-center">

                        {data?.kyc_verified != 1 ? (<Col xl={7} className="mt-4">
                            <Row className="justify-content-center">
                                <Link to="#" onClick={handleRequestKYC} className="btn btn-primary">
                                    {loading &&
                                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>}Click here
                                    for verification</Link>
                            </Row>
                            <Row className="justify-content-center" style={{height: 100}}>
                                <img className="mt-4" width="100"
                                     src={require('./../../assets/images/undraw_project_completed_w0oq.svg')}/>
                            </Row>
                        </Col>) : (<Col xl={7} className="m-4 pt-3 bg-light rounded">
                            <Row>
                                <div className="px-3">
                                    <h6 className="text-secondary">
                                        <i className="bx bxs-check-circle h5 text-primary mr-2"></i>Verify personal
                                        information
                                    </h6>
                                </div>
                            </Row>
                            <Row>
                                <div className="px-3">
                                    <h6 className="text-secondary">
                                        <i className="bx bxs-check-circle h5 text-primary mr-2"></i>Verify {data?.verified_document}
                                    </h6>
                                </div>
                            </Row>
                        </Col>)}
                    </Row>
                </div>
            </Col>

        </Row>
    </div>
};

export default Kyc;

import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {Col, FormGroup, Input, Label, Row, Form} from "reactstrap";
import {AvField, AvForm} from "availity-reactstrap-validation";
import Button from "reactstrap/lib/Button";
import {addPayPalAccount, getContractorInfo} from "../../services/api";
import toastr from "toastr";

const PaypalAccounts = ({onNext = () => {}, isEdit}) => {
    const [accounts, setAccounts] = useState([]);
    const [checkedCard, setCheckedCard] = useState(-1);
    const [editing, setEditing] = useState(false);
    const [actionLoading, setActionLoading] = useState(false);
    const user = useSelector(state => state.Account?.user)
    const updateList = () => {
        getContractorInfo(user?.token).then(r => {
            console.log(r);
            if (r.data.success) {
                setAccounts(r.data.data?.paypal_accounts)
            }
        })
    }
    useEffect(() => {
        updateList()
    }, [])

    const handleAddSubmit = (e, v) => {
        setActionLoading(true)
        addPayPalAccount(user?.token, v.email).then(r => {
            console.log(r);
            if (r.data.success){
                updateList()
                setEditing(false)
            }else {
                toastr.error(r.data.message)
            }
            setActionLoading(false)
        }).catch(e => {
            setActionLoading(false)
        })
    }

    const AccountItem = ({account, index}) => {
        return <Label className="card-radio-label mb-3">
            <Input type="radio" name="pay-method" id={"credit-card-" + index}
                   className="card-radio-input"
                   onClick={() => {
                       setCheckedCard(index)
                       onNext(account)
                   }}/>

            <div
                className={"card-radio p-2 rounded" + (checkedCard == index ? " border-primary" : " border-light")}>
                <Col>
                    <Row className="flex-nowrap">
                        <i className={`bx bxl-paypal font-size-24 ${checkedCard == index ? "text-primary" : "text-secondary"} align-middle mr-2`}></i>

                        <Col>
                            <h5 className={checkedCard == index ? "my-0 text-primary mb-2 bold" : "my-0 text-secondary mb-2 bold"}
                                style={{textJustify: 'center', alignItems: 'center'}}>
                                Account Holder: {user?.first_name} {user?.last_name}
                            </h5>
                            <p className="text-muted">{account.email}</p>
                        </Col>
                    </Row>
                </Col>
            </div>
        </Label>
    }
    return (
        <Row className="justify-content-center">
            <Col className="p-0" md={isEdit?9:8}>
                {!isEdit && <Row className="justify-content-center">
                    <Col md={8} className="border-bottom border-light">
                        <h1 className="font-weight-light text-center">Select an account</h1>
                    </Col>
                </Row>}
                {!editing ? <Row className="justify-content-center">
                    <Col md={isEdit?9:8} className="pt-4 px-5">
                        {accounts.map((e, i) => <AccountItem account={e} index={i}/>)}
                        <a
                            onClick={() => setEditing(true)}
                            className="text-primary font-weight-bold px-0">+ Add New Paypal Account
                        </a>
                    </Col>
                </Row> : <Row className="justify-content-center mt-5">
                    <Col md={isEdit?7:6}>
                        <AvForm onValidSubmit={handleAddSubmit} >
                            <FormGroup className="mt-4 mb-0">
                                <Label htmlFor="name">Account Holder</Label>
                                <AvField name="name" type="text" required
                                       className="form-control" id="paypal-email"
                                       placeholder="Name">
                                </AvField>
                            </FormGroup>
                            <FormGroup className="my-3">
                                <Label htmlFor="name">Email</Label>
                                <AvField name="email" type="email" required
                                       className="form-control" id="paypal-email"
                                       placeholder="Email">
                                </AvField>
                            </FormGroup>
                            <Button color="primary" className="btn-block" type="submit">
                                {actionLoading &&
                                <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>} Add
                            </Button>
                        </AvForm>
                    </Col>
                </Row>
                }
            </Col>
        </Row>
    );
};

export default PaypalAccounts;

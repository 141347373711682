import React, {useEffect, useState} from 'react';
import {Card, CardBody, CardHeader, CardText, Col, Container, Row, Input, Label} from "reactstrap";
import {contractTypes} from "../../../helpers/enum";

const ContractTypeSelect = ({onContractTypeChanged, value}) => {
    const [selected, setSelected] = useState(value);
    const options = [
        {label: 'Fixed', value: contractTypes.FIXED, description: "Pay a fixed rate every cycle"},
        {label: 'Pay as you go', value: contractTypes.PAYG, description: "Pay based on work or timesheets submitted"},
        {label: 'Milestones', value: contractTypes.MILESTONES, description: "Pay as milestones are completed"}
    ]
    useEffect(() => {
        console.log(selected);
    }, [selected])
    return (
        <Container fluid={true}>
            <Row className="justify-content-between">
                <Col>{options.map((e, i) =>
                        <Card
                            outline color={i == selected ? "primary" : "secondary"}
                            className={i == selected ? "text-primary border" : "text-secondary border"}>
                            <CardHeader className="bg-transparent">
                                <Row className="align-items-start pl-2">
                                        <div className="custom-control custom-radio custom-control-inline mr-2">
                                            <Input onClick={() => {
                                                setSelected(i);
                                                onContractTypeChanged(i)
                                            }} type="radio" checked={selected == i} id={`CTRadioInline${i}`} name="customRadioInline1" className="custom-control-input" />
                                            <Label className="custom-control-label mr-0" htmlFor={`CTRadioInline${i}`}>
                                                <Col>
                                                    <h5 className={i == selected ? "my-0 text-primary mb-2 bold" : "my-0 text-secondary mb-2 bold"}
                                                        style={{textJustify: 'center', alignItems: 'center'}}>
                                                        {e.label}
                                                    </h5>
                                                    <p className="text-secondary text-muted font-size-12 mb-0">{e.description}</p>
                                                </Col>
                                            </Label>
                                    </div>
                                </Row>
                            </CardHeader>
                        </Card>
                )}</Col>
            </Row>
        </Container>
    );
}

export default ContractTypeSelect;

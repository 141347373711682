import {
    REGISTER_USER,
    REGISTER_USER_SUCCESSFUL,
    REGISTER_USER_FAILED,
    OTP_VERIFICATION,
    OTP_VERIFICATION_SUCCESSFUL,
    OTP_VERIFICATION_FAILED,
    LOGOUT,
    LOGIN_USER_SUCCESSFUL,
    UPDATE_USER_INFO,
    UPDATE_DEEP_LINK
} from './actionTypes';

const initialState = {
    registrationError: null, message: null, loading: false,
    OTPError: null, OTPmessage: null, OTPloading: false,
}

const account = (state = initialState, action) => {
    switch (action.type) {
        case REGISTER_USER:
            state = {
                ...state,
                // user: null,
                loading: true,
                registrationError: null
            }
            break;
        case REGISTER_USER_SUCCESSFUL:
            console.log(state, action.payload);
            state = {
                ...state,
                loading: false,
                user: {...state.user, ...action.payload},
                registrationError: null,
                loggedIn: true
            }
            break;
        case REGISTER_USER_FAILED:
            state = {
                ...state,
                user: null,
                loading: false,
                registrationError: action.payload
            }
            break;
        case OTP_VERIFICATION:
            state = {
                ...state,
                user: null,
                OTPloading: true,
                OTPError: null
            }
            break;
        case OTP_VERIFICATION_SUCCESSFUL:
            state = {
                ...state,
                OTPloading: false,
                user: action.payload,
                OTPError: null
            }
            break;
        case OTP_VERIFICATION_FAILED:
            state = {
                ...state,
                user: null,
                OTPloading: false,
                OTPError: action.payload
            }
            break;
        case LOGOUT:
            state = {
                ...state,
                user: null,
                loggedIn: false,
            }
            break
        case LOGIN_USER_SUCCESSFUL:
            state = {
                ...state,
                user: {...state.user, ...action.payload},
                loggedIn: true,
            }
            break
        case UPDATE_USER_INFO:
            state = {
                ...state,
                user: {...state.user, ...action.payload}
            }
            break
        case UPDATE_DEEP_LINK:
            state = {
                ...state,
                link: action.payload
            }
            break
        // default:
        //     state = {...state};
        //     break;
    }
    return state;
}

export default account;

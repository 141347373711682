import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Card, CardBody, Col, Container, Row, TabContent, TabPane} from "reactstrap";
import ConfirmationPage from "./ConfirmationPage";
import {Link} from "react-router-dom";
import WithdrawalMethods from "./WithdrawalMethods";
import BankAccounts from "./BankAccounts";
import PaypalAccounts from "./PaypalAccounts";
import WithdrawReview from "./withdrawReview";
import Wootric from "../../components/Wootric/Wootric";
import {useFetch} from "../../helpers/hooks";
import {createWithdraw, prepareWithdraw} from "../../services/api";
import {updateWithdrawAmount} from "../../store/withdraw/actions";
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import {IntercomAPI} from "react-intercom";

const WithdrawProcess = ({history}) => {
    const [activeTab, setActiveTab] = useState(1);
    const [paymentMethod, setPaymentMethod] = useState(-1);
    const [selectedCard, setSelectedCard] = useState(null);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const user = useSelector(state => state.Account?.user)
    const selectedList = useSelector(state => state?.Payment?.toPay)
    const [account, setAccount] = useState(null);
    const preWithdraw = useFetch({
        autoFetch: false, initResult: null, action: prepareWithdraw, onComplete: () => {
            if (preWithdraw.error) {
                toastr.error(preWithdraw.error)
            }
        }
    })
    const withdraw = useFetch({
        autoFetch: false, initResult: null, action: createWithdraw, onComplete: () => {
            if (withdraw.error) {
                toastr.error(withdraw.error)
            }
            setActiveTab(activeTab + 1)
        }
    })
    const withdrawAmount = useSelector(state => state?.Withdraw?.withdrawAmount)
    const dispatch = useDispatch()

    useEffect(() => {
        console.log('error', preWithdraw.error);
        if (preWithdraw.error) {
            toastr.error(preWithdraw.error)
        }
    }, [preWithdraw.error])

    useEffect(() => {
        console.log('withdrawAmount', withdrawAmount);
        return () => {
            dispatch(updateWithdrawAmount(null))
        }
    }, [])


    return (
        <div className="page-content min-vh-100 p-0 m-0">
            <Container fluid>
                <Row className="justify-content-center">
                    <Col className="p-0">
                        <Card className="min-vh-100">
                            <CardBody>
                                <Row
                                    className="justify-content-between flex-nowrap p-3 border-bottom border-light sticky-top bg-white">
                                    <Col md={1}>
                                        <Row>
                                            <img height={32} src={require('./../../assets/images/RW-icon-Blue.png')}/>
                                        </Row>
                                    </Col>
                                    <Col md={10} className="justify-content-center">
                                        <Row className="justify-content-center">
                                            <Col md={11}>
                                                <Row className="align-items-center justify-content-between">
                                                    {activeTab == 1 && <span className="bg-primary"
                                                                             style={{
                                                                                 width: 14,
                                                                                 height: 14,
                                                                                 borderRadius: 7
                                                                             }}/>}
                                                    <Col
                                                        className={activeTab >= 2 ? "bg-primary" : "bg-soft-secondary"}
                                                        style={{height: 2}}/>
                                                    {activeTab == 2 && <span className="bg-primary"
                                                                             style={{
                                                                                 width: 14,
                                                                                 height: 14,
                                                                                 borderRadius: 7
                                                                             }}/>}
                                                    <Col
                                                        className={activeTab >= 3 ? "bg-primary" : "bg-soft-secondary"}
                                                        style={{height: 2}}/>
                                                    {activeTab == 3 && <span className="bg-primary"
                                                                             style={{
                                                                                 width: 14,
                                                                                 height: 14,
                                                                                 borderRadius: 7
                                                                             }}/>}
                                                    <Col
                                                        className={activeTab >= 4 ? "bg-primary" : "bg-soft-secondary"}
                                                        style={{height: 2}}/>
                                                    {activeTab == 4 && <span className="bg-primary"
                                                                             style={{
                                                                                 width: 14,
                                                                                 height: 14,
                                                                                 borderRadius: 7
                                                                             }}/>}
                                                </Row>
                                            </Col>

                                        </Row>
                                        <Row className="justify-content-between">
                                            <Col>
                                                <Row className="justify-content-between pt-3 flex-nowrap">
                                                    <Col
                                                        className={`${activeTab >= 1 ? "text-primary" : "text-muted"} font-weight-semibold text-left`}
                                                        style={{textAlign: 'center'}}>Withdrawal methods</Col>
                                                    <Col
                                                        className={`${activeTab >= 2 ? "text-primary" : "text-muted"} font-weight-semibold`}
                                                        style={{textAlign: 'center'}}>Account</Col>
                                                    <Col
                                                        className={`${activeTab >= 3 ? "text-primary" : "text-muted"} font-weight-semibold`}
                                                        style={{textAlign: 'center'}}>Review</Col>
                                                    <Col
                                                        className={`${activeTab >= 4 ? "text-primary" : "text-muted"} font-weight-semibold text-right`}
                                                        style={{textAlign: 'center'}}>Withdraw</Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md={1}>
                                        <Row className="justify-content-end px-2">
                                            <a onClick={() => {
                                                history.goBack()
                                            }}>
                                                <i className='bx bx-x-circle font-weight-light'
                                                   style={{fontSize: '2rem'}}/>
                                            </a>
                                        </Row>
                                    </Col>
                                </Row>
                                <Col>
                                    <TabContent activeTab={activeTab}
                                                className="twitter-bs-wizard-tab-content">
                                        <TabPane tabId={1}>
                                            <WithdrawalMethods
                                                data={data}
                                                loading={loading}
                                                onSelected={e => {
                                                    setPaymentMethod(e)
                                                    IntercomAPI('trackEvent', 'Withdraw step 1 - Method', {
                                                        balance_amount: withdrawAmount?.amount,
                                                        balance_currency: withdrawAmount?.currency?.code,
                                                        selected_amount: withdrawAmount?.amount,
                                                        withdraw_method: 3
                                                    })
                                                }}
                                                onNext={() => {
                                                    setActiveTab(activeTab + 1)
                                                }}/>
                                        </TabPane>
                                        <TabPane tabId={2}>
                                            {paymentMethod == 1 ? <BankAccounts
                                                    onNext={(account) => {
                                                        setAccount(account)
                                                        IntercomAPI('trackEvent', 'Withdraw step 2 - Account', {
                                                            balance_amount: withdrawAmount.amount,
                                                            balance_currency: withdrawAmount.currency?.code,
                                                            selected_amount: withdrawAmount.amount,
                                                            withdraw_account: account?.id,
                                                            withdraw_method: 3
                                                        })
                                                        preWithdraw.startFetch({
                                                            "amount": withdrawAmount?.amount,
                                                            "currency_id": withdrawAmount?.currency?.id,
                                                            "payment_method_id": 3,
                                                            "account_id": account?.id
                                                        })
                                                        setActiveTab(activeTab + 1)
                                                    }}
                                                    onAccountAdded={account => {
                                                        IntercomAPI('trackEvent', 'Withdraw step 2 - Added account', {
                                                            balance_amount: withdrawAmount.amount,
                                                            balance_currency: withdrawAmount.currency?.code,
                                                            selected_amount: withdrawAmount.amount,
                                                            withdraw_account: account?.id,
                                                            withdraw_method: 3
                                                        })
                                                    }}
                                                    currency={withdrawAmount?.currency?.code}
                                                /> :
                                                <PaypalAccounts onNext={(account) => {
                                                    setAccount(account)
                                                    IntercomAPI('trackEvent', 'Withdraw step 2 - Account', {
                                                        balance_amount: withdrawAmount.amount,
                                                        balance_currency: withdrawAmount.currency?.code,
                                                        selected_amount: withdrawAmount.amount,
                                                        withdraw_account: account?.id,
                                                        withdraw_method: paymentMethod
                                                    })
                                                    preWithdraw.startFetch({
                                                        "amount": withdrawAmount?.amount,
                                                        "currency_id": withdrawAmount?.currency?.id,
                                                        "payment_method_id": paymentMethod,
                                                        "account_id": account?.id
                                                    })
                                                    setActiveTab(activeTab + 1)
                                                }}/>}
                                        </TabPane>
                                        <TabPane tabId={3}>
                                            <WithdrawReview
                                                onBack={() => setActiveTab(activeTab - 1)}
                                                onNext={() => {
                                                    console.log(preWithdraw.data);
                                                    IntercomAPI('trackEvent', 'Withdraw step 3 - Review', {
                                                        balance_amount: withdrawAmount.amount,
                                                        balance_currency: withdrawAmount.currency?.code,
                                                        selected_amount: withdrawAmount.amount,
                                                        withdraw_account: account?.id,
                                                        withdraw_method: 3
                                                    })


                                                    withdraw.startFetch({
                                                        quote_id: preWithdraw.data?.quote_id
                                                    })
                                                }}
                                                bankInfo={preWithdraw.data}
                                                loading={withdraw.isLoading}
                                                method={paymentMethod}
                                            />
                                        </TabPane>
                                        <TabPane tabId={4}>
                                            <ConfirmationPage
                                                onComplete={() => {
                                                    IntercomAPI('Withdraw step 4 - Withdraw Confirmation', {
                                                        balance_amount: withdrawAmount.amount,
                                                        balance_currency: withdrawAmount.currency?.code,
                                                        selected_amount: withdrawAmount.amount,
                                                        withdraw_account: account?.id,
                                                        withdraw_method: 3,
                                                        transaction_id: withdraw.data?.ref
                                                    })
                                                    history.goBack()
                                                }}
                                                data={withdraw.data}
                                            />
                                        </TabPane>
                                    </TabContent>
                                    {[2, 3].includes(activeTab) && <Row className="justify-content-center mx-5">
                                        <Col className="p-0 py-5" lg={6}>
                                            <ul className="pager wizard twitter-bs-wizard-pager-link p-0 m-0">
                                                <Link className="text-muted font-size-13"
                                                      onClick={() => {
                                                          setActiveTab(activeTab - 1)
                                                      }}
                                                >
                                                    <i className="mdi mdi-arrow-left mr-1"></i> Back </Link>

                                            </ul>
                                        </Col>
                                    </Row>}
                                </Col>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            {activeTab == 4 && <Wootric/>}
        </div>
    );
};

export default WithdrawProcess;

import React, {useState} from 'react';
import {Col, Container, Input, Label, Row, Spinner} from "reactstrap";

const WithdrawalMethods = ({loading, onNext, onSelected, data}) => {
    const [value, setValue] = useState(-1);
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: data?.currency?.code || 'USD',
    });
    return (
        <Row className="justify-content-center">
            {loading ? <Container style={{minHeight: '30rem'}}>
                <Col style={{minHeight: '30rem'}}>
                    <Row style={{minHeight: '30rem'}} className="justify-content-center align-items-center">
                        <Spinner type="grow" className="mr-2" color="primary"/>
                    </Row>
                </Col>
            </Container> :<Col lg={8}>
                <Row className="justify-content-center">
                    <Col lg={8} className="border-bottom border-light">
                        <h1 className="font-weight-light text-center">How would you like to withdraw</h1>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col lg={8} className="pt-4 px-5">
                        <Label className="card-radio-label mb-3">
                            <Input type="radio" name="pay-method" id="payment-method1"
                                   className="card-radio-input"
                                   onClick={() => {
                                       setValue(1)
                                       onSelected(1)
                                       onNext()
                                   }}/>

                            <div className={"card-radio"}>
                                <Col>
                                    <Row>
                                        <i className={`bx bxs-bank font-size-24 ${value == 1 ? "text-primary" : "text-secondary"} align-middle mr-2`}></i>
                                        <Col className="p-0">
                                            <h5 className={value == 1 ? "my-0 text-primary mb-2 bold" : "my-0 text-secondary mb-2 bold"}
                                                style={{textJustify: 'center', alignItems: 'center'}}>
                                                Bank Transfer
                                            </h5>
                                            <p className="text-muted">Fee depends on the cash out currency</p>
                                        </Col>
                                    </Row>
                                </Col>
                            </div>
                        </Label>
                        <Label className="card-radio-label mb-3">
                            <Input type="radio" name="pay-method" id="payment-method2"
                                   className="card-radio-input"
                                   onClick={() => {
                                       setValue(2)
                                       onSelected(2)
                                       onNext()
                                   }}/>

                            <div className={"card-radio"}>
                                <Col>
                                    <Row>
                                        <i className={`bx bxl-paypal font-size-24 ${value == 2 ? "text-primary" : "text-secondary"} align-middle mr-2`}></i>
                                        <Col className="p-0">
                                            <h5 className={value == 2 ? "my-0 text-primary mb-2 bold" : "my-0 text-secondary mb-2 bold"}
                                                style={{textJustify: 'center', alignItems: 'center'}}>
                                                Paypal
                                            </h5>
                                            <p className="text-muted">{"2.5%"} Fee</p>
                                        </Col>
                                    </Row>
                                </Col>
                            </div>
                        </Label>
                    </Col>
                </Row>
            </Col>}
        </Row>
    );
};

export default WithdrawalMethods;

import React from 'react';
import {Row, Col, Alert, Card, CardBody, Container} from "reactstrap";

// Redux
import {connect} from "react-redux";
import {withRouter, Link} from "react-router-dom";

// availity-reactstrap-validation
import {AvForm, AvField} from "availity-reactstrap-validation";

// action
import {requestForgetPassword, userForgetPassword} from "../../store/actions";
import {useDispatch} from 'react-redux';
// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";
import {resetPassword} from "../../services/api";
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

const ForgetPasswordPage = (props) => {
    const dispatch = useDispatch();

    function handleValidSubmit(event, values) {
        resetPassword(values.email).then(r => {
            if (r.data.success) {
                dispatch(requestForgetPassword(values.email))
                props.history.push('/update-password')
            }else {
                toastr.error(r.data.data?.error || r.data.message || "Error occurred")
            }
        })
        // props.userForgetPassword(values, props.history);
    }

    return (
        <React.Fragment>
            <div className="account-pages my-5 pt-sm-5">
                <Row className="justify-content-center p-4">
                    <img src={require('./../../assets/images/logo-dark.png')} width={150} height={48}/>
                </Row>
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden">
                                <CardBody className="pt-0">
                                    <div className="p-2">

                                        {props.forgetError && props.forgetError ? (
                                            <Alert color="danger" style={{marginTop: "13px"}}>
                                                {props.forgetError}
                                            </Alert>
                                        ) : null}
                                        {props.forgetSuccessMsg ? (
                                            <Alert color="success" style={{marginTop: "13px"}}>
                                                {props.forgetSuccessMsg}
                                            </Alert>
                                        ) : null}

                                        <AvForm
                                            className="form-horizontal mt-4"
                                            onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                                        >

                                            <div className="form-group">
                                                <AvField
                                                    name="email"
                                                    label="Email"
                                                    className="form-control"
                                                    placeholder="Enter email"
                                                    type="email"
                                                    required
                                                />
                                            </div>
                                            <Row className="form-group">
                                                <Col className="text-right">
                                                    <button
                                                        className="btn btn-primary w-md waves-effect waves-light"
                                                        type="submit"
                                                    >
                                                        Send
                                                    </button>
                                                </Col>
                                            </Row>
                                        </AvForm>
                                    </div>
                                </CardBody>
                            </Card>
                            <div className="mt-5 text-center">
                                <p>
                                    Go back to{" "}
                                    <Link
                                        to="login"
                                        className="font-weight-medium text-primary"
                                    >
                                        Login
                                    </Link>{" "}
                                </p>
                                <p>© {new Date().getFullYear()} RemoteWise. </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    const {forgetError, forgetSuccessMsg} = state.ForgetPassword;
    return {forgetError, forgetSuccessMsg};
};

export default withRouter(
    connect(mapStatetoProps, {userForgetPassword})(ForgetPasswordPage)
);

import React from 'react';
import {Button, Col, Row} from "reactstrap";

const NoContent = ({image, title, actionTitle, onAction, withAction, headline, subtitle}) => {
    return (
        <><h4 className="mb-3 font-size-18">{title}</h4>
        <Row className="justify-content-center">
            <Col xl={6} className="justify-content-center">
                <Row className="justify-content-center">
                    <img src={image} width="300rem" height="300rem"/>
                </Row>
                <Row className="justify-content-center"><h4>{headline || "Welcome to RemoteWise"}</h4></Row>
                <Row className="justify-content-center">
                    <p style={{width: '70%'}} className="text-muted text-light text-center">
                        {subtitle || "create contracts, manage contracts, compliance and make payments"}
                    </p>
                </Row>
                {withAction && <Row className="justify-content-center">
                    <button onClick={onAction} className="btn btn-primary"><i style={{"margin-right": "10px"}}
                                                                              className="fas fa-plus"></i>
                        {actionTitle}</button>
                </Row>}
            </Col>
        </Row></>
    );
};

export default NoContent;

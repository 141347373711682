export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESSFUL = 'REGISTER_USER_SUCCESSFUL';
export const REGISTER_USER_FAILED = 'REGISTER_USER_FAILED';

export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESSFUL = 'LOGIN_USER_SUCCESSFUL';
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED';

export const LOGOUT = 'LOGOUT';

export const UPDATE_DEEP_LINK = 'UPDATE_DEEP_LINK';

export const OTP_VERIFICATION = 'OTP_VERIFICATION';
export const OTP_VERIFICATION_SUCCESSFUL = 'OTP_VERIFICATION_SUCCESSFUL';
export const OTP_VERIFICATION_FAILED = 'OTP_VERIFICATION_FAILED';




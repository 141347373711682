import React, {useState} from 'react';
import {
    Col,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label,
    Modal,
    Row,
    Spinner
} from "reactstrap";
import {AvField, AvForm} from "availity-reactstrap-validation";
import DatePicker from "react-datepicker";
import {submitWork} from "../../../../services/api";
import {useSelector} from "react-redux";
import moment from "moment";
import subDays from 'date-fns/subDays'
import {IntercomAPI} from "react-intercom";

const SubmitWorkModal = ({toggle, isOpen, contract, onWorkSubmitted}) => {
    const [date, setDate] = useState(new Date());
    const [value, setValue] = useState(null);
    const [loading, setLoading] = useState(false);
    const getRate = (id) => {
        console.log(id);
        switch (id) {
            case 1:
                return "Hours";
            case 2:
                return "Days";
            case 3:
                return "Months";
        }
    }
    const user = useSelector(state => state.Account?.user)
    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
        >
            <AvForm onValidSubmit={(e, v) => {
                setLoading(true)
                submitWork(user.token, {
                    ...v,
                    submitted_at: moment(date).format('YYYY-MM-DD'),
                    contract_id: contract.id,
                    qty: value

                }).then(r => {
                    IntercomAPI('trackEvent','Added work', {
                        contract_id: contract.id,
                        contract_type: contract.type,
                        work_name: v?.details,
                        work_value: v?.qty,
                        currency: contract.currency?.code
                    })
                    setLoading(false)
                    if (r.data.success) {
                        toggle();
                        onWorkSubmitted()
                    }
                    console.log(r);
                }).catch(e => {
                    setLoading(false)
                })
            }}>

                <div className="modal-content">
                    {loading ?
                        (<Col style={{minHeight: '10rem'}}>
                            <Row style={{minHeight: '10rem'}} className="justify-content-center align-items-center">
                                <Spinner type="grow" className="mr-2" color="primary"/>
                            </Row>
                        </Col>) :
                        <>
                            <div className="modal-header">
                                <h5 className="modal-title mt-0">Submit work</h5>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={toggle}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <Row
                                    className="justify-content-center">
                                    <Col>

                                        <FormGroup>
                                            <Label>Date</Label>
                                            <DatePicker
                                                name="end-date"
                                                className="form-control"
                                                selected={date}
                                                onChange={setDate}
                                                minDate={subDays(new Date(), 15)}
                                                maxDate={new Date()}
                                                placeholderText="Date"/>
                                        </FormGroup>

                                        <FormGroup>
                                            <Label>Name</Label>
                                            <AvField
                                                name="details"
                                                type="text"
                                                placeholder="Name"
                                                id="basicpill-pancard-input7"
                                                className="form-control"
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Value</Label>
                                            <InputGroup>
                                                <Input
                                                    placeholder="Value" name="qty"
                                                    type="number"
                                                    onChange={(e) => {
                                                        setValue(e.target.value)
                                                    }}/>
                                                <InputGroupAddon addonType="append">
                                                    <InputGroupText>{getRate(contract?.rate_id?.id)}</InputGroupText>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </FormGroup>

                                    </Col>

                                </Row>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="submit"
                                    className="btn btn-primary">
                                    Add
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-dismiss="modal"
                                    onClick={toggle}
                                >
                                    Cancel
                                </button>
                            </div>
                        </>}
                </div>

            </AvForm>
        </Modal>
    );
};

export default SubmitWorkModal;

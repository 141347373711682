import React, {useState} from 'react';
import {useFetch} from "../../../../helpers/hooks";
import {Badge, Card, CardBody, Col, Container, Row, Spinner, Table} from "reactstrap";
import {getAllPaymentList} from "../../../../services/api";
import {Link} from "react-router-dom";
import moment from "moment";
import {storage} from "../../../../helpers/config";

const getStatusColor = (status) => {
    switch (status) {
        case 'Not paid':
            return 'danger';
        case 'Paid':
            return 'success';
        case 'Processing':
            return 'warning';
        default:
            return 'primary';
    }
}
const AllPayments = () => {
    const [expanded, setExpanded] = useState(-1);
    const payments = useFetch({
        autoFetch: true, initResult: null, action: getAllPaymentList
    })
    console.log(payments.data);
    return (
        <div className="page-content">
            {payments.isLoading ? <Container style={{minHeight: '30rem'}}>
                <Col style={{minHeight: '30rem'}}>
                    <Row style={{minHeight: '30rem'}} className="justify-content-center align-items-center">
                        <Spinner type="grow" className="mr-2" color="primary"/>
                    </Row>
                </Col>
            </Container> : <Container fluid>
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <div className="table-responsive">
                                    <Table className="table table-centered table-nowrap text-muted">
                                        <thead className="thead-light">
                                        <tr>
                                            <th className="border-top-0 text-muted">{""}</th>
                                            <th className="border-top-0 text-muted">Contract ID</th>
                                            <th className="border-top-0 text-muted">Contractor name</th>
                                            <th className="border-top-0 text-muted">Contract Name</th>
                                            <th className="border-top-0 text-muted">Amount</th>
                                            <th className="border-top-0 text-muted">Due date</th>
                                            <th className="border-top-0 text-muted">Status</th>
                                        </tr>
                                        </thead>
                                        <tbody>

                                        {payments.data?.map((payment, key) => {
                                            const formatter = new Intl.NumberFormat('en-US', {
                                                style: 'currency',
                                                currency: payment?.currency?.code || "USD",
                                            });
                                            return <><tr key={"_payment_" + key}>
                                                <th>
                                                    {payment?.works?.length != 0 && <a onClick={() => {
                                                        if (expanded == key) {
                                                            setExpanded(-1)
                                                        } else {
                                                            setExpanded(key)
                                                        }
                                                    }}>
                                                        <i className="bx bx-plus"/>
                                                    </a>}
                                                </th>
                                                <td>
                                                    {payment.contract_ref}
                                                </td>
                                                <td>
                                                    {payment.contractor?.first_name} {payment?.contractor?.last_name}
                                                </td>
                                                <td>
                                                    {payment.contract_name}
                                                </td>
                                                <td className="text-right">
                                                    {formatter.format(payment.total)}
                                                </td>
                                                <td>
                                                    {moment(payment.due_date).format('MM/DD/YYYY')}
                                                </td>
                                                <td>
                                                    <Badge
                                                        className={`font-size-12 rounded badge-${getStatusColor(payment.status?.name)} my-2`}
                                                        color="white"
                                                        pill>{payment.status?.name}</Badge>
                                                </td>
                                            </tr>
                                            {expanded == key &&
                                            <>
                                                {payment?.works?.map(t => {
                                                    const formatter = new Intl.NumberFormat('en-US', {
                                                        style: 'currency',
                                                        currency: t?.currency || "USD",
                                                    });
                                                    return <tr
                                                        className="font-weight-light font-size-12 bg-soft-primary"
                                                        key={"_trans" + t?.id}>
                                                        <th>{""}</th>
                                                        <th>{""}</th>
                                                        <th className="font-weight-normal font-size-12">{t?.details}</th>
                                                        <th className="font-weight-normal font-size-12">{t?.name}</th>
                                                        <th className="font-weight-normal font-size-12 text-right">{formatter.format(t?.amount)}</th>
                                                        <th>{""}</th>
                                                        <th>{""}</th>
                                                        <th>{""}</th>
                                                    </tr>
                                                })}
                                            </>
                                            }</>
                                        })}

                                        </tbody>
                                    </Table>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>}

        </div>
    );
};

export default AllPayments;

import React, {useEffect} from 'react';
import {Button, Card, Col, Label, Row} from "reactstrap";
import {CopyToClipboard} from "react-copy-to-clipboard";
import toastr from 'toastr'
import {useSelector} from "react-redux";
import {IntercomAPI} from "react-intercom";


const PaymentReview = ({onNext, onBack, bankInfo, card, loading, data, advance}) => {
    const groupBy = function (xs, key) {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };
    const selectedList = useSelector(state => state.Payment?.toPay)
    const lines = groupBy(selectedList?.map(e => ({
        ...e,
        contractorName: `${e.contractor?.first_name} ${e.contractor?.last_name}`
    })) || [], "contractorName")

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: data?.currency?.code || 'USD',
    });
    useEffect(() => {
        IntercomAPI('trackEvent', 'Payment step 3 - Review')
    }, [])

    return (
        <Row className="justify-content-center">
            <Col lg={8}>
                <Row className="justify-content-center">
                    <Col lg={8} className="border-bottom border-light">
                        <h1 className="font-weight-light text-center">Review & Pay</h1>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col lg={8}>
                        <Card className="mt-3 rounded-0 border-light shadow p-2">
                            <Row className="justify-content-center">
                                <Col>
                                    <Col className="pt-2 border-bottom">
                                        <Col>
                                            <Row>
                                                <h5 className="text-primary py-2 font-weight-bold">Contractors</h5>
                                            </Row>
                                            {data?.items?.map(e => <Row
                                                className="justify-content-between font-weight-light">
                                                <p>{e.contractor_name}</p>
                                                <p>{formatter.format(e.trans_amount)}</p>
                                            </Row>)}
                                            <Row className="justify-content-between font-weight-light">
                                                <p>Fee</p>
                                                <p>{bankInfo ? formatter.format(data?.fee) : formatter.format(data?.fee)}</p>
                                            </Row>
                                            {/*{Object.keys(lines).map(key => {*/}
                                            {/*    console.log(key);*/}
                                            {/*    return (<Row className="justify-content-between font-weight-light">*/}
                                            {/*            <p>{key}</p>*/}
                                            {/*            <p>{formatter.format(lines[key]?.length > 1 ? lines[key]?.reduce((prev, current) => prev?.amount ? prev?.amount + current?.amount : prev + current?.amount) : lines[key][0]?.amount)}</p>*/}
                                            {/*        </Row>)*/}
                                            {/*    }*/}
                                            {/*)}*/}

                                            <Row className="justify-content-between font-size-16 font-weight-bold">
                                                <p>Total</p>
                                                <p>{bankInfo ? formatter.format(data?.total) : formatter.format(data?.total)}</p>
                                            </Row>
                                        </Col>

                                    </Col>
                                    {bankInfo ? <Col className="pt-2">

                                        <Col className="p-0">
                                            <Col className="bg-primary text-white py-2 mb-3">
                                                <p>• Transfer the exact amount to the bank account below</p>
                                                <p>• Include the reference to help us identify your payment </p>
                                            </Col>
                                            <Label className="col-form-label pt-0 font-weight-light">Reference</Label>
                                            <div
                                                name="end-date"
                                                id="bankInof_ref"
                                                className="form-control mb-2 text-primary font-weight-bold"
                                            >
                                                {data?.ref}
                                            </div>
                                            <CopyToClipboard text={data?.ref}
                                                             onCopy={() => toastr.success('Reference copied')}>
                                                <span style={{
                                                    position: 'absolute',
                                                    right: '10px',
                                                    bottom: '10px'
                                                }}>
                                                    <i className="fas fa-copy 16 text-primary"/>
                                                </span>
                                            </CopyToClipboard>

                                        </Col>
                                        {!!data && data?.account?.map(e => <Col className="p-0">
                                            <Label className="col-form-label pt-0 font-weight-light">{e.name}</Label>
                                            <div
                                                name="end-date"
                                                className="form-control mb-2"
                                                id="bankInof_Beneficiary"
                                            >
                                                {e.value}
                                            </div>
                                            <CopyToClipboard text={e.value}
                                                             onCopy={() => toastr.success(`${e.name} copied`)}>
                                                <span style={{
                                                    position: 'absolute',
                                                    right: '10px',
                                                    bottom: '10px'
                                                }}>
                                                    <i className="fas fa-copy 16 text-primary"/>
                                                </span>
                                            </CopyToClipboard>

                                        </Col>)}

                                    </Col> : <Col className="pt-2">
                                        <Col>
                                            <Row className="justify-content-between">
                                                <h5 className="text-primary py-2 font-weight-bold">Payment Method</h5>
                                                <a onClick={onBack}
                                                   className="text-primary font-weight-bold py-2">edit
                                                </a>
                                            </Row>
                                            <Row className="justify-content-between font-weight-light">
                                                <p>Card holder</p>
                                                <p>{card?.billing_details?.name || "Card Holder"}</p>
                                            </Row>
                                            <Row className="justify-content-between font-weight-light">
                                                <p>Card number</p>
                                                <p>**** **** **** {card?.card?.last4}</p>
                                            </Row>
                                        </Col>
                                    </Col>}
                                </Col>
                            </Row>

                        </Card>
                        <Button
                            onClick={() => onNext(data?.ref)}
                            color="primary"
                            className="btn-block font-weight-bold">{loading && <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"/>}{bankInfo ? "Confirm Payment" : "Confirm & Pay"}</Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default PaymentReview;

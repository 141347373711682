import {takeEvery, fork, put, all, call} from 'redux-saga/effects';

//Account Redux states
import {OTP_VERIFICATION, REGISTER_USER} from './actionTypes';
import {registerUserSuccessful, registerUserFailed, verifyOTPRequest, OTPSuccessful, OTPFailed} from './actions';

//Include Both Helper File with needed methods
// import {getFirebaseBackend} from '../../../helpers/firebase_helper';
import {postFakeRegister, postJwtRegister} from '../../../helpers/fakebackend_helper';
import {completeRegistration, verifyOTP as OTPVerify} from "../../../services/api";

// initialize relavant method of both Auth
// const fireBaseBackend = getFirebaseBackend();

// Is user register successfull then direct plot user in redux.
// function* registerUser({ payload: { user } }) {
//     try {
//     	if(process.env.REACT_APP_DEFAULTAUTH === "firebase")
//     	{
//             const response = yield call(fireBaseBackend.registerUser, user.email, user.password);
//             yield put(registerUserSuccessful(response));
//     	}
//         else if(process.env.REACT_APP_DEFAULTAUTH === "jwt")
//         {
//              const response = yield call(postJwtRegister, '/post-jwt-register', user);
//             yield put(registerUserSuccessful(response));
//         }
//     	else if(process.env.REACT_APP_DEFAULTAUTH === "fake")
//     	{
//             const response = yield call(postFakeRegister, '/post-fake-register', user);
//             yield put(registerUserSuccessful(response));
//     	}
//     } catch (error) {
//         yield put(registerUserFailed(error));
//     }
// }

function* verifyOTP({payload}) {
    console.log(payload);
    const {otp, email, type} = payload
    try {
        const response = yield OTPVerify(otp, email, type)
        console.log(response);
        if (response.data.success) {
            yield put(OTPSuccessful(response.data?.data))
        } else {
            yield put(OTPFailed(response.message))
        }
    } catch (error) {
        yield put(OTPFailed(error));
    }
}

function* registerUser({payload}) {
    console.log(payload);
    const {data, type, token} = payload
    try {
        const response = yield completeRegistration(type, data, token)
        console.log(response);
        if (response.data.success) {
            yield put(registerUserSuccessful(response.data?.data))
        } else {
            yield put(registerUserFailed(response.data?.data?.error))
        }
    } catch (error) {
        yield put(registerUserFailed(error));
    }
}

export function* watchUserRegister() {
    yield takeEvery(REGISTER_USER, registerUser);
    yield takeEvery(OTP_VERIFICATION, verifyOTP);
}

function* accountSaga() {
    yield all([fork(watchUserRegister)]);
}

export default accountSaga;

import React from 'react';
import {useSelector} from "react-redux";
import {Button, Card, Col, Label, Row} from "reactstrap";
import {CopyToClipboard} from "react-copy-to-clipboard";
import toastr from "toastr";
import account from "../../store/auth/register/reducer";


const WithdrawReview = ({onNext, onBack, bankInfo, loading, data, method}) => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: bankInfo?.currency?.code || 'USD',
    });
    return (
        <Row className="justify-content-center">
            <Col lg={8}>
                <Row className="justify-content-center">
                    <Col lg={8} className="border-bottom border-light">
                        <h1 className="font-weight-light text-center">Review & Withdraw</h1>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col lg={8}>
                        <Card className="mt-3 rounded-0 border-light shadow p-2">
                            {!!bankInfo && <Row className="justify-content-center">
                                <Col>
                                    <Col className="pt-2 border-bottom">
                                        <Col>
                                            <Row>
                                                <h5 className="text-primary py-2 font-weight-bold">Withdraw</h5>
                                            </Row>
                                            <Row className="justify-content-between font-weight-light">
                                                <p>Amount</p>
                                                <p>{formatter.format(bankInfo?.subtotal)}</p>
                                            </Row>

                                            <Row className="justify-content-between font-weight-light">
                                                <p>Fee</p>
                                                <p>{formatter.format(bankInfo?.fee)}</p>
                                            </Row>

                                            <Row className="justify-content-between font-size-16 font-weight-bold">
                                                <p>You will receive:</p>
                                                <p>{formatter.format(bankInfo?.total)}</p>
                                            </Row>
                                        </Col>

                                    </Col>
                                    <Col className="pt-2">
                                        <Col>
                                            <Row className="justify-content-between">
                                                <h5 className="text-primary py-2 font-weight-bold">Method</h5>
                                                <a onClick={onBack}
                                                   className="text-primary font-weight-bold py-2">edit
                                                </a>
                                            </Row>
                                            <Row className="justify-content-between font-weight-light">
                                                <p>Method</p>
                                                <p>{method == 1 ? "Bank transfer" : "Paypal"}</p>
                                            </Row>
                                            {bankInfo?.details?.details?.map(e =>
                                                <Row className="justify-content-between font-weight-light">
                                                    <p>{e?.name}</p>
                                                    <p>{e?.value}</p>
                                                </Row>
                                            )}
                                        </Col>
                                    </Col>
                                </Col>
                            </Row>}

                        </Card>
                        <Button
                            onClick={() => onNext(data?.ref)}
                            color="primary"
                            className="btn-block font-weight-bold">{loading &&
                        <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"/>}Confirm & withdraw</Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default WithdrawReview;

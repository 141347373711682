import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import Invite from "../pages/Authentication/Invite";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
import CreateContract from "../pages/Contract/CreateContract";
import Invoices from "../pages/Invoices";
import Activity from "../pages/Activity";
import ContractList from "../pages/Contract/ContractList";
import History from "../pages/History";
import Documents from "../pages/Documents";
import Payment from "../pages/Payment";
import ContractDetail from "../pages/Contract/ContractDetail";
import InvoiceDetail from "../pages/Invoices/InvoiceDetail";
import CompanySetting from "../pages/CompanySetting";
import Withdrawal from "../pages/Withdrawal";
import UpdatePassword from "../pages/Authentication/UpdatePassword";
import ProfileSettings from "../pages/ProfileSettings";
import TestPaymentCycle from "../pages/TestPaymentCycle/TestPaymentSycle";
import AdminPanel from "../pages/AdminPanel";
import InvoicesGenerator from "../pages/AdminPanel/pages/InvoicesGenerator/InvoicesGenerator";
import TransactionManagement from "../pages/AdminPanel/pages/TransactionManagement/TransactionManagement";
import ContractPage from "../pages/Contract/ContractPage";
import PayInvoices from "../pages/payInvoices";
import WithdrawProcess from "../pages/withdrawProcess";
import UpcomingPayment from "../pages/UpcomingPayment";
import AllPayments from "../pages/AdminPanel/pages/AllPayments";
import PendingTransfers from "../pages/AdminPanel/pages/PendingTransfers";
import ConfirmedTransfers from "../pages/AdminPanel/pages/ConfirmedTransfers";
import Transactions from "../pages/AdminPanel/pages/Transactions";

const userRoutes = [

	{ path: "/activity", component: Activity },
	{ path: "/contract/create", component: CreateContract },
	{ path: "/invoices", component: Invoices },
	{ path: "/documents", component: Documents },
	{ path: "/contracts", component: ContractList },
	{ path: "/history", component: History },
	{ path: "/payment/:id", component: Payment },
	{ path: "/payment", component: Payment },
	{ path: "/upcoming-payment", component: UpcomingPayment },
	{ path: "/contract/detail", component: ContractPage },
	{ path: "/invoice/detail", component: InvoiceDetail },
	{ path: "/settings", component: CompanySetting },
	{ path: "/withdraw", component: Withdrawal },
	{ path: "/profile-settings", component: ProfileSettings },

	// this route should be at the end of all other routes
	{ path: "/", exact: true, component: () => <Redirect to="/activity" /> }
];

const authRoutes = [
	{ path: "/logout", component: Logout },
	{ path: "/login", component: Login },
	{ path: "/forgot-password", component: ForgetPwd },
	{ path: "/update-password", component: UpdatePassword },
	{ path: "/register", component: Register },
	{ path: "/invite", component: Invite },
	{ path: "/test-payment-cycle", component: TestPaymentCycle },
];

const adminPanelRoutes = [
	{ path: "/admin/all-payments", component: AllPayments },
	{ path: "/admin/transactions", component: Transactions },
	{ path: "/admin", exact: true, component: () => <Redirect to="admin/all-payments" />  },
	{ path: "/admin/pending-transfers", exact: true, component: PendingTransfers },
	{ path: "/admin/confirmed-transfers", exact: true, component: ConfirmedTransfers }
]

const modalRoutes = [
	{path: "/pay-invoices/:id", component: PayInvoices},
	{path: "/pay-invoices", component: PayInvoices},
	{path: "/withdrawal-process", component: WithdrawProcess}
]

export { userRoutes, authRoutes, adminPanelRoutes, modalRoutes };

import React, {useEffect, useMemo, useRef, useState} from 'react';
import {CardBody, CardSubtitle, Col, FormGroup, Label} from "reactstrap";
import Select from "react-select";
import AddressPicker from "../../components/AddressPicker";
import {AvField, AvForm} from 'availity-reactstrap-validation';
import {Link} from "react-router-dom";
import Row from "reactstrap/es/Row";

import {getCompanyInfo, updateCompanyInfo} from "../../services/api";
import {useSelector} from 'react-redux';
import profileImg from "../../assets/images/profile-img.png";
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import Dropzone from "react-dropzone";
import {IntercomAPI} from "react-intercom";
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

const CompanyInfo = React.forwardRef((props, ref) => {
    const user = useSelector(state => state.Account?.user)
    const staticData = useSelector(state => state.Layout?.staticData)
    const [data, setData] = useState({});
    const [selectedType, setSelectedType] = useState(data?.type);
    const [selectedCurrency, setSelectedCurrency] = useState(null);
    const [selectedOwner, setSelectedOwner] = useState(null);
    const [editable, setEditable] = useState(false);
    const [completeAddress, setCompleteAddress] = useState({});
    const [loading, setLoading] = useState(false);

    const updateInfo = () => {
        getCompanyInfo(user?.token).then(r => {
            console.log(r);
            if (r.data.success) {
                IntercomAPI('trackEvent', 'Viewed settings - company info');
                setData(r.data.data)
                console.log(r.data.data);
            }
        })
    }

    useEffect(() => {
        updateInfo()
    }, []);

    useEffect(() => {
        const currentCurrency = data?.currency
        setSelectedType({
            value: data?.type?.id,
            label: data?.type?.name
        });
        setSelectedCurrency({
            value: currentCurrency?.id,
            label: currentCurrency?.code
        })
        setSelectedOwner({
            value: data?.owner?.id,
            label: `${data?.owner?.first_name} ${data?.owner?.last_name} (${data?.owner?.email})`
        })
    }, [data, staticData])

    useEffect(() => {
        console.log(selectedCurrency);
    }, [selectedCurrency])


    function handleSelectType(type) {
        setSelectedType(type);
    }

    function handleSelectOwner(type) {
        setSelectedOwner(type);
    }

    return (
        <div>
            <div className="bg-soft-primary rounded-top ">
                <Row>
                    <Col>
                        <div className="text-primary p-3">
                            <h5 className="text-primary">Welcome Back !</h5>
                        </div>
                    </Col>
                    <Col md='2' className="align-self-end">
                        <img src={profileImg} alt="" className="img-fluid"/>
                    </Col>
                </Row>
            </div>
            <CardBody className="py-0">
                <Row className="justify-content-between">
                    <Col sm="2">
                        <div className="avatar-lg profile-user-wid mb-4">
                            {data?.photo ?
                                <img src={avatar1} alt="" className="img-thumbnail rounded-circle"></img> :
                                <span
                                    className="avatar-title rounded-circle font-weight-bolder"
                                    style={{fontSize: "40px"}}>
                                                                                    <i className="bx bx-briefcase-alt-2"></i>
                                                                                </span>
                            }
                            <div style={{
                                marginTop: "-40%",
                                marginLeft: "80%",
                            }}>
                                {editable && <div style={{position: 'absolute'}}>
                                    <Dropzone
                                        onDrop={acceptedFiles => {
                                            // handleAcceptedFiles(acceptedFiles)
                                        }}
                                    >
                                        {({getRootProps, getInputProps}) => (
                                            <div style={{
                                                zIndex: 99999999999,
                                            }}>
                                                <div
                                                    {...getRootProps()}
                                                >
                                                    <input {...getInputProps()} />
                                                    <div className="avatar-xs mr-3">
                            <span
                                className="avatar-title rounded-circle bg-soft-dark text-white font-size-24"
                                style={{
                                    backgroundColor: 'ref'
                                }}>
                                                            <i className="bx bx-camera font-size-18"></i>
                            </span>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Dropzone>
                                </div>}
                            </div>
                        </div>
                    </Col>
                    <Link className="p-3"
                          onClick={() => setEditable(!editable)}>{editable ? 'View' : 'Edit Profile'}</Link>
                </Row>
                <CardSubtitle className="mb-3 ml-3">Company information will be displayed as is in your contracts and
                    invoices
                </CardSubtitle>
                <AvForm className="form-horizontal p-3" ref={ref} onValidSubmit={(e, v) => {
                    if (editable) {
                        console.log(completeAddress, selectedType, selectedCurrency, selectedOwner);
                        if (!completeAddress?.country?.value || !completeAddress.city?.value || !completeAddress.state?.value || !selectedType?.value || !selectedCurrency?.value || !selectedOwner?.value) {
                            toastr.error("You should fill all required fields")
                        } else {
                            setLoading(true)
                            updateCompanyInfo(user?.token, {
                                ...v,
                                country_id: completeAddress?.country?.value,
                                state_id: completeAddress?.state?.value,
                                city_id: completeAddress?.city?.value,
                                type_id: selectedType?.value,
                                currency_id: selectedCurrency?.value,
                                owner_id: selectedOwner?.value
                            }).then(r => {
                                console.log(r);
                                setLoading(false)
                                if (r.data.success) {
                                    IntercomAPI('trackEvent', 'Updated settings  - company info');
                                    updateInfo()
                                    setEditable(false)
                                }
                            }).catch(e => {
                                setLoading(false)
                            })
                        }
                    }
                }}>
                    <FormGroup row>
                        <Label htmlFor="billing-name" md="3" className="col-form-label">Company Name {editable && <span
                            className="text-danger">*</span>}</Label>
                        <Col md="9" className="p-0">
                            {editable ?
                                <AvField name="name" value={data?.name} required type="text" className="form-control"
                                         id="billing-name"/> :
                                <p className="mt-2">
                                    {data.name}
                                </p>}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label md="3" className="col-form-label">Company Type {editable && <span
                            className="text-danger">*</span>}</Label>
                        <Col md="9" className="p-0">
                            {editable ?
                                <><Select
                                    className="mb-3"
                                    name="type_id"
                                    options={staticData?.company_types.map(e => ({value: e.id, label: e.name}))}
                                    onChange={(s) => {
                                        handleSelectType(s);
                                    }}
                                    value={selectedType}
                                    required
                                />
                                </>
                                : <p className="mt-2">
                                    {data?.type?.name}
                                </p>}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label htmlFor="billing-address" md="3" className="col-form-label">Address {editable && <span
                            className="text-danger">*</span>}</Label>
                        <Col md="9" className="p-0">
                            {editable ?
                                <AvField required value={data.address} name="address" type="text"
                                         className="form-control"
                                         id="company-address"></AvField> : <p className="mt-2">
                                    {data.address}
                                </p>}
                        </Col>
                    </FormGroup>
                    {useMemo(() => <AddressPicker
                        data={data}
                        editable={editable}
                        onChange={setCompleteAddress}
                    />)}
                    <FormGroup row>
                        <Label htmlFor="billing-email-address" md="3" className="col-form-label">VAT No
                            (optional)</Label>
                        <Col md="9" className="p-0">
                            {editable ?
                                <AvField value={data.vat} name="vat" type="text" className="form-control" id="vat"
                                         name="vat"/> :
                                <p className="mt-2">
                                    {data.vat}
                                </p>}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label htmlFor="billing-email-address" md="3"
                               className="col-form-label">Registration {editable && <span
                            className="text-danger">*</span>}</Label>
                        <Col md="9" className="p-0">
                            {editable ?
                                <AvField required value={data.registration_no} name="registration_no" type="text"
                                         className="form-control" id="registrationId"/> :
                                <p className="mt-2">
                                    {data.registration_no}
                                </p>}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label htmlFor="billing-address" md="3" className="col-form-label">Currency {editable && <span
                            className="text-danger">*</span>}</Label>
                        <Col md="9" className="p-0">
                            {editable ? <Select
                                className="mb-3"
                                name="city"
                                value={selectedCurrency}
                                onChange={setSelectedCurrency}
                                options={staticData?.currencies ? staticData.currencies.map(c => ({
                                    label: c.code,
                                    value: c.id,
                                })) : []}
                            /> : <p className="mt-2">
                                {selectedCurrency?.label}
                            </p>}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label md="3" className="col-form-label">Account Owner {editable && <span
                            className="text-danger">*</span>}</Label>
                        <Col md="9" className="p-0">
                            {editable ? <Select
                                value={selectedOwner}
                                onChange={(s) => {
                                    handleSelectOwner(s);
                                }}
                                options={data?.admins?.map(e => ({
                                    value: e.id,
                                    label: `${e.first_name} ${e.last_name} (${e.email})`
                                }))}
                            /> : <p className="mt-2">
                                {`${data?.owner?.first_name} ${data?.owner?.last_name} (${data?.owner?.email})`}
                            </p>}
                        </Col>
                    </FormGroup>
                    {editable && <Row className="my-4">
                        <Col></Col>
                        <Col sm="6">
                            <div className="text-sm-right">
                                <Link onClick={() => {
                                    ref.current.submit()
                                }} to="#" className="btn btn-success">
                                    {loading &&
                                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>} Save </Link>
                            </div>
                        </Col>
                    </Row>}
                </AvForm>
            </CardBody>
        </div>
    );
})

export default CompanyInfo;

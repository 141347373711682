import React, {useEffect, useState} from 'react';
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Col,
    Container,
    FormGroup,
    Input,
    Label,
    Media,
    Modal,
    Row,
    Spinner
} from "reactstrap";
import CardContact from "./card-contact";
import SweetAlert from "react-bootstrap-sweetalert";
import {
    cancelInvitation,
    downloadContract as downloadPdf,
    getContractorInfo,
    inviteContractPart,
    signContract, terminateContract
} from "../../../services/api";
import {useSelector} from 'react-redux';
import {host, storage} from "../../../helpers/config";
import SubmitMilestoneModal from "./components/SubmitMilestoneModal";
import SubmitWorkModal from "./components/SubmitWorkModal";
import AddMilestoneModal from "./components/AddMilestoneModal";
import KYCModal from "./components/KYCModal";
import {IntercomAPI} from "react-intercom";
import moment from "moment";
import {useFetch} from "../../../helpers/hooks";
import ConfirmationModal from "../../../components/Common/ConfirmationModal";

const ContractDetail = ({id, history, data, updateContractDetail, loading, onShowTerminatedModal}) => {
    const [reports, setReports] = useState([
        {title: "Contract type", iconClass: "bx-receipt", description: "--"},
        {title: "Start Date", iconClass: "bx-task", description: "--"},
        {title: "Payment Schedule", iconClass: "bx-money", description: "--"}
    ]);

    const [showInvitePopup, setShowInvitePopup] = useState(false);
    const [kycRequired, setKycRequired] = useState(false);
    const [showSignModal, setShowSignModal] = useState(false);
    const [email, setEmail] = useState(null);
    const [fullName, setFullName] = useState(null);
    const user = useSelector(state => state.Account?.user)
    const [clientSignature, setClientSignature] = useState(null);
    const [contractorSignature, setContractorSignature] = useState(null);
    const [pdf, setPdf] = useState(null);
    const [profile, setProfile] = useState({});
    const [addMilestone, setAddMilestone] = useState(false);
    const [submitMilestone, setSubmitMilestone] = useState(false);
    const [submitWork, setSubmitWork] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const [showTerminateConfirmation, setShowTerminateConfirmation] = useState(false);
    const [showNeedToCompleteProfile, setShowNeedToCompleteProfile] = useState(false);

    const cancel = useFetch({
        autoFetch: false, initResult: null, action: cancelInvitation, onComplete: updateContractDetail

    })
    const terminate = useFetch({
        action: terminateContract,
        onComplete: updateContractDetail
    })
    console.log(data, profile, id);

    useEffect(() => {
        updateContractDetail()
        getContractorInfo(user?.token).then(r => {
            console.log('kyc', r);
            if (r.data.success) {
                setProfile({
                    ...r.data.data,
                    photo: r.data?.data?.photo ? `${storage}${r.data?.data?.photo}` : r.data?.data?.photo
                })
            }
        })
    }, [])

    const cancelInvite = () => {
        cancel.startFetch({contract_id: data?.id})
    }

    const handleTerminateContract = () => {
        setShowTerminateConfirmation(false)
        terminate.startFetch({contract_id: data.id})
    }

    const downloadContract = (ref, token) => {
        fetch(`${host}/api/contract/show/${ref}/${token}`,).then(r => {
            console.log(r);
            return r.blob()
        }).then(res => {
            const file = window.URL.createObjectURL(res);
            setPdf(file)
            console.log(res);
        }).catch(e => {
            console.log(e);
        })
    }
    const handleDownloadContract = () => {
        IntercomAPI('trackEvent', 'Clicked on Download contract', {
            contract_id: data.ref,
        })
        setDownloading(true)
        downloadPdf(data?.ref, user?.token, data?.file).then(r => {
            setDownloading(false)
            console.log(r.data);
            const url = window.URL.createObjectURL(new Blob([r.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${data?.ref}.pdf`); //or any other extension
            document.body.appendChild(link);
            link.click();
        }).catch(e => {
            setDownloading(false)
        })
    }
    const terminationDate = data?.timeline?.find(e => e.name == "Contract Terminated")?.date
    useEffect(() => {
        const newData = {...data}
        downloadContract(newData?.ref, newData?.file)
        // const terminationDate = newData?.timeline?.find(e => e.name == "Contract Terminated")
        console.log("terminationDate", terminationDate);
        setReports([
            {title: "Contract type", iconClass: "bx-receipt", description: newData?.type},
            {
                title: "Start Date",
                iconClass: "bx-task",
                description: newData?.type != "Milestones" ? newData?.start_date : "--"
            },
            {
                title: terminationDate ? "Terminated" : "Next Payment",
                iconClass: "bx-money",
                color: terminationDate ? "danger" : "primary",
                description: terminationDate ? moment(terminationDate, "MM/DD/YYYY hh:mm").format("MMM DD, YYYY") : newData?.next_payment?.due_date ? moment(newData?.next_payment?.due_date, "YYYY-MM-DD").format("MMM DD, YYYY") : "upon work submission"
            }
        ])
        if (newData?.client) {
            setClientSignature(newData.signatures?.find(e => e?.user_id == newData?.client?.id))
        }
        if (newData?.contractor) {
            setContractorSignature(newData.signatures?.find(e => e?.user_id == newData?.contractor?.id))
        }
        setFullName(`${newData[user?.type]?.first_name} ${newData[user?.type]?.last_name}`)
    }, [data])

    function createMarkup(text) {
        return {__html: text};
    }

    return loading ?
        (<Col style={{minHeight: '30rem'}}>
            <Row style={{minHeight: '30rem'}} className="justify-content-center align-items-center">
                <Spinner type="grow" className="mr-2" color="primary"/>
            </Row>
        </Col>)
        :
        (<><Row>
            <Col xl="8">
                <Row>
                    {reports.map((report, key) =>
                        <Col md="4" key={"_col_" + key}>

                            <Card
                                onClick={() => {
                                    console.log('clicked');
                                }}
                                className="mini-stats-wid h-75" color={report.color}>
                                <CardBody>
                                    <Media>
                                        <Row className="align-items-center">
                                            <div className="mr-2 p-2">
                                                                <span>
                                                                    {key == 2 ?
                                                                        <i className={"bx " + report.iconClass + " font-size-24 text-white"}></i> :
                                                                        <i className={"bx " + report.iconClass + " font-size-24 text-primary"}></i>}
                                                                </span>
                                            </div>
                                            <Media body>
                                                {key == 2 ?
                                                    <h6 className={"font-weight-medium text-white"}>{report.title}</h6> :
                                                    <h6 className={"font-weight-medium text-muted"}>{report.title}</h6>}
                                                {key == 2 ?
                                                    <h5 className={"mb-0 text-white"}>{report.description}</h5> :
                                                    <h5 className={"mb-0 text-muted"}>{report.description}</h5>}
                                            </Media>

                                        </Row>
                                    </Media>
                                </CardBody>
                            </Card>
                        </Col>
                    )}
                </Row>
                <Row>
                    <Col>
                        <Card className="overflow-hidden">
                            <CardBody>
                                {!!data?.client &&
                                <Row className="mb-3">
                                    <Col md={9}>
                                        <CardContact label="Client" user={data?.client}
                                                     key={"_client_" + data?.client?.id}/>
                                    </Col>
                                    <Col md={3}>
                                        <Row className="justify-content-center">
                                            <Col className="p-0">
                                                {1 == 0 && <SweetAlert
                                                    showCancel
                                                    title="Submit email to send invitation"
                                                    cancelBtnBsStyle="danger"
                                                    confirmBtnBsStyle="success"
                                                    confirmBtnText="Send invitation"
                                                    onConfirm={() => {
                                                        inviteContractPart(user?.token, {
                                                            email,
                                                            contract_id: data?.id,
                                                        }).then(r => {
                                                            console.log(r);
                                                            updateContractDetail()
                                                        })
                                                        setShowInvitePopup(false);
                                                    }}
                                                    onCancel={() => {
                                                        setShowInvitePopup(false)
                                                    }}
                                                >
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        placeholder="Enter Email"
                                                        onChange={e => setEmail(e.target.value)}
                                                    />
                                                </SweetAlert>}
                                                {!data?.contractor ? <Button
                                                    color="primary"
                                                    className="waves-effect btn-block waves-light mt-2"
                                                    onClick={() => {
                                                        setShowInvitePopup(true)
                                                    }}
                                                >
                                                    Invite Contractor
                                                </Button> : !!data?.client && !!data?.contractor && !clientSignature && user?.type == 'client' ?
                                                    <Button
                                                        color="primary"
                                                        className="waves-effect btn-block waves-light"
                                                        onClick={() => {
                                                            if (terminationDate){
                                                                onShowTerminatedModal()
                                                            } else if (data?.can_sign) {
                                                                setShowSignModal(true)
                                                            } else {
                                                                setShowNeedToCompleteProfile(true)
                                                            }
                                                        }}
                                                    >
                                                        Sign
                                                    </Button> : !!clientSignature ? <Button
                                                        color="success"
                                                        className="btn-block"
                                                        disabled
                                                    >
                                                        Signed
                                                    </Button> : user.type == 'client' ? <Button
                                                        color="success"
                                                        className="btn-block"
                                                        disabled
                                                    >
                                                        Created
                                                    </Button> : <Button
                                                        color="success"
                                                        className="btn-block"
                                                        onClick={cancelInvite}
                                                        disabled
                                                    >
                                                        {cancel.isLoading &&
                                                        <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>}Created
                                                    </Button>}

                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>}
                                {!!data?.contractor && <Row className="mb-3">
                                    <Col md={9}>
                                        <CardContact label="Contractor" user={data?.contractor}
                                                     key={"_contractor_12" + data?.contractor?.id} isContarctor
                                                     isKyc={data?.kyc == 1}/>
                                    </Col>
                                    <Col md={3}>
                                        <Row className="justify-content-center">
                                            <Col className="p-0">
                                                {1 == 0 && <SweetAlert
                                                    showCancel
                                                    title="Submit email to send invitation"
                                                    cancelBtnBsStyle="danger"
                                                    confirmBtnBsStyle="success"
                                                    confirmBtnText="Send invitation"
                                                    onConfirm={() => {
                                                        inviteContractPart(user?.token, {
                                                            email,
                                                            contract_id: data?.id,
                                                        }).then(r => {
                                                            console.log(r);
                                                            updateContractDetail()
                                                        })
                                                        setShowInvitePopup(false);
                                                    }
                                                    }
                                                    onCancel={() => {
                                                        setShowInvitePopup(false)
                                                    }}
                                                >
                                                    <input
                                                        type="email"
                                                        className="form-control"
                                                        placeholder="Enter Email"
                                                        onChange={e => setEmail(e.target.value)}
                                                    />
                                                </SweetAlert>}

                                                <KYCModal toggle={() => setKycRequired(false)} isOpen={kycRequired}
                                                          proceed={() => {
                                                              setKycRequired(false)
                                                              history.push('/settings/#kyc')
                                                          }}/>

                                                {!data?.client && user?.type != 'client' ? <Button
                                                    color="primary"
                                                    className="waves-effect btn-block waves-light "
                                                    onClick={() => {
                                                        setShowInvitePopup(true)
                                                    }}
                                                >
                                                    Invite Client
                                                </Button> : !!data?.client && !contractorSignature && user.type == 'contractor' ?
                                                    <Button
                                                        color="primary"
                                                        className="waves-effect btn-block waves-light"
                                                        onClick={() => {
                                                            if (terminationDate) {
                                                                onShowTerminatedModal()
                                                            } else if (data?.kyc == 1 && profile?.kyc_verified != 1) {
                                                                setKycRequired(true)
                                                            } else if (data.can_sign) {
                                                                setShowSignModal(true)
                                                            } else {
                                                                setShowNeedToCompleteProfile(true)
                                                            }
                                                        }}
                                                    >
                                                        Sign
                                                    </Button> : !!contractorSignature ? <Button
                                                        color="success"
                                                        className="btn-block"
                                                        disabled
                                                    >
                                                        Signed
                                                    </Button> : user.type == 'contractor' ? <Button
                                                        color="success"
                                                        className="btn-block"
                                                        disabled
                                                    >
                                                        Created
                                                    </Button> : <Button
                                                        color="danger"
                                                        className="btn-block"
                                                        onClick={cancelInvite}
                                                    >
                                                        {cancel.isLoading &&
                                                        <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>}Cancel
                                                    </Button>}
                                            </Col>
                                        </Row>
                                    </Col>

                                </Row>}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <Media>
                                    <Media className="overflow-hidden" body>
                                        <h5 className="text-truncate font-size-15 text-primary">Project
                                            name</h5>
                                        <p className="text-muted">{data?.name}</p>
                                    </Media>
                                </Media>

                                <h5 className="font-size-15 mt-4 text-primary">Scope</h5>
                                <div className="text-muted" dangerouslySetInnerHTML={createMarkup(data?.scope)}/>
                                {/*<div className="text-muted">{data?.scope}</div>*/}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    {data?.milestones ?
                        null : <Col>
                            <Card>
                                <CardBody>
                                    <Media>
                                        <Media className="overflow-hidden" body>
                                            <h5 className="text-truncate font-size-15 text-primary">Payment
                                                detail</h5>
                                            <div className="text-muted mt-4">
                                                {/*<p className="text-muted">{data?.extra}</p>*/}
                                                {!!data?.frequency &&
                                                <p><i
                                                    className="mdi mdi-chevron-right text-primary mr-1"></i><span
                                                    className="text-dark font-weight-bold">Scheduled Payment:</span> {data?.frequency?.name}
                                                </p>}
                                                {!!data?.start_date && <p>
                                                    <i
                                                        className="mdi mdi-chevron-right text-primary mr-1"></i>
                                                    <span
                                                        className="text-dark font-weight-bold">Effective Date:</span> {data?.start_date}
                                                </p>}
                                                {!!data?.amount && <p>
                                                    <i className="mdi mdi-chevron-right text-primary mr-1"></i>
                                                    <span
                                                        className="text-dark font-weight-bold">Total</span>: {data?.amount} {data?.currency?.code || ""} {data?.rate_id?.name || ""}
                                                </p>}
                                                {!!data?.first_payment_date && <p>
                                                    <i className="mdi mdi-chevron-right text-primary mr-1"></i>
                                                    <span
                                                        className="text-dark font-weight-bold">First Payment</span>: {data?.first_payment_date}
                                                </p>}
                                                {!!data?.occurrence && <p>
                                                    <i className="mdi mdi-chevron-right text-primary mr-1"></i>
                                                    <span
                                                        className="text-dark font-weight-bold">Occurrence</span>: {data?.occurrence?.name}
                                                </p>}
                                                {!!data?.first_payment_prorata && <p>
                                                    <i className="mdi mdi-chevron-right text-primary mr-1"></i>
                                                    <span
                                                        className="text-dark font-weight-bold">Prorata on first payment</span>
                                                </p>}
                                            </div>
                                        </Media>
                                    </Media>
                                </CardBody>
                            </Card>
                        </Col>}

                    <AddMilestoneModal
                        contract={data} isOpen={addMilestone} toggle={() => setAddMilestone(false)}
                        onMilestoneAdded={updateContractDetail} currency={data?.currency}/>
                    <SubmitMilestoneModal isOpen={submitMilestone} toggle={() => setSubmitMilestone(false)}/>
                    <SubmitWorkModal contract={data} isOpen={submitWork} toggle={() => setSubmitWork(false)}/>

                </Row>
            </Col>
            <Col xl="4">
                <Card>
                    <CardBody>
                        <CardTitle className="mb-5">Contract Timeline</CardTitle>
                        <div className="">
                            <ul className="verti-timeline list-unstyled">
                                {
                                    data?.timeline?.map((status, key) =>
                                        <li key={key} className="event-list">
                                            <div className="event-timeline-dot font-size-24"
                                                 style={{marginLeft: "-4px"}}>
                                                <i className={status.completed == 1 ? `bx bxs-check-circle ${status.name == "Contract Terminated" ? "text-danger" : "text-primary"} ` : "bx bx-x-circle text-danger"}></i>
                                            </div>
                                            <div className="media">
                                                <div className="media-body">
                                                    <div>
                                                        <h5>{status?.name}</h5>
                                                        <span className="text-muted">{status?.date}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                }
                            </ul>
                        </div>
                    </CardBody>
                    <CardFooter className="p-0">
                        <button onClick={handleDownloadContract}
                                className="btn btn-block btn-primary mt-3">{downloading &&
                        <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>}Download
                        </button>
                        {!terminationDate && <button onClick={() => setShowTerminateConfirmation(true)}
                                                     className="btn btn-block btn-white mt-3">{terminate.isLoading &&
                        <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>}Terminate
                        </button>}
                        <ConfirmationModal
                            title={`Terminate Contract`}
                            isOpen={showTerminateConfirmation}
                            message="Are you sure you want to permanently terminate this contract? This action is irreversible, please review any pending submitted work before you proceed."
                            toggle={() => setShowTerminateConfirmation(false)}
                            onConfirm={handleTerminateContract}/>
                    </CardFooter>
                </Card>
            </Col>
            <ConfirmationModal
                title="Incomplete Profile"
                message="You need to complete your profile before sign this contract"
                isOpen={showNeedToCompleteProfile}
                toggle={() => {
                    setShowNeedToCompleteProfile(false)
                }}
                caption="Complete profile now"
                onConfirm={() => {
                    history.push('/settings')
                }}
            />
        </Row>

            <Modal
                isOpen={showInvitePopup}
                toggle={() => {
                    setShowInvitePopup(false)
                }}
            >
                <div className="modal-header">
                    <Col className="p-0">
                        <h5
                            className="modal-title mt-0"
                            id="myLargeModalLabel"
                        >
                            Invite your {user?.type == 'client' ? 'contractor' : 'client'}
                        </h5>
                        <p className="text-muted">Enter the email address of
                            the {user?.type == 'client' ? 'contractor' : 'client'}. An invitation email will be
                            sent to them</p>
                    </Col>
                    <button
                        onClick={() => {
                            setShowInvitePopup(false)
                        }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body pl-4 pr-4">
                    <Row className="justify-content-center">
                        <Col className="p-0">
                            <FormGroup className="justify-content-center">
                                <Label
                                    for="basicpill-pancard-input5">E-mail</Label>
                                <Input
                                    type="email"
                                    name="email"
                                    className="form-control"
                                    id="basicpill-pancard-input5"
                                    onChange={e => {
                                        setEmail(e.target.value);
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
                <div className="modal-footer">
                    {/*<Row className="justify-content-between">*/}
                    <button className="btn btn-primary" onClick={() => {
                        inviteContractPart(user?.token, {
                            email,
                            contract_id: data?.id,
                        }).then(r => {
                            if (r.data.success) {
                                if (user?.type == 'client') {
                                    IntercomAPI('trackEvent', 'Invited contrator', {
                                        contract_id: data?.ref,
                                        contractor_email: email,
                                    })
                                } else {
                                    IntercomAPI('trackEvent', 'Invited client', {
                                        contract_id: data?.ref,
                                        client_email: email,
                                    })
                                }
                                console.log(r);
                                updateContractDetail()
                            }
                        })
                        setShowInvitePopup(false);
                    }}>Send invite
                    </button>
                    {/*</Row>*/}
                </div>
            </Modal>
            <Modal
                size="lg"
                isOpen={showSignModal}
                scrollable
                toggle={() => {
                    setShowSignModal(false)
                }}
            >
                <div className="modal-header">
                    <h5
                        className="modal-title mt-0"
                        id="myLargeModalLabel"
                    >
                        Contract #{data?.ref}
                    </h5>
                    <button
                        onClick={() => {
                            setShowSignModal(false)
                        }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body p-0">
                    <iframe frameBorder="0" src={pdf} width="100%" type="application/pdf"
                            style={{"overflow": "auto", height: "80vh"}}>
                    </iframe>
                </div>
                <div className="modal-footer">
                    <Container>
                        <Row className="mb-3 justify-content-end">
                            {user?.type == 'contractor' && <Col xl={5}>
                                <Input placeholder="Client" className="mb-1" onChange={e => {
                                    setFullName(e.target.value)
                                }} disale={user?.type == 'client'} value={user?.type != 'client' ? fullName : ""}/>
                                <h6 className="text-muted">Enter your full name [contractor]</h6>
                            </Col>}
                            {user?.type == 'client' && <Col xl={5}>
                                <Input contractor="Contractor" className="mb-1" disable={user?.type == 'contractor'}
                                       value={user?.type == 'client' ? fullName : ""} onChange={e => {
                                    setFullName(e.target.value)
                                }}/>
                                <h6 className="text-muted">Enter your full name [client]</h6>
                            </Col>}
                        </Row>
                        <Row>
                            <Col>
                                <button
                                    onClick={() => {
                                        IntercomAPI('trackEvent', 'Clicked on sign contract', {
                                            contract_id: data?.ref,
                                        })
                                        signContract(user?.token, {
                                            name: fullName,
                                            contract_id: data?.id
                                        }).then(r => {
                                            if (r.data.success) {
                                                IntercomAPI('trackEvent', 'Signed', {
                                                    contract_id: data?.ref,
                                                })
                                                setShowSignModal(false)
                                            }
                                            updateContractDetail()
                                        })
                                    }}
                                    type="button"
                                    className="btn btn-primary btn-block"
                                    data-dismiss="modal"
                                >
                                    <span aria-hidden="true">AGREE & SIGN</span>
                                </button>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Modal></>)

}

export default ContractDetail;

import React, {useEffect, useState} from 'react';
import {Badge, Card, CardBody, Col, Container, Modal, Row, Spinner, Table} from "reactstrap";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {confirmTransaction, getTransactionList, transferTransaction} from "../../../../services/api";
import Select from "react-select";
import moment from "moment";
import {useFetch} from "../../../../helpers/hooks";

const getStatusColor = (status) => {
    switch (status) {
        case 'Pending':
            return 'warning';
        case 'Paid':
            return 'success';
        case 'Overdue':
            return 'danger';
        default:
            return 'primary';
    }
}
const formatter = new Intl.NumberFormat('en-US', {
    maximumSignificantDigits: 20
});
const TransactionManagement = () => {
    const transfer = useFetch({
        autoFetch: false,
        initResult: null,
        action: transferTransaction,
    });
    const [data, setData] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [toConfirm, setToConfirm] = useState(null);
    const user = useSelector(state => state.Account?.user)
    const [loading, setLoading] = useState(true);
    const updateList = () => {
        getTransactionList(user?.token, {
            // "transferred": 0,
            // "confirmed": 0,
            // "payment_method_id": 1
        }).then(r => {
            setLoading(false)
            if (r.data.success) {
                console.log(r.data.data);
                setData(r.data?.data)
            }
        }).catch(e => {
            setLoading(false)
            console.log(e);
        })
    }
    const handleCheck = (i, e) => {
        setData(data.map((c, idx) => ({...c, selected: i == idx ? e.target.checked : c.selected})))
    }
    const handleTransfer = () => {
        const selectedList = data?.filter(e => e.selected)
        if (selectedList?.length > 0) {
            transfer.startFetch({
                "transaction_item_ids": selectedList.map(t => t?.id)
            })
        }
    }
    useEffect(() => {
        updateList()
    }, [])

    return (
        <div className="page-content">
            {loading ? <Container style={{minHeight: '30rem'}}>
                <Col style={{minHeight: '30rem'}}>
                    <Row style={{minHeight: '30rem'}} className="justify-content-center align-items-center">
                        <Spinner type="grow" className="mr-2" color="primary"/>
                    </Row>
                </Col>
            </Container> : <Container fluid>
                <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                <Row className="justify-content-end">
                                    <button onClick={handleTransfer}
                                            className={`btn px-3 m-3 ${data?.filter(e => e.selected)?.length > 0 ? 'btn-primary' : 'btn-secondary'}`}>{transfer?.isLoading &&
                                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>}Transfer
                                    </button>
                                </Row>
                                <div className="table-responsive">
                                    <Table className="table table-centered table-nowrap">
                                        <thead className="thead-light">
                                        <tr>
                                            <th className="border-top-0">{""}</th>
                                            <th className="border-top-0">Transaction ID</th>
                                            <th className="border-top-0">Total</th>
                                            <th className="border-top-0">Currency</th>
                                            <th className="border-top-0">Payment Date</th>
                                            <th className="border-top-0">Payment Status</th>
                                            <th className="border-top-0">Payment Method</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            data?.map((order, key) =>
                                                <tr key={"_order_" + key}>
                                                    <th style={{width: "20px"}}>
                                                        <div className="custom-control custom-checkbox">
                                                            <input disabled={order.status != 'Confirmed'}
                                                                   type="checkbox" className="custom-control-input"
                                                                   onChange={(e) => handleCheck(key, e)}
                                                                   checked={order.checked ? "checked" : null}
                                                                   id={`genInv${key}`}/>
                                                            <label className="custom-control-label"
                                                                   htmlFor={`genInv${key}`}>&nbsp;</label>
                                                        </div>
                                                    </th>
                                                    <td>
                                                        {order.ref}
                                                    </td>
                                                    <td className="text-right">
                                                        {formatter.format(order.amount)}
                                                    </td>
                                                    <td>
                                                        {order.currency?.code}
                                                    </td>
                                                    <td>
                                                        {moment(order.created_at).format('MM/DD/YYYY')}
                                                    </td>
                                                    <td>
                                                        <>{order.status == 'Confirmed' ? <Badge
                                                            className={"font-size-12 rounded badge-success my-2"}
                                                            color={"white"}
                                                            pill>{order.status}</Badge> : <Select
                                                            className="border-0"
                                                            name="currency_id"
                                                            onChange={(s) => {
                                                                setToConfirm(order)
                                                                setIsOpen(true)
                                                                // setFirstPayment(s?.value);
                                                            }}
                                                            // styles={simpleStyles}
                                                            defaultValue={{value: order.status, label: order.status}}
                                                            options={[
                                                                {value: 'Confirmed', label: 'Confirmed'},
                                                            ]}
                                                            components={{Option: CustomOption}}
                                                        />}

                                                        </>
                                                    </td>
                                                    <td>
                                                        {order.method?.name}
                                                    </td>
                                                </tr>
                                            )
                                        }

                                        </tbody>
                                    </Table>
                                    <ConfirmationModal
                                        isOpen={isOpen}
                                        toggle={() => setIsOpen(false)}
                                        onConfirm={() => {
                                            confirmTransaction(user?.token, {
                                                transaction_id: toConfirm?.id
                                            }).then(r => {
                                                setIsOpen(false)
                                                if (r.data.success) {
                                                    updateList()
                                                }
                                            })
                                        }}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>}
        </div>
    );
};

const ConfirmationModal = ({isOpen, toggle, onConfirm}) => <Modal
    isOpen={isOpen}
    toggle={toggle}
>
    <div className="modal-content">
        <div className="modal-header">
            <h5 className="modal-title mt-0">Submit milestone</h5>
            <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={toggle}
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div className="modal-body">
            <p>Are you sure ?</p>
        </div>
        <div className="modal-footer">
            <button
                type="button"
                className="btn btn-primary"
                onClick={onConfirm}
            >
                Submit
            </button>
            <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={toggle}
            >
                Cancel
            </button>
        </div>
    </div>

</Modal>

const CustomOption = (props) => {
    console.log('CustomOption', props);
    const {innerProps, isDisabled} = props
    return !isDisabled ? (
        <div {...innerProps} className="p-2">
            <Badge
                className={"font-size-12 rounded badge-success"}
                color={"white"}
                pill>{props.value}</Badge>
        </div>
    ) : null;
}

const CustomSingleValue = (props) => {
    console.log('CustomOption', props);
    const {innerProps, isDisabled} = props
    return !isDisabled ? (
        <div {...innerProps} style={{backgroundColor: 'pink'}}>
            {/*<Badge*/}
            {/*    className={"font-size-12 badge-soft-" + getStatusColor(props.value)}*/}
            {/*    color={"white"}*/}
            {/*    pill>{props.value}</Badge>*/}
            <p>{props.value}</p>
        </div>
    ) : null;
}


export default TransactionManagement;

import React, {useState} from 'react';
import {
    Badge,
    Card,
    CardBody,
    Col,
    Container,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input,
    Row,
    Spinner,
    Table,
    UncontrolledTooltip
} from "reactstrap";
import {withNamespaces} from 'react-i18next';
import {downloadDoc, getContractorDocs, getContractors} from "../../services/api";
import {useSelector} from "react-redux";
import {useFetch} from "../../helpers/hooks";
import NoContent from "../../components/NoContent";
import {storage} from "../../helpers/config";

const Documents = (props) => {
    const docs = useFetch({
        autoFetch: true, initResult: [], action: getContractors
    })
    console.log(docs.data);
    const user = useSelector(state => state.Account?.user)
    return (
        <div className="page-content">
            {docs.isLoading ?
                (<Col style={{minHeight: '30rem'}}>
                    <Row style={{minHeight: '30rem'}} className="justify-content-center align-items-center">
                        <Spinner type="grow" className="mr-2" color="primary"/>
                    </Row>
                </Col>)
                : <Container fluid>
                    {docs.data?.length == 0 ? <Card>
                        <CardBody>
                            <NoContent
                                title="No documents added"
                                subtitle={"All compliance documents will be shown here when added by the contractor"}
                                image={require('./../../assets/images/empty_docs.png')}
                            />
                        </CardBody>
                    </Card> : <Row>
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <Row className="mb-2">
                                        <Col sm="4">
                                            {/*<div className="search-box mr-2 mb-2 d-inline-block">*/}
                                            {/*    <div className="position-relative">*/}
                                            {/*        <Input type="text" className="form-control"*/}
                                            {/*               placeholder="Search..."/>*/}
                                            {/*        <i className="bx bx-search-alt search-icon"></i>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                        </Col>
                                        <Col sm="8">
                                        </Col>
                                    </Row>

                                    <div className="table-responsive">
                                        <Table className="table table-centered table-nowrap text-muted">
                                            <thead className="thead-light">
                                            <tr>
                                                <th className="border-top-0 text-muted">Contractor</th>
                                                <th className="border-top-0 text-muted">ID Number</th>
                                                <th className="border-top-0 text-muted">Active Contract</th>
                                                <th className="border-top-0 text-muted">KYC Level1</th>
                                                <th className="border-top-0 text-muted">KYC Level2</th>
                                                <th className="border-top-0 text-muted">Download</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                docs.data?.map((doc, key) => <tr key={"_order_" + key}>
                                                        <td><Col>
                                                            <Row className="align-items-center">
                                                                {
                                                                    !doc.img ? <div className="avatar-xs">
                                                                                <span
                                                                                    className="avatar-title rounded-circle">
                                                                                    {doc.first_name?.charAt(0)}
                                                                                </span>
                                                                        </div>
                                                                        : <div>
                                                                            <img className="rounded-circle avatar-xs"
                                                                                 src={`${storage}${doc.img}`} alt=""/>
                                                                        </div>
                                                                }
                                                                <div
                                                                    style={{marginLeft: "10px"}}>{doc.first_name} {doc.last_name}</div>
                                                            </Row>
                                                        </Col></td>
                                                        <td>
                                                            {doc.id_number}
                                                        </td>
                                                        <td className="text-center">
                                                            {doc.contracts}
                                                        </td>
                                                        <td>
                                                            <Badge
                                                                className={"font-size-12 rounded badge-" + (doc.kyc_level_1 == 'Verified' ? "success" : "danger")}
                                                                color="white"
                                                                pill>
                                                                {doc.kyc_level_1}
                                                            </Badge>
                                                        </td>
                                                        <td>
                                                            <Badge
                                                                className={"font-size-12 rounded badge-" + (doc.kyc_level_2 == 'Verified' ? "success" : "danger")}
                                                                color="white"
                                                                pill>{doc.kyc_level_2}</Badge>
                                                        </td>
                                                        <td>
                                                            {doc?.documents?.length > 0 &&
                                                            <Col>
                                                                <DownloadDropDown docs={doc.documents}/>
                                                            </Col>
                                                            }
                                                        </td>
                                                    </tr>
                                                )
                                            }

                                            <div className="p-lg-5"></div>
                                            <div className="p-lg-5"></div>
                                            </tbody>
                                        </Table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>}
                </Container>}
        </div>
    );
}

const DownloadDropDown = ({docs}) => {
    console.log(docs);
    const [isOpen, setIsOpen] = useState(false);
    const user = useSelector(state => state.Account?.user)
    const handleDownloadDocs = (doc) => {
        downloadDoc(user?.token, {
            id: doc.id
        }).then(r => {
            console.log(r);
            const url = window.URL.createObjectURL(new Blob([r.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${doc?.name}.jpeg`); //or any other extension
            document.body.appendChild(link);
            link.click();
        }).catch(e => {
            console.log(e);
        })
    }
    const documents = useFetch({
        autoFetch: docs?.length > 0, initResult: [], action: getContractorDocs, body: docs[0]
    })
    console.log("documentsDebug", documents);
    return <Dropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
        <DropdownToggle tag="a" className="text-muted">
            <div className="text-primary">
                <i className="fas fa-download mr-1"
                   id="deletetooltip"></i><span>Documents</span><i className="fas fa-angle-down ml-1"
                                                                   id="deletetooltip"></i>
                <UncontrolledTooltip placement="top" target="deletetooltip">
                    Download
                </UncontrolledTooltip>
            </div>
        </DropdownToggle>
        <DropdownMenu right>
            {documents.data?.map(doc => <DropdownItem onClick={() => handleDownloadDocs(doc)}
                                                      href="#">{doc?.name}</DropdownItem>)}
        </DropdownMenu>
    </Dropdown>
}

export default withNamespaces()(Documents);

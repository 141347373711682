import React, {useEffect, useRef, useState} from 'react';

import {
    Badge,
    Button,
    CardTitle,
    Col,
    FormGroup,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Table,
    UncontrolledTooltip
} from "reactstrap";
import {Link} from "react-router-dom";
import Select from "react-select";
import {changeUserStatus, getUsers, sendUserInvitation} from "../../services/api";
import {useSelector} from 'react-redux';
import {AvField, AvForm} from 'availity-reactstrap-validation';
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import {IntercomAPI} from "react-intercom";
import ConfirmationModal from "../../components/Common/ConfirmationModal";
import {useFetch} from "../../helpers/hooks";
import {storage} from "../../helpers/config";

const Users = () => {
    const [modal, setModal] = useState(false);

    const user = useSelector(state => state?.Account?.user)
    const staticData = useSelector(state => state?.Layout?.staticData)
    const [selectedType, setSelectedType] = useState(staticData?.roles.map(e => ({value: e.id, label: e.name}))[0]);
    const [archiveModal, setArchiveModal] = useState(false);
    const [statusModal, setStatusModal] = useState(false);

    const changeStatus = useFetch({
        autoFetch: false, action: changeUserStatus, onComplete: () => {
            setArchiveModal(false);
            setStatusModal(false);
            updateUsersList()
        }
    })
    function handleSelectType(type) {
        setSelectedType(type);
    }

    const formRef = useRef()

    const handleArchive = (id) => {
        changeStatus.startFetch({
            user_id: id,
            status: "archived"
        })
    }

    const handleStatusChange = (id, status) => {
        changeStatus.startFetch({
            user_id: id,
            status: status ? "inactive" : "active"
        })
    }

    const [data, setData] = useState([]);
    useEffect(() => {
        updateUsersList()
    }, [])

    const updateUsersList = () => {
        getUsers(user?.token).then(r => {
            console.log(r);
            if (r.data?.success) {
                IntercomAPI('trackEvent', 'Viewed settings - users');
                setData(r.data?.data)
            }
        })
    }

    useEffect(() => {
        setSelectedType(staticData?.roles.map(e => ({value: e.id, label: e.name}))[0])
    }, [staticData])

    const handleSubmitInvite = (e, v) => {
        IntercomAPI('trackEvent', 'Clicked Add User');
        console.log(selectedType);
        sendUserInvitation(user?.token, {...v, role_id: selectedType?.value}).then(r => {
            console.log(r);
            if (r.data.success) {
                IntercomAPI('trackEvent', 'Added user', {
                    user_email: v?.email,
                    user_role: selectedType?.label
                });
                toastr.success(r.data.message)
            } else {
                toastr.error(r.data.message, "Error")
            }
            updateUsersList()
        }).catch(e => {
            toastr.error("an error occurred", "Error")
        })
        console.log(v);
    }
    return (
        <>
            {<Row className="p-3 justify-content-between align-items-center">
                <CardTitle>Users</CardTitle>
                <div className="text-sm-right">
                    <Button
                        color="success"
                        className="waves-effect waves-light"
                        onClick={() => setModal(true)}
                    ><i style={{"margin-right": "10px"}} className="fas fa-plus"></i>
                        New User
                    </Button>
                </div>
            </Row>}

            <div className="table-responsive">
                <Table className="table table-centered table-nowrap">
                    <thead className="thead-light">
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Team</th>
                        <th>Role</th>
                        <th>Status</th>
                        <th>Archive</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        data.map((myUser, key) =>
                            <tr key={"_order_" + key}>
                                <td>
                                    <Col>
                                        <Row className="align-items-center">
                                            {
                                                !myUser.photo
                                                    ? <div className="avatar-xs">
                                                                                <span
                                                                                    className="avatar-title rounded-circle">
                                                                                    {myUser.first_name.charAt(0)}
                                                                                </span>
                                                    </div>
                                                    : <div>
                                                        <img className="rounded-circle avatar-xs"
                                                             src={`${storage}${myUser.photo}`} alt=""/>
                                                    </div>
                                            }
                                            <div style={{marginLeft: "10px"}}>{myUser.first_name} {myUser.last_name}</div>
                                        </Row>
                                    </Col>
                                </td>
                                <td>
                                    {myUser.email}
                                </td>
                                <td>
                                    <Badge className={"font-size-12 rounded badge-soft-primary"}
                                           pill>{"Default"}</Badge>
                                </td>
                                <td>
                                    {myUser.role}
                                </td>
                                <td>
                                    <Col>
                                        <Row className="justify-content-center">
                                            <div className="custom-control custom-switch mb-2"
                                                 dir="ltr">
                                                <input type="checkbox"
                                                       className="custom-control-input"
                                                       onClick={e => {
                                                           handleStatusChange(myUser?.id, myUser.status == 'active')
                                                       }}
                                                       id={`SwitchStatus${key}`} disabled={myUser.id == user?.id || myUser?.role == "Owner"} defaultChecked={myUser.status == 'active'}/>
                                                <label className="custom-control-label"
                                                       htmlFor={`SwitchStatus${key}`} onClick={(e) => {
                                                }}/>
                                            </div>
                                        </Row>
                                    </Col>
                                </td>
                                <td>
                                    <Col>
                                        <Row className="justify-content-center">
                                            {!(myUser.id == user?.id || myUser?.role == "Owner") && <Link to="#" onClick={() => handleArchive(myUser?.id)}>
                                                <i className="bx bx-trash font-size-18"
                                                   id="deletetooltip"></i>
                                                <UncontrolledTooltip placement="top" target="deletetooltip">
                                                    Archive
                                                </UncontrolledTooltip>
                                            </Link>}

                                        </Row>
                                    </Col>
                                </td>
                            </tr>
                        )
                    }

                    </tbody>
                </Table>
            </div>
            <Modal isOpen={modal} role="dialog" autoFocus={true} centered={true} className="exampleModal" tabindex="-1"
                   toggle={() => {
                       setModal(!modal)
                   }}>
                <div className="modal-content">
                    <ModalHeader toggle={() => {
                        setModal(!modal)
                    }}>
                        New User
                    </ModalHeader>
                    <ModalBody>
                        <AvForm className="form-horizontal" ref={formRef} onValidSubmit={handleSubmitInvite}>
                            <FormGroup className="mb-4" row>
                                <Label htmlFor="first-name" md="3" className="col-form-label">First Name</Label>
                                <Col md="9">
                                    <AvField name="first_name" type="text" className="form-control" id="billing-name"/>
                                </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                                <Label htmlFor="last-name" md="3" className="col-form-label">Last Name</Label>
                                <Col md="9">
                                    <AvField name="last_name" type="text" className="form-control" id="billing-name"/>
                                </Col>
                            </FormGroup>
                            <FormGroup className="mb-4" row>
                                <Label htmlFor="email" md="3" className="col-form-label">Email</Label>
                                <Col md="9">
                                    <AvField name="email" type="email" className="form-control" id="billing-name"/>
                                </Col>
                            </FormGroup>
                            <FormGroup className="select2-container mb-4" row>
                                <Label md="3" className="col-form-label">Role</Label>
                                <Col md="9">
                                    <Select
                                        value={selectedType}
                                        onChange={handleSelectType}
                                        options={staticData?.roles?.map(e => ({value: e.id, label: e.name}))}
                                    />
                                </Col>
                            </FormGroup>

                        </AvForm>
                    </ModalBody>
                    <ModalFooter>
                        <Button type="button" color="secondary" onClick={() => {
                            setModal(!modal)
                        }}>Close</Button>
                        <Button type="button" color="primary" onClick={() => {
                            formRef.current.submit()
                            setModal(!modal)
                        }}>Save changes</Button>
                    </ModalFooter>
                </div>
            </Modal>
            <ConfirmationModal/>
        </>
    );
}

export default Users;

import React, {useState} from 'react';

import {connect, useSelector} from "react-redux";
// Reactstrap
import {Col, Row} from "reactstrap";

import {Link} from "react-router-dom";
import {withRouter} from "react-router";
// Import menuDropdown
import NotificationDropdown from "../../../../components/CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../../../../components/CommonForBoth/TopbarDropdown/ProfileMenu";

import logo from "../../../../assets/images/logo.svg";
import logoLightPng from "../../../../assets/images/logo-light.png";
import logoLightSvg from "../../../../assets/images/logo-light.png";
import logoDark from "../../../../assets/images/logo-dark.png";
//i18n
import {withNamespaces} from 'react-i18next';
// Redux Store
import {changeSidebarType, showRightSidebarAction, toggleLeftmenu} from "../../../../store/actions";

const Header = (props) => {

  const [search, setsearch] = useState(false);
  const [megaMenu, setmegaMenu] = useState(false);
  const [socialDrp, setsocialDrp] = useState(false);
  const display = useSelector(state => state.Account?.user?.type);
  const isMobile =  /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const { match, location, history } = props;

  const getTitle = () => {
    switch (location.pathname) {
      case "/admin/all-payments":
        return 'All Payment';
      case "/admin/transactions":
        return "Transactions";
      case "/admin/pending-transfers":
        return "Pending Transfers"
      case "/admin/confirmed-transfers":
        return "Confirmed Transfers"
      default:
        return "";
    }
  }
  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

function tToggle()
{
       props.toggleLeftmenu(!props.leftMenu);
      if (props.leftSideBarType === "default") {
         props.changeSidebarType("default", isMobile);
    } else if (props.leftSideBarType === "default") {
         props.changeSidebarType("default", isMobile);
    }
}
      return (
       <React.Fragment>
        <header id="page-topbar">
          <div className="navbar-header">
            <div className="d-flex">
              <div className="navbar-brand-box">
                <Link to="/" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={logo} alt="" height="24" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoDark} alt="" height="24" />
                  </span>
                </Link>

                <Link to="/" className="logo logo-light">
                  <span className="logo-sm">
                    <img src={logoLightSvg} alt="" height="24" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoLightPng} alt="" height="24" />
                  </span>
                </Link>
              </div>
              <Row className="align-items-center p-4">
              <Col>
                <h4 className="mb-0 font-size-18">{getTitle()}</h4>
              </Col>
              </Row>

            </div>
            <div className="d-flex">
              {/*<LanguageDropdown />*/}

              <div className="dropdown d-none d-lg-inline-block ml-1">
                <button type="button" onClick={() => { {/*toggleFullscreen();*/} }} className="btn header-item noti-icon waves-effect">
                  <i className="bx bx-support"></i>
                </button>
              </div>

              <NotificationDropdown />
              <ProfileMenu />
            </div>
          </div>
        </header>
      </React.Fragment>
      );
    }
const mapStatetoProps = state => {
  const { layoutType,showRightSidebar,leftMenu,leftSideBarType } = state.Layout;
  return { layoutType,showRightSidebar,leftMenu,leftSideBarType };
};

export default withRouter(connect(mapStatetoProps, { showRightSidebarAction,toggleLeftmenu,changeSidebarType })(withNamespaces()(Header)));

import React, {useEffect, useState} from 'react';
import {Badge, Card, CardBody, Col, Row, Spinner, Table, UncontrolledTooltip} from "reactstrap";
import {withNamespaces} from 'react-i18next';
import {Link} from "react-router-dom";
import {getContractPayment, getTransactionList} from "../../../services/api";
import {useSelector} from "react-redux";
import NoContent from "../../../components/NoContent";
import moment from "moment";

const History = ({contract}) => {
    const [data, setData] = useState([]);
    const user = useSelector(state => state.Account?.user)
    const [loading, setLoading] = useState(true);
    const [expanded, setExpanded] = useState(-1);
    useEffect(() => {
        if (contract?.id) {
            getContractPayment(user?.token, contract.id).then(r => {
                setLoading(false)
                if (r.data.success) {
                    console.log(r.data.data);
                    setData(r.data?.data)
                }
            }).catch(e => {
                setLoading(false)
                console.log(e);
            })
        }

    }, [contract])

    const getStatusColor = (status) => {
        switch (status) {
            case 'Processing':
                return 'warning';
            case 'Paid':
                return 'success';
            case 'Overdue':
                return 'danger';
            case 'Unpaid':
                return 'danger';
            default:
                return 'primary';
        }
    }
    return loading ?
        (<Col style={{minHeight: '30rem'}}>
            <Row style={{minHeight: '30rem'}} className="justify-content-center align-items-center">
                <Spinner type="grow" className="mr-2" color="primary"/>
            </Row>
        </Col>) :
        data?.length == 0 ? <Card>
                <CardBody>
                    <NoContent
                        headline="No payment due"
                        subtitle="Due payments will automatically be shown here 5 days before their due date."
                        image={require('./../../../assets/images/empty_transactions.png')}
                    />
                </CardBody>
            </Card> :
            <Row>
                <Col xs="12">
                    <Card>
                        <CardBody>
                            <div className="table-responsive">
                                <Table className="table table-centered table-nowrap text-muted">
                                    <thead className="thead-light">
                                    <tr>
                                        <th>{""}</th>
                                        <th>Payment ID</th>
                                        <th>Due Date</th>
                                        <th className="text-right">Amount</th>
                                        <th>{user?.type != 'client' ? "Status" : ""}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        data?.map((order, key) => {
                                            const formatter = new Intl.NumberFormat('en-US', {
                                                style: 'currency',
                                                currency: order?.currency?.code || "USD",
                                            });
                                            return <>
                                                    <tr key={"_order_" + key}>
                                                        <td>
                                                            {order?.works?.length != 0 && order.status != "Paid (off-cycle)" && <a onClick={() => {
                                                                if (expanded == key) {
                                                                    setExpanded(-1)
                                                                } else {
                                                                    setExpanded(key)
                                                                }
                                                            }}>
                                                                <i className="bx bx-plus"/>
                                                            </a>}
                                                        </td>
                                                        <th>
                                                            #{order.payment_ref}
                                                        </th>
                                                        <td>
                                                            {order.due_date}
                                                        </td>
                                                        <td className="text-right">
                                                            {formatter.format(order.amount)}
                                                        </td>
                                                        <td>
                                                            <Col>
                                                                <Row className="font-weight-bold">
                                                                    {order.status == "Paid" || user?.type != 'client' ?
                                                                        <Badge
                                                                            className={`font-size-12 rounded badge-${getStatusColor(order.status)}`}
                                                                            color="white"
                                                                            pill>{order.status}</Badge> :
                                                                        <Link to={`/payment/${order?.id}`}>
                                                                            <span id="paytooltip">Pay</span>
                                                                            <UncontrolledTooltip placement="top"
                                                                                                 target="paytooltip">
                                                                                Click to pay
                                                                            </UncontrolledTooltip>
                                                                        </Link>
                                                                    }
                                                                </Row>
                                                            </Col>
                                                        </td>
                                                    </tr>
                                                    {expanded == key &&
                                                    <>
                                                        {order?.works?.map(t => <tr className="font-weight-light font-size-12 bg-soft-primary" key={"_trans" + t?.id}>
                                                            <th>{""}</th>
                                                            <th className="font-weight-normal font-size-12">{t?.details}</th>
                                                            <th className="font-weight-normal font-size-12">{t?.name}</th>
                                                            <th className="font-weight-normal font-size-12 text-right">{formatter.format(t?.amount)}</th>
                                                            <th>{""}</th>
                                                            <th>{""}</th>
                                                        </tr>)}
                                                    </>
                                                    }
                                                </>
                                            }
                                        )
                                    }

                                    </tbody>
                                </Table>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
}

export default withNamespaces()(History);

import React, {useEffect} from 'react';
import * as Defaults from './../../config/defaults';
import {useSelector} from "react-redux";

const Wootric = () => {
    const user = useSelector(state => state.Account?.user)
    useEffect(() => {
        console.log(~~(Date.now() / 1000));
        let setupScript = document.createElement('script');
        setupScript.type = 'text/javascript';
        setupScript.id = 'wootric-settings';
        setupScript.async = true;
        setupScript.innerHTML = `
        wootric_no_surveyed_cookie = ${Defaults.WOOTRIC_NO_SURVEYED_COOKIE};
        wootric_survey_immediately = ${Defaults.WOOTRIC_SURVEY_IMMEDIATELY};
        window.wootricSettings = {
        email: '${user?.email}',
        created_at: ${~~(Date.now() / 1000)},
        account_token: "${Defaults.WOOTRIC_ACCOUNT_TOKEN}",
        survey_immediately: false
      };
    `;
        if (document.getElementById('wootric-settings') == null) {
            document.body.appendChild(setupScript);
        }

        // Beacon
        let beacon = document.createElement('script');
        beacon.type = 'text/javascript';
        beacon.id = 'wootric-beacon';
        beacon.async = true;

        beacon.src = 'https://cdn.wootric.com/wootric-sdk.js';
        beacon.onload = function () {
            window.wootric('run');
        };
        if (document.getElementById('wootric-beacon') == null) {
            document.body.appendChild(beacon);
        }
    }, []);

    return (
        <div/>
    );
};

export default Wootric;

import React, {useEffect} from 'react';
// MetisMenu
import MetisMenu from "metismenujs";
import {Link, withRouter} from "react-router-dom";
//i18n
import {withNamespaces} from 'react-i18next';
import {useSelector} from 'react-redux';

const SidebarContent = (props) => {

    const display = useSelector(state => {
        console.log(state);
        return state.Account?.user?.type
    })
    console.log(display);
    // Use ComponentDidMount and ComponentDidUpdate method symultaniously
    useEffect(() => {

        var pathName = props.location.pathname;

        const initMenu = () => {
            new MetisMenu("#side-menu");
            var matchingMenuItem = null;
            var ul = document.getElementById("side-menu");
            var items = ul.getElementsByTagName("a");
            for (var i = 0; i < items.length; ++i) {
                if (pathName === items[i].pathname) {
                    matchingMenuItem = items[i];
                    break;
                }
            }
            if (matchingMenuItem) {
                activateParentDropdown(matchingMenuItem);
            }
        }
        initMenu();
    }, [props.location.pathname]);


    function activateParentDropdown(item) {
        item.classList.add("active");
        const parent = item.parentElement;

        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show");

                const parent3 = parent2.parentElement;

                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); //a
                    const parent4 = parent3.parentElement;
                    if (parent4) {
                        parent4.classList.add("mm-active");
                    }
                }
            }
            return false;
        }
        return false;
    };

    return (

        <React.Fragment>
            <div id="sidebar-menu">
                <ul className="metismenu list-unstyled" id="side-menu">
                    <li>
                        <Link to="/admin/all-payments" className="waves-effect">
                            <i className="fas fa-clipboard-list"></i>
                            <span>{props.t('All Payment')}</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/admin/transactions" className="waves-effect">
                            <i className="fas fa-file-contract"></i>
                            <span>{props.t('Transactions')}</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/#" className="has-arrow waves-effect">
                            <i className="bx bx-briefcase-alt-2"></i>
                            <span>{props.t('Transfers')}</span>
                        </Link>
                        <ul className="sub-menu" aria-expanded="false">
                            <li>
                                <Link to="/admin/pending-transfers">{props.t('Pending Transfers')}</Link>
                            </li>
                            <li>
                                <Link to="/admin/confirmed-transfers">{props.t('Confirmed Transfers')}</Link>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </React.Fragment>
    );
}

export default withRouter(withNamespaces()(SidebarContent));



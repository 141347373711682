import React, {useState} from 'react';
import {Col, Row} from "reactstrap";

const Steps = ({data, activeTab}) => {
    return (
        <Row className="justify-content-between align-items-start flex-nowrap py-3 font-size-11 p-0 m-0">
            {data?.map((e, key) => <Col className="p-0 m-0">
                <Row className="h-25 align-items-center mb-3">
                    <Col
                        className={activeTab >= key ? "bg-primary p-0 m-0" : "bg-light p-0 m-0"}
                        style={{height: key != 0 ? 2 : 0}}/>
                    <span className={activeTab == key ? "bg-primary" : ""}
                          style={{
                              width: activeTab == key ? 14 : 0,
                              height: 14,
                              borderRadius: 7,
                          }}/>
                     <Col
                        className={activeTab >= key + 1 ? "bg-primary p-0 m-0" : "bg-light p-0 m-0"}
                        style={{height: key != data?.length -1 ? 2 : 0}}/>
                </Row>
                <Row
                    className={`${activeTab >= key ? "text-primary" : "text-muted"} font-weight-semibold text-center justify-content-center h-75 align-items-center p-0 m-0`}
                    style={{textAlign: 'center'}}>{e}
                </Row>
            </Col>)}
        </Row>
    );
};

export default Steps;

import React from 'react';
import {Badge, Col, Row} from "reactstrap";
import {storage} from "../../../helpers/config";

const CardContact = (props) => {

    const user = props.user;
    const {label, isKyc, isContarctor} = props

    return (
        <React.Fragment>
            <Row>
                {
                    !user?.photo ?
                        <div className="avatar-sm mx-auto">
                            <span
                                className={"avatar-title rounded-circle bg-primary text-white font-size-16"}>
                                {user?.first_name ? user?.first_name?.charAt(0) : user?.email?.charAt(0)}
                            </span>
                        </div>
                        :
                        <div>
                            <img className="rounded-circle avatar-sm" src={`${storage}${user?.photo}`}
                                 alt="user-avatar"/>
                        </div>
                }
                <Col>
                    <h5 className="font-size-15 text-dark">
                        {user?.first_name && user?.last_name ? `${user?.first_name} ${user?.last_name}` : 'Invitation pending'}
                        {!!isContarctor && <span
                            className="ml-2 text-muted font-size-10">{isKyc ? "KYC Required" : "KYC not required"}</span>}
                    </h5>
                    <Row className="ml-0 flex-nowrap">
                        <p className="text-muted">{user?.email}</p>
                        <span className="ml-2">
                            <Badge className={"font-size-12 rounded badge-soft-primary"}
                                   color="primary"
                                   pill>
                                {label}
                            </Badge>
                        </span>
                    </Row>
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default CardContact;

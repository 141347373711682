import React, {useEffect, useState} from 'react';
import {
    Badge,
    Card,
    CardBody,
    Col,
    Container,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    Table,
    UncontrolledTooltip
} from "reactstrap";
import {withNamespaces} from 'react-i18next';
import avatar2 from "../../../assets/images/users/avatar-2.jpg";
import avatar3 from "../../../assets/images/users/avatar-3.jpg";
import {getDocs} from "../../../services/api";
import {useSelector} from "react-redux";

const Documents = (props) => {
    const Orders = [
        {
            id: "customCheck2",
            img: "Null",
            orderId: "#SK2540",
            docId: "94023jj",
            billingName: "Neal Matthews",
            activeNumber: 4,
            Date: "07 Oct, 2019",
            total: "$400",
            badgeclass2: "danger",
            paymentStatus2: "Unverified",
            badgeclass: "success",
            paymentStatus: "Verified",
            methodIcon: "fa-cc-mastercard",
            paymentMethod: "Mastercard"
        },
        {
            id: "customCheck3",
            img: avatar2,
            orderId: "#SK2541",
            docId: "94023jj",
            activeNumber: 3,
            billingName: "Jamal Burnett",
            Date: "07 Oct, 2019",
            total: "$380",
            badgeclass2: "danger",
            paymentStatus2: "Unverified",
            badgeclass: "success",
            paymentStatus: "Verified",
            methodIcon: "fa-cc-visa",
            paymentMethod: "Visa"
        },
        {
            id: "customCheck4",
            img: avatar3,
            orderId: "#SK2542",
            docId: "94023jj",
            activeNumber: 1,
            billingName: "Juan Mitchell",
            Date: "06 Oct, 2019",
            total: "$384",
            badgeclass2: "danger",
            paymentStatus2: "Unverified",
            badgeclass: "success",
            paymentStatus: "Verified",
            methodIcon: "fa-cc-paypal",
            paymentMethod: "Paypal"
        },

    ];
    const user = useSelector(state => state.Account?.user)
    useEffect(() => {
        getDocs(user?.token).then(r => {
            console.log(r);
        })
    }, [])
    return (

        <Row>
            <Col xs="12">
                <Card>
                    <CardBody>
                        <div className="table-responsive">
                            <Table className="table table-centered table-nowrap text-muted">
                                <thead className="thead-light">
                                <tr>
                                    <th>Contractor</th>
                                    <th>ID Number</th>
                                    <th>Active Contract</th>
                                    <th>KYC Level1</th>
                                    <th>KYC Level2</th>
                                    <th>Download</th>
                                    <th><span></span></th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    Orders.map((order, key) =>
                                        <tr key={"_order_" + key}>
                                            <td><Col>
                                                <Row className="align-items-center">
                                                    {
                                                        order.img === "Null"
                                                            ? <div className="avatar-xs">
                                                                                <span
                                                                                    className="avatar-title rounded-circle">
                                                                                    {order.billingName.charAt(0)}
                                                                                </span>
                                                            </div>
                                                            : <div>
                                                                <img className="rounded-circle avatar-xs"
                                                                     src={order.img} alt=""/>
                                                            </div>
                                                    }
                                                    <div style={{marginLeft: "10px"}}>{order.billingName}</div>
                                                </Row>
                                            </Col></td>
                                            <td>
                                                {order.docId}
                                            </td>
                                            <td>
                                                {order.activeNumber}
                                            </td>
                                            <td>
                                                <Badge className={"font-size-12 rounded badge-" + order.badgeclass}
                                                       color={"white"} pill>{order.paymentStatus}</Badge>
                                            </td>
                                            <td>
                                                <Badge className={"font-size-12 rounded badge-" + order.badgeclass2}
                                                       color={"white"} pill>{order.paymentStatus2}</Badge>
                                            </td>
                                            <td>
                                                <Col>
                                                    <Row className="justify-content-center">
                                                        <DownloadDropDown/>
                                                    </Row>
                                                </Col>
                                            </td>
                                        </tr>
                                    )
                                }

                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
}

const DownloadDropDown = () => {
    const [isOpen, setIsOpen] = useState(false);
    return <Dropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} className="float-right ml-2">
        <DropdownToggle tag="a" className="text-muted">
            <div className="text-primary">
                <i className="fas fa-download mr-1"
                   id="deletetooltip"></i><span>Documents</span><i className="fas fa-angle-down ml-1"
                                                                   id="deletetooltip"></i>
                <UncontrolledTooltip placement="top" target="deletetooltip">
                    Download
                </UncontrolledTooltip>
            </div>
        </DropdownToggle>
        <DropdownMenu right>
            <DropdownItem href="#">Passport or ID</DropdownItem>
            <DropdownItem href="#">Document1- download</DropdownItem>
            <DropdownItem href="#">Document2- download</DropdownItem>
        </DropdownMenu>
    </Dropdown>
}

export default withNamespaces()(Documents);

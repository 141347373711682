import React, {useMemo, useState} from "react";
import {
    useStripe,
    useElements,
    CardNumberElement,
    CardCvcElement,
    CardExpiryElement, Elements
} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";

import useResponsiveFontSize from "./useResponsiveFontSize";
import {Row, Col, Input} from "reactstrap";


const useOptions = () => {
    const fontSize = useResponsiveFontSize();
    const options = useMemo(
        () => ({
            style: {
                base: {
                    // fontSize,
                    // color: "#424770",
                    // letterSpacing: "0.025em",
                    // // fontFamily: "Source Code Pro, monospace",
                    // "::placeholder": {
                    //     color: "#aab7c4"
                    // }
                },
                invalid: {
                    color: "#9e2146"
                }
            }
        }),
        [fontSize]
    );

    return options;
};


const StripeForm = ({onSubmitted, loading, onFocus, onPay, isNewCard}) => {
    const SplitForm = () => {
        const stripe = useStripe();
        const elements = useElements();
        const options = useOptions();
        const [name, setName] = useState(null);

        const handleSubmit = async event => {
            event.preventDefault();

            if (!stripe || !elements) {
                // Stripe.js has not loaded yet. Make sure to disable
                // form submission until Stripe.js has loaded.
                return;
            }

            const payload = await stripe.createPaymentMethod({
                type: "card",
                card: elements.getElement(CardNumberElement),
                billing_details: {
                    name,
                }
            });
            onSubmitted(payload)
            console.log("[PaymentMethod]", payload);
        };

        return (
            <form onSubmit={handleSubmit}>
                <Col>

                    <Row className="flex-nowrap">

                        <Col>
                            <Input
                                onChange={e => {
                                    setName(e.target.value)
                                }}
                                className="form-control"
                                placeholder="Card holder"
                            />
                        </Col>
                    </Row>
                    <Row className="flex-nowrap mt-3">

                        <Col>
                            <CardNumberElement
                                className="form-control"
                                options={options}
                                onFocus={onFocus}
                            />
                        </Col>
                    </Row>
                    <Row className="flex-nowrap my-3">
                        <Col>

                            <CardExpiryElement
                                className="form-control"
                                options={options}
                                onFocus={onFocus}
                            />

                        </Col>
                        <Col>

                            <CardCvcElement
                                className="form-control"
                                options={options}
                                onFocus={onFocus}
                            />

                        </Col>

                    </Row>


                    <Col>
                        <Row className="justify-content-end">
                            <button
                                onClick={!isNewCard ? onPay : null}
                                data-secret="sk_test_51H4P5VIJ4lhRT09aTNOzaSMwSizdGSzMdrWuQje5Otm7QZchEXuX4ajPVzQ27nXrbUY7BMGFMqVv451XkxrJz9yW00yOiO0klu"
                                className={`btn btn-${stripe ? 'primary' : 'secondary'} pl-3 pr-3 btn-block font-weight-bold`}
                                type="submit"
                                disabled={!stripe}>
                                {loading &&
                                <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>} Add Card
                            </button>
                        </Row>
                    </Col>
                </Col>
            </form>
        );
    };
    const stripePromise = loadStripe("pk_test_51H4P5VIJ4lhRT09a1lUxc5NTD2RRW4KZoiHHyHFTll6UhT61uZwPq6pULdajzFmUsa6OGIv1m5wBleTc5cWrd0eB00wwZUCkqs");

    return (
        <Elements stripe={stripePromise}>
            <SplitForm/>
        </Elements>
    );
};

export default StripeForm;

import React, {useEffect, useState} from 'react';
import {Card, CardBody, Col, Container, Row, Spinner} from "reactstrap";
import MiniWidget from "./mini-widget";
import Earning from "./earning";
import ContractList from "../Contract/ContractList/ContractList";
import {getOverview} from "../../services/api";
import {useSelector} from "react-redux";

const ContractorOverview = () => {

    const [reports, setReports] = useState([]);
    const [loading, setLoading] = useState(true);
    const [contracts, setContracts] = useState([]);

    const user = useSelector(state => state.Account?.user)
    useEffect(() => {
        getOverview(user?.token).then(r => {
            console.log(r);
            setLoading(false)
            if (r.data.success){
                setReports([
                    {
                        icon: "bx bx-copy-alt",
                        link: '/history',
                        title: "Balance",
                        value: r.data.data.balance,
                        badgeValue: "6",
                        color: "success",
                        desc: "Contracts"
                    },
                    {
                        icon: "bx bx-purchase-tag-alt",
                        link: '/contracts',
                        title: "Active contracts",
                        value: r.data.data.active_contracts,
                        badgeValue: "6",
                        color: "success",
                        desc: "Contracts"
                    },
                    {
                        icon: "bx bx-archive-in",
                        link: '/payment',
                        title: "Upcoming Payment",
                        value: r.data.data.upcoming_payment,
                        badgeValue: "--",
                        color: "success",
                        desc: "Contracts"
                    },
                ])
                setContracts(r.data.data.contracts)
            }
        }).catch(e => {
            console.log(e);
            setLoading(false)
        })
    }, [])
    return (
        <>
            <div className="page-content">
                {loading ? <Container style={{minHeight: '30rem'}}>
                    <Col style={{minHeight: '30rem'}}>
                        <Row style={{minHeight: '30rem'}} className="justify-content-center align-items-center">
                            <Spinner type="grow" className="mr-2" color="primary"/>
                        </Row>
                    </Col>
                </Container> : <Container fluid>
                    <Col>
                        <Row>
                            {/*mimi widgets */}
                            <MiniWidget reports={reports} simple withdraw/>
                        </Row>
                    </Col>
                    {/*<Row>*/}
                    {/*    <Col>*/}
                    {/*        <Earning/>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                    <Col>
                        <Card>
                            <CardBody>
                                <h4 className="mb-3 font-size-18">Recent Contracts</h4>
                                <ContractList Orders={contracts}/>
                            </CardBody>
                        </Card>
                    </Col>
                </Container>}
            </div>
        </>
    );
}

export default ContractorOverview;

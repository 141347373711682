import { UPDATE_LIST_TO_PAY } from './actionTypes';

const initialState = {
    toPay: null
}

const payment = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_LIST_TO_PAY:
            state = {
                ...state,
                toPay: action.payload
            }
            break;
    }
    return state;
}

export default payment;

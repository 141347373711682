import React, {useEffect, useState} from 'react';
import {Button, Card, CardBody, Col, Container, Row, Spinner} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {getUnpaidList, getUpcomingPaymentList} from "../../services/api";
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import PaymentList from "./paymentList";
import {updateToPayList} from "../../store/payment/actions";
import NoContent from "../../components/NoContent";
import {IntercomAPI} from "react-intercom";

const Payment = ({history, match}) => {
    const user = useSelector(state => state.Account?.user)
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currency, setCurrency] = useState(null);
    const [totalToPay, setTotalToPay] = useState(0);
    const updateInvoiceList = () => {
        if (match.params?.id) {
            getUpcomingPaymentList(user?.token).then(r => {
                if (r.data.success) {
                    setData(r.data.data.map(e => ({...e, selected: e.id == match.params?.id})))
                } else {
                    toastr.error(r.data.message)
                }
                setLoading(false)
                console.log(r);
            }).catch(e => {
                setLoading(false)
            })
        } else {
            getUnpaidList(user?.token).then(r => {
                if (r.data.success) {
                    setData(r.data.data.map(e => ({...e, selected: true})))
                } else {
                    toastr.error(r.data.message)
                }
                setLoading(false)
                console.log(r);
            }).catch(e => {
                setLoading(false)
            })
        }
    }

    const dispatch = useDispatch();

    useEffect(() => {
        const selectedInvoice = data.find(e => e.selected)
        setCurrency(selectedInvoice?.currency)
        const selectedElements = data.filter(e => e.selected)
        if (selectedElements?.length > 1) {
            setTotalToPay(selectedElements?.reduce((prev, current) => prev?.amount ? prev?.amount + current?.amount : prev + current?.amount))
        } else if (selectedElements?.length == 1) {
            setTotalToPay(selectedElements[0]?.amount)
        } else {
            setTotalToPay(0)
        }
        dispatch(updateToPayList(selectedElements))
    }, [data])

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency?.code || 'USD',
    });

    useEffect(() => {
        IntercomAPI('trackEvent', 'Clicked Pay - homepage')
        updateInvoiceList();
    }, [])

    return (
        <div className="page-content">
            {loading ? <Container style={{minHeight: '30rem'}}>
                <Col style={{minHeight: '30rem'}}>
                    <Row style={{minHeight: '30rem'}} className="justify-content-center align-items-center">
                        <Spinner type="grow" className="mr-2" color="primary"/>
                    </Row>
                </Col>
            </Container> : <Container fluid>
                {data?.length == 0 ? <Card>
                    <CardBody><NoContent
                        image={require('./../../assets/images/empty_transactions.png')}
                    /></CardBody></Card> : <Row>
                    <Col>
                        <div id="accordion">
                            <Card className="mb-1">
                                <CardBody>
                                    <Col>
                                        <Row className="justify-content-end">
                                            <Button
                                                onClick={() => {
                                                    history.push('/pay-invoices')
                                                }}
                                                disabled={totalToPay == 0}
                                                color={totalToPay == 0 ? "secondary" : "primary"}
                                                className="font-weight-bold px-5">Pay {totalToPay == 0 ? "" : formatter.format(totalToPay)}</Button>
                                        </Row>
                                    </Col>
                                </CardBody>
                                <PaymentList
                                    data={data} currency={currency} onInvoiceSelected={i => {
                                    console.log('checkDebug', i, currency, data[i]);
                                    setData(data.map((inv, idx) => ({
                                        ...inv,
                                        selected: i == idx ? !inv.selected : inv.selected
                                    })))
                                }}
                                />
                            </Card>
                        </div>
                    </Col>
                </Row>}
            </Container>}
        </div>
    );
}

export default Payment;

import React, {useEffect, useState} from 'react';
import {Card, CardBody, Col, Container, Row, Spinner} from "reactstrap";
import {withNamespaces} from 'react-i18next';
import MiniWidget from "./mini-widget";
import {useSelector} from 'react-redux';
import ContractorOverview from "./ContractorOverview";
import {userTypes} from "../../helpers/enum";
import {getActivity} from "../../services/api";
import NoContent from "../../components/NoContent";
import ContractList from "../Contract/ContractList/ContractList";
import {IntercomAPI} from "react-intercom";

const Activity = (props) => {
    const [reports, setReports] = useState([]);
    const [contracts, setContracts] = useState([]);

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const user = useSelector(state => state.Account?.user)
    useEffect(() => {
        getActivity(user.token).then(r => {
            console.log(r);
            setLoading(false);
            if (r.data.success){
                IntercomAPI('trackEvent', 'Viewed Activity');
                setData(r.data.data.contracts)
                setReports([

                    {
                        icon: "bx bx-archive-in",
                        link: '/upcoming-payment',
                        title: "Upcoming Payment",
                        value: r.data.data.upcoming_payment,
                        badgeValue: r.data.data.upcoming_payment.contracts,
                        color: "success",
                        desc: "Contracts"
                    },
                    {
                        icon: "bx bx-purchase-tag-alt",
                        link: '/payment',
                        title: "Payment due",
                        value: r.data.data.payment_due,
                        badgeValue: r.data.data.payment_due.contracts,
                        color: "success",
                        desc: "Contracts"
                    },
                    {
                        icon: "bx bx-copy-alt",
                        link: '/history',
                        title: "Paid this month",
                        value: r.data.data.paid_this_month,
                        badgeValue: r.data.data.paid_this_month.contracts,
                        color: "success",
                        desc: "Contracts"
                    },
                ])
                setContracts(r.data.data.contracts)
            }
        }).catch(e => {
            console.log(e);
            setLoading(false)
        })
    }, [])
    const display = useSelector(state => state.Account?.user?.type)
    return display == userTypes.COMPANY ? (
        <>
            <div className="page-content">
                {loading ? <Container style={{minHeight: '30rem'}}>
                    <Col style={{minHeight: '30rem'}}>
                        <Row style={{minHeight: '30rem'}} className="justify-content-center align-items-center">
                            <Spinner type="grow" className="mr-2" color="primary"/>
                        </Row>
                    </Col>
                </Container> : <Container fluid>
                    <Row>
                        <Col>
                            <Row>
                                <MiniWidget reports={reports} simple pay/>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                        <h4 className="mb-3 font-size-18">Recent Contracts</h4>
                                        <ContractList Orders={contracts}/>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>}
            </div>
        </>
    ) : <ContractorOverview/>;
}

export default withNamespaces()(Activity);

import React, {useState} from 'react';
import {Col, Input, Row, Table, UncontrolledTooltip} from "reactstrap";
import {Link} from "react-router-dom";
import {storage} from "../../helpers/config";
import {downloadInvoice} from "../../services/api";
import {useSelector} from "react-redux";
import NoContent from "../../components/NoContent";
import {IntercomAPI} from "react-intercom";
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

const InvoicesList = ({Orders, selectable, onInvoiceSelected, currency, recent}) => {
    const user = useSelector(state => state?.Account?.user)
    const [expanded, setExpanded] = useState(-1);

    const handleInvoiceChecked = (i) => {
        onInvoiceSelected(i)
    }
    const handleDownloadInvoice = (invoice) => {
        console.log(invoice);
        downloadInvoice(invoice.invoice_ref, user?.token, invoice.token).then(r => {
            if (r.data?.data?.error || r.data?.message) {
                toastr.error(r.data?.data?.error || r.data?.message || "An error occurred")
                return
            }
            IntercomAPI('trackEvent', 'Download invoice', {
                invoice_id: invoice.invoice_ref,
            })
            console.log(r.data);
            const url = window.URL.createObjectURL(new Blob([r.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${invoice?.invoice_ref}.pdf`); //or any other extension
            document.body.appendChild(link);
            link.click();
        })
    }
    const getStatusColor = (status) => {
        switch (status) {
            case 'Pending':
                return 'warning';
            case 'Paid':
                return 'success';
            case 'Overdue':
                return 'danger';
            default:
                return 'primary';
        }
    }
    return Orders?.length == 0 ? <NoContent
        subtitle="All your invoices will be shown here after each payment"
        image={require('./../../assets/images/empty_invoices.png')}
    /> : (
        <Row>
            <Col xs="12">
                {!selectable && !recent && <Row className="mb-2">
                    <Col sm="4">
                        {/*<div className="search-box mr-2 mb-2">*/}
                        {/*    <div className="position-relative">*/}
                        {/*        <Input type="text" className="form-control" placeholder="Search..."/>*/}
                        {/*        <i className="bx bx-search-alt search-icon"></i>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </Col>
                    <Col sm="8">
                    </Col>
                </Row>}

                {recent && <h4 className="mb-3 font-size-18">Recent invoices</h4>}
                <div className="table-responsive">
                    <Table className="table table-centered table-nowrap text-muted">
                        <thead className="thead-light">
                        <tr>
                            <th className="border-top-0 text-muted">{""}</th>
                            <th className="border-top-0 text-muted">Invoice</th>
                            <th className="border-top-0 text-muted">Contract Ref</th>
                            <th className="border-top-0 text-muted">{user.type == "client" ? "Contractor" : "Client"}</th>
                            <th className="text-right border-top-0 text-muted">Total</th>
                            <th className="border-top-0 text-muted">Invoice Date</th>
                            <th className="text-center border-top-0 text-muted">Download</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            Orders.map((order, key) => {
                                    const formatter = new Intl.NumberFormat('en-US', {
                                        style: 'currency',
                                        currency: order?.currency?.code || "USD",
                                    });
                                    const partner = user?.type == 'client' ? order.contractor : order.client
                                    return <>
                                        <tr key={"_order_" + key}>
                                            <th>
                                                {order?.works?.length != 0 && <a onClick={() => {
                                                    if (expanded == key) {
                                                        setExpanded(-1)
                                                    } else {
                                                        setExpanded(key)
                                                    }
                                                }}>
                                                    <i className="bx bx-plus"/>
                                                </a>}
                                            </th>
                                            <td>
                                                {order.invoice_ref}
                                            </td>
                                            <td>
                                                {order.contract_ref}
                                            </td>
                                            <td>
                                                <Col>
                                                    <Row className="align-items-center flex-nowrap">
                                                        {
                                                            !partner?.photo
                                                                ? <div className="avatar-xs">
                                                                                <span
                                                                                    className="avatar-title rounded-circle">
                                                                                    {partner?.first_name?.charAt(0)}
                                                                                </span>
                                                                </div>
                                                                : <div>
                                                                    <img className="rounded-circle avatar-xs"
                                                                         src={`${storage}${partner?.photo}`} alt=""/>
                                                                </div>
                                                        }
                                                        <div
                                                            style={{marginLeft: "10px"}}>{partner?.first_name}</div>
                                                    </Row>
                                                </Col>
                                            </td>
                                            <td className="text-right">
                                                {formatter.format(order?.total)}
                                            </td>
                                            <td>
                                                {order.invoice_date}
                                            </td>
                                            {selectable ? <td>
                                                <Col>
                                                    <Row className="justify-content-center">
                                                        <div className="custom-control custom-switch mb-2"
                                                             dir="ltr">
                                                            <input type="checkbox"
                                                                   className="custom-control-input"
                                                                   disabled={currency && currency != order.currency}
                                                                   id={`invoiceToPay${key}`} checked={order.selected}
                                                                   onClick={(e) => {
                                                                       handleInvoiceChecked(key)
                                                                   }}/>
                                                            <label className="custom-control-label"
                                                                   htmlFor={`invoiceToPay${key}`} onClick={(e) => {
                                                            }}></label>
                                                        </div>
                                                    </Row>
                                                </Col>
                                            </td> : <td>
                                                <Col>
                                                    <Row className="justify-content-center">
                                                        <Link to="#" onClick={() => handleDownloadInvoice(order)}>
                                                            <i className="fas fa-download mr-1"
                                                               id="deletetooltip"></i> Invoice
                                                            <UncontrolledTooltip placement="top" target="deletetooltip">
                                                                Download
                                                            </UncontrolledTooltip>
                                                        </Link>

                                                    </Row>
                                                </Col>
                                            </td>}
                                        </tr>
                                        {expanded == key &&
                                        <>
                                            {order?.works?.map(t => {
                                                const formatter = new Intl.NumberFormat('en-US', {
                                                    style: 'currency',
                                                    currency: t?.currency || "USD",
                                                });
                                                return <tr
                                                    className="font-weight-light font-size-12 bg-soft-primary"
                                                    key={"_trans" + t?.id}>
                                                    <th>{""}</th>
                                                    <th>{""}</th>
                                                    <th className="font-weight-normal font-size-12">{t?.details}</th>
                                                    <th className="font-weight-normal font-size-12">{t?.name}</th>
                                                    <th className="font-weight-normal font-size-12 text-right">{formatter.format(t?.amount)}</th>
                                                    <th>{""}</th>
                                                    <th>{""}</th>
                                                </tr>
                                            })}
                                        </>
                                        }
                                    </>
                                }
                            )
                        }
                        </tbody>
                    </Table>
                </div>
            </Col>
        </Row>
    );
}

export default InvoicesList;

import React, {useEffect, useState} from 'react';
import {Col, Form, FormGroup, Input, Label} from "reactstrap";
import {useSelector} from 'react-redux';
import Select from "react-select";
import {getCities, getStates} from "../../services/api";

const AddressPicker = ({editable = true, data, onChange}) => {

    const countries = useSelector(state => state.Layout?.staticData?.countries)

    const [country, setCountry] = useState(data?.country);
    const [state, setState] = useState(null);
    const [states, setStates] = useState([]);
    const [city, setCity] = useState(null);
    const [cities, setCities] = useState([]);

    useEffect(() => {
        handleCountrySelection(country)
    }, []);
    useEffect(() => {
        onChange({country, city, state})
    }, [country, state, city])

    useEffect(() => {
        setCountry({label: data?.country?.name, value: data?.country?.id})
        setCity({label: data?.city?.name, value: data?.city?.id})
        setState({label: data?.state?.name, value: data?.state?.id})
    }, [data])
    const handleCountrySelection = (s) => {
        getStates({
            country_id: s?.value
        }).then(r => {
            if (r.data?.success) {
                setStates(r.data.data)
            }
        })
        setCountry(s);
    }
    const handleStateSelection = (s) => {
        getCities({
            state_id: s?.value
        }).then(r => {
            if (r.data?.success) {
                setCities(r.data.data)
            }
        })
        setState(s);
    }

    const handleCitySelection = (s) => {
        setCity(s);
    }
    return (
        <>
            <FormGroup className="mb-4" row>
                <Label htmlFor="billing-address" md="3" className="col-form-label">Country {editable && <span
                    className="text-danger">*</span>}</Label>
                <Col md="9" className="p-0">
                    {editable ? <Select
                        className="mb-3"
                        name="country"
                        value={country}
                        onChange={handleCountrySelection}
                        options={countries ? countries.map(c => ({
                            label: c.name,
                            value: c.id,
                        })) : []}
                    /> : <p className="mt-2">
                        {data?.country?.name}
                    </p>}
                </Col>
            </FormGroup>
            <FormGroup className="mb-4" row>
                <Label htmlFor="billing-address" md="3" className="col-form-label">State {editable && <span
                    className="text-danger">*</span>}</Label>
                <Col md="9" className="p-0">
                    {editable ? <Select
                        className="mb-3"
                        name="state"
                        value={state}
                        onChange={handleStateSelection}
                        options={states ? states.map(s => ({
                            label: s.name,
                            value: s.id,
                        })) : []}
                    /> : <p className="mt-2">
                        {data?.state?.name}
                    </p>}
                </Col>
            </FormGroup>
            <FormGroup  row>
                <Label htmlFor="billing-address" md="3" className="col-form-label">City {editable && <span
                    className="text-danger">*</span>}</Label>
                <Col md="9" className="p-0">
                    {editable ? <Select
                        className="mb-3"
                        name="city"
                        value={city}
                        onChange={handleCitySelection}
                        options={cities ? cities.map(c => ({
                            label: c.name,
                            value: c.id,
                        })) : []}
                    /> : <p className="mt-2">
                        {data?.city?.name}
                    </p>}
                </Col>
            </FormGroup>
        </>
    );
}

export default AddressPicker;

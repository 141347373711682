import React, {useState} from 'react';
import {
    Button, Card, CardBody, CardText,
    Col,
    Container,
    FormGroup, Input,
    InputGroup,
    InputGroupAddon,
    Label, Media, Nav, NavItem, NavLink,
    Row,
    TabContent,
    TabPane
} from "reactstrap";
import ContractTypeSelect from "../Contract/CreateContract/ContractTypeSelect";
import {AvField, AvForm, AvInput} from "availity-reactstrap-validation";
import toastr from "toastr";
import {Link} from "react-router-dom";
import classnames from "classnames";
import {useSelector} from "react-redux";

const TestPaymentCycle = () => {
    const [contractType, setContractType] = useState(0);
    const staticData = useSelector(state => state.Layout.staticData)
    const user = useSelector(state => state.Account.user)
    const [data, setData] = useState({});
    const [jobs, setJobs] = useState([{}, {}, {}, {}, {}, {}, {}, {}, {},]);
    const [frequencyId, setFrequencyId] = useState(1);
    const [activeTabJustify, setactiveTabJustify] = useState("5");

    function toggleCustomJustified(tab) {
        if (activeTabJustify !== tab) {
            setactiveTabJustify(tab);
        }
    }

    return (
        <div>
            <div className="page-content">
                <Container fluid>
                    <Row className="justify-content-center">
                        <Col xl="8">
                            <Nav tabs className="nav-tabs-custom nav-justified">
                                <NavItem>
                                    <NavLink
                                        style={{cursor: "pointer"}}
                                        className={classnames({
                                            active: activeTabJustify === "5"
                                        })}
                                        onClick={() => {
                                            toggleCustomJustified("5");
                                        }}
                                    >
                                        <span className="d-none d-sm-block">Simulate</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{cursor: "pointer"}}
                                        className={classnames({
                                            active: activeTabJustify === "6"
                                        })}
                                        onClick={() => {
                                            toggleCustomJustified("6");
                                        }}
                                    >
                                        <span className="d-none d-sm-block">Contract</span>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={activeTabJustify}>
                                <TabPane tabId="5" className="p-3">

                                    <Row className="justify-content-center">
                                        <Col xl={12}>
                                            <Row>
                                                <Col>
                                                    <ContractTypeSelect
                                                        onContractTypeChanged={type => {
                                                            setContractType(type)
                                                        }}
                                                        value={contractType}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col>
                                                    <>
                                                        <AvForm className="align-items-center"
                                                                onValidSubmit={(e, v) => {
                                                                    setData({...data, ...v})
                                                                }}>
                                                            <Row className="justify-content-center">
                                                                <Col>
                                                                    <FormGroup className="mb-4">
                                                                        <Label className="col-form-label">Start
                                                                            Date</Label>
                                                                        <AvField required name="start_date"
                                                                                 className="form-control-lg"
                                                                                 type="date"
                                                                                 id="start-date-input"/>
                                                                    </FormGroup>
                                                                </Col>
                                                                <Col>
                                                                    <FormGroup className="mb-4">
                                                                        <Label className="col-form-label">End
                                                                            Date</Label>
                                                                        <AvField name="end_date"
                                                                                 className="form-control-lg"
                                                                                 type="date"
                                                                                 placeholder="Optional"
                                                                                 id="end-date-input"/>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row className="justify-content-between">
                                                                <Col xl={5} className="pr-2">
                                                                    <FormGroup className="mb-4">
                                                                        <label className="col-form-label">Amount</label>
                                                                        <InputGroup size="lg">
                                                                            <AvField required name="amount"
                                                                                     className="form-control-lg"
                                                                            />
                                                                            <InputGroupAddon addonType="append"
                                                                                             className="form-control-lg p-0">
                                                                                <AvInput type="select"
                                                                                         name="currency_id"
                                                                                         placeholder="Amount"
                                                                                         className="input-group-text"
                                                                                         style={{
                                                                                             height: '100%',
                                                                                             width: '150%',
                                                                                             marginLeft: "-7px",
                                                                                             borderBottomLeftRadius: 0,
                                                                                             borderTopLeftRadius: 0
                                                                                         }} value={1}>
                                                                                    {staticData?.currencies.map(e =>
                                                                                        <option
                                                                                            value={e.id}>{e.code}</option>)}
                                                                                </AvInput>
                                                                            </InputGroupAddon>
                                                                        </InputGroup>
                                                                    </FormGroup>

                                                                </Col>
                                                                <Col xl={7}>
                                                                    {contractType == 1 ? <Row>
                                                                            <Col>
                                                                                <FormGroup className="mb-4">
                                                                                    <label
                                                                                        className="col-form-label">Type</label>
                                                                                    <AvField required name="rate_id"
                                                                                             type="select"
                                                                                             className="form-control-lg"
                                                                                             value={1}>
                                                                                        {staticData?.rates?.map(e => <option
                                                                                            value={e.id}>{e.name}</option>)}
                                                                                    </AvField>
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col>
                                                                                <FormGroup>
                                                                                    <label
                                                                                        className="col-form-label">Frequency</label>
                                                                                    <AvField
                                                                                        value={staticData?.frequencies[0]?.id}
                                                                                        type="select" name="frequency_id"
                                                                                        onChange={(e) => {
                                                                                            setFrequencyId(e.target.value)
                                                                                        }}
                                                                                        className="form-control-lg select">
                                                                                        {staticData?.frequencies.map(e =>
                                                                                            <option
                                                                                                value={e.id}>{e.name}</option>)}
                                                                                    </AvField>
                                                                                </FormGroup>
                                                                            </Col>
                                                                        </Row> :
                                                                        <Row className="justify-content-center">
                                                                            <Col>
                                                                                <FormGroup>
                                                                                    <label
                                                                                        className="col-form-label">Frequency</label>
                                                                                    <AvField
                                                                                        value={staticData?.frequencies[0]?.id}
                                                                                        type="select"
                                                                                        name="frequency_id"
                                                                                        onChange={(e) => {
                                                                                            setFrequencyId(e.target.value)
                                                                                        }}
                                                                                        className="form-control-lg select">
                                                                                        {staticData?.frequencies.map(e =>
                                                                                            <option
                                                                                                value={e.id}>{e.name}</option>)}
                                                                                    </AvField>
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col>
                                                                                <FormGroup className="mb-4">
                                                                                    <Label className="col-form-label">First
                                                                                        Payment</Label>
                                                                                    <AvField required
                                                                                             name="first_payment_date"
                                                                                             className="form-control-lg"
                                                                                             type="date"
                                                                                             id="first-payment-date-input"/>
                                                                                </FormGroup>
                                                                            </Col>
                                                                        </Row>}
                                                                </Col>
                                                            </Row>
                                                            {contractType == 1 && <Row>
                                                                <Col xl={5}>
                                                                    <FormGroup className="mb-4">
                                                                        <Label className="col-form-label">First
                                                                            Payment</Label>
                                                                        <AvField required name="first_payment_date"
                                                                                 className="form-control-lg" type="date"
                                                                                 id="first-payment-date-input"/>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>}
                                                            <Row>
                                                                <Col xl={5}>
                                                                    <FormGroup className="mb-4">
                                                                        <Label
                                                                            className="col-form-label">Occurrence</Label>
                                                                        <AvField required type="select"
                                                                                 name="occurrence_id"
                                                                                 className="form-control-lg"
                                                                                 id="first-payment-date-input">
                                                                            {staticData?.occurrences.filter(o => o.frequency_id == frequencyId).map(e =>
                                                                                <option
                                                                                    value={e.id}>{e.name}</option>)}
                                                                        </AvField>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                            <Row className="justify-content-center border-top pt-3">
                                                                <Col>
                                                                    <FormGroup>
                                                                        <div
                                                                            className="custom-control custom-switch mb-2"
                                                                            dir="ltr">
                                                                            <AvInput name="first_payment_prorata"
                                                                                     type="checkbox"
                                                                                     className="custom-control-input"
                                                                                     id="customSwitch1" defaultChecked/>
                                                                            <label className="custom-control-label"
                                                                                   htmlFor="customSwitch1">
                                                                                Prorata on the first payment
                                                                            </label>
                                                                        </div>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        </AvForm>
                                                    </>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                </TabPane>
                                <TabPane tabId="6" className="p-3">
                                    <Row>
                                        <Col xl="4">
                                            <div className="form-group">
                                                <Label htmlFor="formname">Contratc ID : </Label>
                                                <Input
                                                    type="text"
                                                    id="formname"
                                                    className="form-control"
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </TabPane>
                            </TabContent>

                            <Row>
                                <button className="btn btn-primary btn-block waves-effect waves-light"
                                        type="submit">Start
                                </button>
                            </Row>

                            <Row className="mt-4">
                                <Col>
                                    <Card>
                                        <CardBody>
                                            <Media>
                                                <Media className="overflow-hidden" body>
                                                    {jobs.map(e => <JobTile/>)}
                                                </Media>
                                            </Media>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};
const JobTile = () => {
    return (<div className="text-muted mt-4 border-bottom pb-2">
        <p>
            <i
                className="mdi mdi-chevron-right text-primary mr-1"></i><span
            className="text-dark font-weight-bold">Due date:</span> {"31 jul, 2020"}
        </p>
        <p>
            <i
                className="mdi mdi-chevron-right text-primary mr-1"></i><span
            className="text-dark font-weight-bold">Invoice date:</span> {"25 jul, 2020"}
        </p>
        <p>
            <i
                className="mdi mdi-chevron-right text-primary mr-1"></i><span
            className="text-dark font-weight-bold">Amount:</span> {"1200 $"}
        </p>
    </div>)
}
export default TestPaymentCycle;

import React, {useState} from 'react';

import {connect} from "react-redux";
import {Row, Col} from "reactstrap";

import {Link} from "react-router-dom";
import {withRouter} from "react-router";
import {useSelector} from 'react-redux';
// Reactstrap
import {Dropdown, DropdownToggle, DropdownMenu} from "reactstrap";

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";

import megamenuImg from "../../assets/images/megamenu-img.png";
import logo from "../../assets/images/logo.svg";
import logoLightPng from "../../assets/images/logo-light.png";
import logoLightSvg from "../../assets/images/logo-light.png";
import logoDark from "../../assets/images/logo-dark.png";

// import images
import github from "../../assets/images/brands/github.png";
import bitbucket from "../../assets/images/brands/bitbucket.png";
import dribbble from "../../assets/images/brands/dribbble.png";
import dropbox from "../../assets/images/brands/dropbox.png";
import mail_chimp from "../../assets/images/brands/mail_chimp.png";
import slack from "../../assets/images/brands/slack.png";

//i18n
import {withNamespaces} from 'react-i18next';

// Redux Store
import {showRightSidebarAction, toggleLeftmenu, changeSidebarType} from "../../store/actions";
import {userTypes} from "../../helpers/enum";
import {useFetch} from "../../helpers/hooks";
import {getNotificationList, getTransferList} from "../../services/api";

const Header = (props) => {

    const [search, setsearch] = useState(false);
    const [megaMenu, setmegaMenu] = useState(false);
    const [socialDrp, setsocialDrp] = useState(false);
    const display = useSelector(state => state.Account?.user?.type);
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const {match, location, history} = props;
    const id = new URLSearchParams(props.location.search).get("id")

    const getTitle = () => {
        console.log('debugContractID', location.pathname);
        switch (location.pathname) {
            case "/activity":
                return display == userTypes.COMPANY ? 'Activity' : 'Overview';
            case "/contract/create":
                return "Contracts";
            case "/invoices":
                return "Invoices"
            case "/documents":
                return "Documents"
            case "/contracts":
                return "Contracts"
            case "/history":
                return "History"
            case "/payment":
                return "Payment"
            case "/profile-settings":
                return "Profile Settings"
            case "/upcoming-payment":
                return "Upcoming Payments"
            case "/contract/detail":
                return "Contract Details"
            case "/invoice/detail":
                return "Invoice Detail"
            case "/withdraw":
                return "Withdrawal"
            case "/settings":
                return display == userTypes.COMPANY ? "Company Settings" : "Profile Settings"
            default:
                return ""
        }
        if (location.pathname?.startsWith("/payment")) {
            return "Payment"
        }
    }

    function toggleFullscreen() {
        if (
            !document.fullscreenElement &&
            /* alternative standard method */ !document.mozFullScreenElement &&
            !document.webkitFullscreenElement
        ) {
            // current working methods
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen(
                    Element.ALLOW_KEYBOARD_INPUT
                );
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    }

    function tToggle() {
        props.toggleLeftmenu(!props.leftMenu);
        if (props.leftSideBarType === "default") {
            props.changeSidebarType("condensed", isMobile);
        } else if (props.leftSideBarType === "condensed") {
            props.changeSidebarType("default", isMobile);
        }
    }

    return (
        <React.Fragment>
            <header id="page-topbar">
                <div className="navbar-header">
                    <div className="d-flex">
                        {!isMobile && <div className="navbar-brand-box">
                            <Link to="/" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={logo} alt="" height="50"/>
                  </span>
                                <span className="logo-lg">
                    <img src={logoDark} alt="" height="50"/>
                  </span>
                            </Link>

                            <Link to="/" className="logo logo-light">
                  <span className="logo-sm">
                    <img src={logoLightSvg} alt="" height="22"/>
                  </span>
                                <span className="logo-lg">
                    <img src={logoLightPng} alt="" height="50"/>
                  </span>
                            </Link>
                        </div>}
                        {isMobile && <button type="button" onClick={() => {
                            tToggle()
                        }} className="btn btn-sm px-3 font-size-16 header-item waves-effect" id="vertical-menu-btn">
                            <i className="fa fa-fw fa-bars"></i>
                        </button>}
                        <Row className="align-items-center p-4">
                            <Col>
                                <h4 className="mb-0 font-size-18">{`${getTitle()} ${id ? " #" : ''}${id || ""}`}</h4>
                            </Col>
                        </Row>

                    </div>
                    <div className="d-flex">
                        {/*<LanguageDropdown />*/}

                        <div className="dropdown d-none d-lg-inline-block ml-1">
                            <button type="button" onClick={() => {
                                {/*toggleFullscreen();*/
                                }
                            }} className="btn header-item noti-icon waves-effect">
                                <i className="bx bx-support"></i>
                            </button>
                        </div>

                        <NotificationDropdown/>
                        <ProfileMenu isMobile={isMobile}/>
                    </div>
                </div>
            </header>
        </React.Fragment>
    );
}
const mapStatetoProps = state => {
    const {layoutType, showRightSidebar, leftMenu, leftSideBarType} = state.Layout;
    return {layoutType, showRightSidebar, leftMenu, leftSideBarType};
};

export default withRouter(connect(mapStatetoProps, {
    showRightSidebarAction,
    toggleLeftmenu,
    changeSidebarType
})(withNamespaces()(Header)));

import React from 'react';
import {Modal} from "reactstrap";
import {submitMilestone} from "../../../../services/api";
import {useSelector} from "react-redux";

const SubmitMilestoneModal = ({toggle, isOpen, milestone, onMilestoneSubmitted}) => {
    const user = useSelector(state => state?.Account?.user)
    console.log(milestone);
    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
        >
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title mt-0">Submit milestone</h5>
                    <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={toggle}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <p>Are you sure ?</p>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                            submitMilestone(user.token, {work_id: milestone?.id}).then(r => {
                                if (r.data.success) {
                                    toggle()
                                    onMilestoneSubmitted()
                                }
                                console.log(r);
                            }).catch(e => {
                                console.log(e);
                            })
                        }}
                    >
                        Submit
                    </button>
                    <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                        onClick={toggle}
                    >
                        Cancel
                    </button>
                </div>
            </div>

        </Modal>
    );
};

export default SubmitMilestoneModal;

import React, {Component} from 'react';
import {Card, CardBody, Col, Row} from "reactstrap";

import ReactApexChart from 'react-apexcharts';

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'AED',
});
class Earning extends Component {
    constructor(props) {
        super(props);
        this.state = {
            series: [
                {name:"series1",data:[31,40,36,51,49,72,69,56,68,82,68,76]}
            ],
            options : {
                chart: {
                    toolbar:"false",
                    dropShadow: {
                        enabled:!0,
                        color:"#000",
                        top:18,
                        left:7,
                        blur:8,
                        opacity:.2
                    }
            },
            dataLabels: {
                enabled:!1
            },
            colors:["#556ee6"],
            stroke: {
                curve:"smooth",
                width:3
            }
        }
        };
    };

    render() {
        return (
            <React.Fragment>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <div className="clearfix">
                                            <div className="float-right">
                                                <div className="input-group input-group-sm">
                                                    <select className="custom-select custom-select-sm">
                                                        <option defaultValue>2020</option>
                                                        <option value="1">2019</option>
                                                        <option value="2">2018</option>
                                                        <option value="3">2017</option>
                                                    </select>
                                                    <div className="input-group-append">
                                                        <label className="input-group-text">Year</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <h4 className="card-title mb-4">Earning</h4>
                                        </div>

                                        <Row>
                                            <Col lg="4">
                                                <div className="text-muted">
                                                    <div className="mb-4">
                                                        <p>This month</p>
                                                        <h4>{formatter.format(2500)}</h4>
                                                        <div><span className="badge badge-soft-success font-size-12 mr-1"> + 0.2% </span> From previous period</div>
                                                    </div>
                                                    <div className="mt-4">
                                                        <p className="mb-2">Last month</p>
                                                        <h5>{formatter.format(2281.04)}</h5>
                                                    </div>

                                                </div>
                                            </Col>

                                            <Col lg="8">
                                                <div id="line-chart" className="apex-charts" dir="ltr">
                                                <ReactApexChart series={this.state.series} options={this.state.options} type="line" height={320} />
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
            </React.Fragment>
        );
    }
}

export default Earning;

import React, {useState} from 'react';
import {Badge, Card, CardBody, Col, Container, Modal, Row, Spinner, Table} from "reactstrap";
import {useFetch} from "../../../../helpers/hooks";
import {confirmTransaction, getTransactionList} from "../../../../services/api";
import {Link} from "react-router-dom";
import moment from "moment";
import Select from "react-select";
import {storage} from "../../../../helpers/config";

const Transactions = () => {
    const [expanded, setExpanded] = useState(-1);
    const transactions = useFetch({
        autoFetch: true, initResult: null, action: getTransactionList
    })
    const confirm = useFetch({
        autoFetch: false, initResult: null, action: confirmTransaction, onComplete: () => {
            setIsOpen(false)
            setToConfirm(null)
            transactions.startFetch({})
        }
    })
    const handleConfirm = () => {
        confirm.startFetch({transaction_id: toConfirm?.transaction_id})
    }

    const [isOpen, setIsOpen] = useState(false);
    const [toConfirm, setToConfirm] = useState(null);
    return (
        <div className="page-content">
            {transactions.isLoading ? <Container style={{minHeight: '30rem'}}>
                <Col style={{minHeight: '30rem'}}>
                    <Row style={{minHeight: '30rem'}} className="justify-content-center align-items-center">
                        <Spinner type="grow" className="mr-2" color="primary"/>
                    </Row>
                </Col>
            </Container> : <Container fluid>
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <div className="table-responsive">
                                    <Table className="table table-centered table-nowrap text-muted">
                                        <thead className="thead-light">
                                        <tr className="text-muted">
                                            <th className="border-top-0 text-muted">{""}</th>
                                            <th className="border-top-0 text-muted">TRX ID</th>
                                            <th className="border-top-0 text-muted">Type</th>
                                            <th className="border-top-0 text-muted">Method</th>
                                            <th className="border-top-0 text-muted">Client name</th>
                                            <th className="border-top-0 text-muted">Amount</th>
                                            <th className="border-top-0 text-muted">TRX date</th>
                                            <th className="border-top-0 text-muted">Confirmed at</th>
                                            <th className="border-top-0 text-muted">TRX Status</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            transactions?.data?.map((order, key) => {
                                                const formatter = new Intl.NumberFormat('en-US', {
                                                    style: 'currency',
                                                    currency: order?.currency?.code || "USD",
                                                });
                                                    return <><tr key={"_order_" + key}>
                                                        <th>
                                                            {order?.works?.length != 0 && <a onClick={() => {
                                                                if (expanded == key) {
                                                                    setExpanded(-1)
                                                                } else {
                                                                    setExpanded(key)
                                                                }
                                                            }}>
                                                                <i className="bx bx-plus"/>
                                                            </a>}
                                                        </th>
                                                        <td>
                                                                  {order.transaction_ref}
                                                        </td>
                                                        <td>
                                                            {order.type.name}
                                                        </td>
                                                        <td>
                                                            {order.method.name}
                                                        </td>
                                                        <td>
                                                            {order.client?.first_name} {order.client?.last_name}
                                                        </td>
                                                        <td className="text-right">
                                                            {formatter.format(order.amount)}
                                                        </td>
                                                        <td>
                                                            {order.created_at}
                                                        </td>
                                                        <td>
                                                            {order.confirmed_at}
                                                        </td>
                                                        <td>
                                                            <div className="w-md">
                                                                {order.status == 'Confirmed' ?
                                                                    <Badge
                                                                        className={"font-size-12 rounded badge-success my-2"}
                                                                        color={"white"}
                                                                        pill>{order.status}
                                                                    </Badge> :
                                                                    <Select
                                                                        name="currency_id"
                                                                        onChange={(s) => {
                                                                            setToConfirm(order)
                                                                            setIsOpen(true)
                                                                        }}
                                                                        defaultValue={{
                                                                            value: order.status,
                                                                            label: order.status
                                                                        }}
                                                                        options={[
                                                                            {value: 'Confirmed', label: 'Confirmed'},
                                                                        ]}
                                                                        styles={customStyles}
                                                                        components={{Option: CustomOption}}
                                                                    />}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                {expanded == key &&
                                                <>
                                                    {order?.works?.map(t => {
                                                        const formatter = new Intl.NumberFormat('en-US', {
                                                            style: 'currency',
                                                            currency: t?.currency || "USD",
                                                        });
                                                        return <tr
                                                            className="font-weight-light font-size-12 bg-soft-primary"
                                                            key={"_trans" + t?.id}>
                                                            <th>{""}</th>
                                                            <th className="font-weight-bold font-size-12">{t?.contract_ref}</th>
                                                            <td>
                                                                <Col>
                                                                    <Row className="align-items-center flex-nowrap">
                                                                        {
                                                                            !t.contractor?.photo
                                                                                ? <div className="avatar-xs">
                                                                                <span
                                                                                    className="avatar-title rounded-circle">
                                                                                    {t.contractor?.first_name?.charAt(0)}
                                                                                </span>
                                                                                </div>
                                                                                : <div>
                                                                                    <img className="rounded-circle avatar-xs"
                                                                                         src={`${storage}${t.contractor?.photo}`} alt=""/>
                                                                                </div>
                                                                        }
                                                                        <div
                                                                            style={{marginLeft: "10px"}}>{t.contractor?.first_name} {t.contractor?.last_name}</div>
                                                                    </Row>
                                                                </Col>
                                                            </td>                                                            <th className="font-weight-normal font-size-12">{t?.details}</th>
                                                            <th className="font-weight-normal font-size-12">{t?.name}</th>
                                                            <th className="font-weight-normal font-size-12 text-right">{formatter.format(t?.amount)}</th>
                                                            <th className="border-top-0 text-muted">{""}</th>
                                                            <th className="border-top-0 text-muted">{""}</th>
                                                            <th className="border-top-0 text-muted">{""}</th>
                                                            <th className="border-top-0 text-muted">{""}</th>
                                                        </tr>
                                                    })}
                                                </>
                                                }
                                                </>
                                                }
                                            )
                                        }

                                        </tbody>
                                    </Table>
                                    <ConfirmationModal
                                        isOpen={isOpen}
                                        toggle={() => setIsOpen(false)}
                                        onConfirm={handleConfirm}
                                    />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>}

        </div>
    );
};

const CustomOption = (props) => {
    console.log('CustomOption', props);
    const {innerProps, isDisabled} = props
    return !isDisabled ? (
        <div {...innerProps} className="p-2">
            <Badge
                className={"font-size-12 rounded badge-success"}
                color={"white"}
                pill>{props.value}</Badge>
        </div>
    ) : null;
}
const customStyles = {

    dropdownIndicator: () => ({
        color: '#556ee6',
        paddingRight: '10px',
        paddingLeft: '10px'
    }),
    indicatorSeparator: () => ({
        width: 0
    }),
    control: () => ({
        display: 'flex',
        flexDirection: 'row',
        borderRadius: 5,
        borderWidth: 2,
        borderColor: 'gray'

    }),

}

const ConfirmationModal = ({isOpen, toggle, onConfirm}) => <Modal
    isOpen={isOpen}
    toggle={toggle}
>
    <div className="modal-content">
        <div className="modal-header">
            <h5 className="modal-title mt-0">Confirm Transaction</h5>
            <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={toggle}
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div className="modal-body">
            <p>Are you sure ?</p>
        </div>
        <div className="modal-footer">
            <button
                type="button"
                className="btn btn-primary"
                onClick={onConfirm}
            >
                Confirm
            </button>
            <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
                onClick={toggle}
            >
                Cancel
            </button>
        </div>
    </div>

</Modal>

export default Transactions;

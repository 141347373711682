import React from 'react';
import {AvField, AvForm} from "availity-reactstrap-validation";
import {Col} from "reactstrap";

const ChangePassword = () => {
    const handleValidSubmit = () => {

    }
    return (
        <Col md={8} lg={6} xl={5}>
            <AvForm className="form-horizontal" onValidSubmit={(e, v) => {
                handleValidSubmit(e, v)
            }}>

                {/*{props.error && props.error ?*/}
                {/*    <Alert color="danger">{props.error}</Alert> : null}*/}


                <div className="form-group">
                    <AvField name="current_password" label="Current Password" type="password"
                             required placeholder="Current Password"/>
                </div>
                <div className="form-group">
                    <AvField name="password" label="New Password" type="password"
                             required placeholder="New Password"/>
                </div>

                <div className="form-group">
                    <AvField name="confirm_password" label="Confirm Password" type="password"
                             required placeholder="Confirm Password" validate={{match:{value:'password'}}}/>
                </div>



                <div className="mt-3">
                    <button
                        className="btn btn-primary btn-block waves-effect waves-light"
                        type="submit">Change Password
                    </button>
                </div>

            </AvForm>
        </Col>
    );
};

export default ChangePassword;

import React, {useRef, useState} from 'react';
import {Card, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import {Link} from "react-router-dom";
import classnames from 'classnames';
import ProfileInfo from "./ProfileInfo";
import ChangePassword from "../../components/CommonForBoth/ChangePassword";

const ProfileSettings = () => {
    const [activeTab, setActiveTab] = useState('1');
    const [loading, setLoading] = useState(false);
    const formRef = useRef(null);

    return (
        <div className="page-content">
            <Container fluid>
                <div className="checkout-tabs">
                    <Row>
                        <Col lg="2">
                            <Nav className="flex-column" pills>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === '1' })}
                                        onClick={() => { setActiveTab('1'); }}
                                    >
                                        <i className="bx bxs-user-detail d-block check-nav-icon mt-4 mb-2"></i>
                                        <p className="font-weight-bold mb-4">General Info</p>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({active: activeTab === '2'})}
                                        onClick={() => {
                                            setActiveTab('2');
                                        }}
                                    >
                                        <i className="bx bxs-key d-block check-nav-icon mt-4 mb-2"></i>
                                        <p className="font-weight-bold mb-4">Password</p>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </Col>
                        <Col lg="10">
                            <Card className="overflow-hidden">
                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId="1">
                                            <ProfileInfo ref={formRef} onStart={() => setLoading(true)}
                                                         onFinish={() => setLoading(false)}/>
                                        </TabPane>
                                    </TabContent>
                                <TabContent activeTab={activeTab}>
                                        <TabPane tabId="2">
                                            <ChangePassword/>
                                        </TabPane>
                                    </TabContent>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    );
}

export default ProfileSettings;

import React, {useEffect, useState} from 'react';
import {Badge, Card, CardBody, Col, Container, Row, Spinner, Table, UncontrolledTooltip} from "reactstrap";
import {withNamespaces} from 'react-i18next';
import {Link} from "react-router-dom";
import {downloadReceipt, getPaymentHistory} from "../../services/api";
import {useSelector} from "react-redux";
import moment from "moment";
import NoContent from "../../components/NoContent";
import {IntercomAPI} from "react-intercom";
import {storage} from "../../helpers/config";

const History = () => {
    const [data, setData] = useState([]);
    const user = useSelector(state => state.Account?.user)
    const [loading, setLoading] = useState(true);
    const [expanded, setExpanded] = useState(-1);
    useEffect(() => {
        getPaymentHistory(user?.token).then(r => {
            setLoading(false)
            if (r.data.success) {
                IntercomAPI('trackEvent', 'Viewed History');
                setData(r.data?.data.transactions || r.data?.data.transactions)
            }
        }).catch(e => {
            setLoading(false)
            console.log(e);
        })
    }, [])

    const getStatusColor = (status) => {
        switch (status) {
            case 'Processing':
                return 'warning';
            case 'Confirmed':
                return 'success';
            case 'Overdue':
                return 'danger';
            default:
                return 'primary';
        }
    }
    const handleDownloadInvoice = (transaction) => {
        console.log(transaction);
        downloadReceipt(transaction.ref, user?.token, transaction.token).then(r => {
            IntercomAPI('trackEvent', 'Download receipt', {
                receipt_id: transaction.ref,
            })
            console.log(r.data);
            const url = window.URL.createObjectURL(new Blob([r.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${transaction?.ref}.pdf`); //or any other extension
            document.body.appendChild(link);
            link.click();
        })
    }
    return (
        <div className="page-content">
            {loading ? <Container style={{minHeight: '30rem'}}>
                <Col style={{minHeight: '30rem'}}>
                    <Row style={{minHeight: '30rem'}} className="justify-content-center align-items-center">
                        <Spinner type="grow" className="mr-2" color="primary"/>
                    </Row>
                </Col>
            </Container> : <Container fluid>
                {data?.length == 0 ? <Card>
                    <CardBody>
                        <NoContent
                            subtitle="All your payment & withdraws transactions history will be shown"
                            image={require('./../../assets/images/empty_transactions.png')}
                        />
                    </CardBody>
                </Card> : <Row>
                    <Col xs="12">
                        <Card>
                            <CardBody>
                                <Row className="mb-2">
                                    <Col sm="4">
                                        {/*<div className="search-box mr-2 mb-2 d-inline-block">*/}
                                        {/*    <div className="position-relative">*/}
                                        {/*        <Input type="text" className="form-control" placeholder="Search..."/>*/}
                                        {/*        <i className="bx bx-search-alt search-icon"></i>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </Col>
                                    <Col sm="8">
                                    </Col>
                                </Row>

                                <div className="table-responsive">
                                    <Table className="table table-centered table-nowrap text-muted">
                                        <thead className="thead-light">
                                        <tr>
                                            <th className="border-top-0 text-muted">{""}</th>
                                            <th className="border-top-0 text-muted">Transaction ID</th>
                                            <th className="text-center border-top-0 text-muted">Date</th>
                                            <th className="border-top-0 text-muted">Type</th>
                                            <th className="border-top-0 text-muted">Status</th>
                                            <th className="text-right border-top-0 text-muted">Total</th>
                                            {user.type == 'client' && <th className="text-center border-top-0 text-muted">Download</th>}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            data?.map((order, key) => {
                                                    const formatter = new Intl.NumberFormat('en-US', {
                                                        style: 'currency',
                                                        currency: order?.currency?.code || "USD",
                                                    });
                                                return <>
                                                    <tr key={"_order_" + key}>
                                                        <th>
                                                            {order?.works?.length != 0 && order.status != "Paid (off-cycle)" && <a onClick={() => {
                                                                if (expanded == key) {
                                                                    setExpanded(-1)
                                                                } else {
                                                                    setExpanded(key)
                                                                }
                                                            }}>
                                                                <i className="bx bx-plus"/>
                                                            </a>}
                                                        </th>
                                                        <td>
                                                            {order.ref}
                                                        </td>
                                                        <td className="text-center">
                                                            {moment(order.created_at).format('MM/DD/YYYY')}
                                                        </td>
                                                        <td>
                                                            {order.type?.name}
                                                        </td>
                                                        <td>
                                                            <div>
                                                                <Badge
                                                                    className={"font-size-12 rounded badge-" + getStatusColor(order.status)}
                                                                    color={"white"}
                                                                    pill>{order.status}</Badge>
                                                            </div>
                                                        </td>
                                                        <td className={`text-right`}>
                                                            {`${user?.type != 'client' && order.type?.id != 1 ? "-" : ""}`} {formatter.format(order.amount)}
                                                        </td>
                                                        {order.status == "Confirmed" && user.type == 'client' && <td>
                                                            <Col>
                                                                <Row className="justify-content-center">
                                                                    <Link to="#"
                                                                          onClick={() => handleDownloadInvoice(order)}>
                                                                        <i className="fas fa-download mr-1"
                                                                           id="deletetooltip"></i><span>Receipt</span>
                                                                        <UncontrolledTooltip placement="top"
                                                                                             target="deletetooltip">
                                                                            Download
                                                                        </UncontrolledTooltip>
                                                                    </Link>

                                                                </Row>
                                                            </Col>
                                                        </td>}
                                                    </tr>
                                                    {expanded == key &&
                                                    <>
                                                        {order?.works?.map(t => {
                                                            const formatter = new Intl.NumberFormat('en-US', {
                                                                style: 'currency',
                                                                currency: t?.currency || "USD",
                                                            });
                                                            return <tr
                                                                className="font-weight-light font-size-12 bg-soft-primary"
                                                                key={"_trans" + t?.id}>
                                                                <th>{""}</th>
                                                                <th className="font-weight-bold font-size-12">{t.contract_ref}</th>
                                                                {user.type == 'client' ? <td>
                                                                    <Col>
                                                                        <Row className="align-items-center flex-nowrap">
                                                                            {
                                                                                !t.contractor?.photo
                                                                                    ? <div className="avatar-xs">
                                                                                <span
                                                                                    className="avatar-title rounded-circle">
                                                                                    {t.contractor?.first_name?.charAt(0)}
                                                                                </span>
                                                                                    </div>
                                                                                    : <div>
                                                                                        <img className="rounded-circle avatar-xs"
                                                                                             src={`${storage}${t.contractor?.photo}`} alt=""/>
                                                                                    </div>
                                                                            }
                                                                            <div
                                                                                style={{marginLeft: "10px"}}>{t.contractor?.first_name} {t.contractor?.last_name}</div>
                                                                        </Row>
                                                                    </Col>
                                                                </td>:<th>{""}</th>}
                                                                <th className="font-weight-normal font-size-12">{t?.details}</th>
                                                                <th className="font-weight-normal font-size-12">{t?.name}</th>
                                                                <th className="font-weight-normal font-size-12 text-right">{formatter.format(t?.amount)}</th>
                                                                {user.type == 'client' && <th>{""}</th>}

                                                            </tr>
                                                        })}
                                                    </>
                                                    }
                                                </>
                                            }
                                            )
                                        }

                                        </tbody>
                                    </Table>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>}
            </Container>}
        </div>
    );
}

export default withNamespaces()(History);

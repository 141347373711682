import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {Col, FormGroup, Input, Label, Row} from "reactstrap";
import {AvField, AvForm} from "availity-reactstrap-validation";
import Select from "react-select";
import {
    createAccount,
    estimateAmount,
    getContractorInfo,
    getCurrencies,
    getRequirements,
    TWTransfer
} from "../../services/api";
import toastr from "toastr";

const BankAccounts = ({onNext = () => {}, onAccountAdded = () => {}, currency, isEdit}) => {
    const [accounts, setAccounts] = useState([]);
    const [checkedCard, setCheckedCard] = useState(-1);
    const [editing, setEditing] = useState(false);
    const user = useSelector(state => state.Account?.user)

    const [method, setMethod] = useState(0);

    const [currencies, setCurrencies] = useState([]);
    const [selectedCurrency, setSelectedCurrency] = useState(currencies ? currencies[0] : null);
    const [fields, setFields] = useState([]);
    const [type, setType] = useState(null);
    const [types, setTypes] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showSignModal, setShowSignModal] = useState(false);

    function handleSelectCurrency(type) {
        setSelectedCurrency(type);
    }


    function handleValidSubmit(event, values) {
        console.log(values);
        const newValues = {...values}
        delete newValues.legalType;
        console.log(user);
        const data = {
            currency: selectedCurrency?.value,
            user_id: user?.id,
            accountHolderName: `${user?.first_name} ${user?.last_name}`,
            type: type?.type,
            legalType: values?.legalType,
            details: {...newValues}
        }
        setLoading(true)
        createAccount(data).then(r => {
            console.log(r);
            if (r.data.success) {
                updateList()
                setLoading(false)
                setEditing(false)
                onAccountAdded(r.data?.data)
                // transfer(405, r.data?.data?.id, selectedCurrency?.value)
            } else {
                setLoading(false)
                setShowSignModal(false)
            }
        }).catch(e => {
            setLoading(false)
        })
        console.log(data);
    }

    useEffect(() => {
        setLoading(true)
        getCurrencies().then(r => {
            setLoading(false)
            console.log(r);
            setCurrencies(r.data.data.map(e => ({label: e.code, value: e.code})))
        }).catch(e => {
            setLoading(false)
        })
    }, [])
    useEffect(() => {
        console.log(fields);
    }, [fields])
    useEffect(() => {
        console.log(type);
        if (type) {
            setFields(type?.fields)
        }
    }, [type])
    useEffect(() => {
        console.log(types);
        if (types?.length == 1) {
            setType(types[0])
        }
    }, [types])
    useEffect(() => {
        console.log(selectedCurrency);
        setFields([])
        setType(null)
        if (selectedCurrency) {
            setLoading(true)
            getRequirements({
                source: currency,
                target: selectedCurrency?.value,
                targetAmount: 300
            }).then(r => {
                setLoading(false)
                console.log(r);
                setTypes(r.data.data.filter(t => t.type != 'email').map(e => ({...e, label: e.title, value: e.type})))
            }).catch(e => {
                setLoading(false)
            })
        }
    }, [selectedCurrency])

    useEffect(() => {
        console.log(method);
    }, [method])

    const updateList = () => {
        getContractorInfo(user?.token).then(r => {
            console.log(r);
            if (r.data.success){
                setAccounts(r.data.data['bank-accounts'])
            }
        })
    }
    useEffect(() => {
        updateList()
    }, [])

    const AccountItem = ({card, index}) => {
        console.log(card);
        const accountNumber = (JSON.parse(card.details)?.iban || JSON.parse(card.details)?.accountNumber)
        return <Label className="card-radio-label mb-3">
            <Input type="radio" name="pay-method" id={"credit-card-" + index}
                   className="card-radio-input"
                   onClick={() => {
                       console.log('card_element', card);
                       setCheckedCard(index)
                       onNext(card)
                   }}/>

            <div
                className={"card-radio p-2 rounded" + (checkedCard == index ? " border-primary" : " border-light")}>
                <Col>
                    <Row className="flex-nowrap">
                        <i className={`bx bxs-bank font-size-24 ${checkedCard == index ? "text-primary" : "text-secondary"} align-middle mr-2`}></i>

                        <Col>
                            <h5 className={checkedCard == index ? "my-0 text-primary mb-2 bold" : "my-0 text-secondary mb-2 bold"}
                                style={{textJustify: 'center', alignItems: 'center'}}>
                                {"Account Holder: "+card.holder_name}
                            </h5>
                            <p className="text-muted">************ {accountNumber.slice(accountNumber?.length - 4, accountNumber?.length)} {card?.currency}</p>
                        </Col>
                    </Row>
                </Col>
            </div>
        </Label>
    }
    const FormAddAccount = () => (<AvForm onValidSubmit={(e, v) => {
        handleValidSubmit(e, v)
    }}>
        <div style={{minHeight: '400px'}}>
            <Col>
                <FormGroup className="select2-container mb-4">
                    <Label htmlFor="cardnumberInput">Select the desired currency</Label>
                    <Select
                        name="currency"
                        value={selectedCurrency}
                        onChange={(s) => {
                            handleSelectCurrency(s);
                        }}
                        options={currencies}
                    />
                </FormGroup>
            </Col>
            {types?.length > 1 &&
            <Col>
                <FormGroup md="4" className="select2-container mb-4">
                    <Label htmlFor="cardnumberInput">Type</Label>
                    <Select
                        name="currency"
                        value={type}
                        onChange={(s) => {
                            setType(s);
                        }}
                        options={types}
                    />
                </FormGroup>
            </Col>
            }
            {fields.map(f => {
                return (<Col>
                    <FormGroup className="mt-4 mb-0">
                        <Label htmlFor="name">{f.name}</Label>
                        <AvField name={f.group[0]?.key} type={f.group[0].type == 'radio' ? 'select' : f.group[0].type}
                                 className="form-control" id={f.group[0].key}
                                 placeholder={f.name} key={f.group[0]?.key}
                                 value={f.group[0]?.valuesAllowed ? f.group[0]?.valuesAllowed[0]?.key : null}>
                            {f.group[0]?.valuesAllowed?.map(e => <option
                                value={e.key}>{e.name}</option>)}
                        </AvField>
                    </FormGroup>
                </Col>)
            })}
        </div>
        <div className="modal-footer"><Row className="justify-content-center">
            <button className="btn btn-primary waves-effect waves-light"
                    type="submit"> {loading &&
            <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>} Add account
            </button>
        </Row></div>
    </AvForm>)
    return (
        <Row className="justify-content-center">
            <Col className="p-0" md={isEdit?9:8}>
                {!isEdit && <Row className="justify-content-center">
                    <Col md={8} className="border-bottom border-light">
                        <h1 className="font-weight-light text-center">Select an account</h1>
                    </Col>
                </Row>}
                {!editing ? <Row className="justify-content-center">
                    <Col md={isEdit?9:8} className="pt-4 px-5">
                        {accounts.map((e, i) => <AccountItem card={e} index={i}/>)}
                        <a
                            onClick={() => setEditing(true)}
                            className="text-primary font-weight-bold px-0">+ Add New Bank Account
                        </a>
                    </Col>
                </Row> : <Row className="justify-content-center mt-5">
                    <Col md={isEdit?7:6}>
                        <FormAddAccount/>
                    </Col>
                </Row>}
            </Col>
        </Row>
    );
};

export default BankAccounts;

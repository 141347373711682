import React, {useEffect, useState} from 'react';
import {Card, CardBody, Col, Container, Row, TabContent, TabPane} from "reactstrap";
import CreditCards from "./creditCards";
import {Link} from "react-router-dom";
import PaymentMethods from "./paymentMethods";
import PaymentReview from "./PaymentReview";
import ConfirmationPage from "./ConfirmationPage";
import BankAccount from "./BankAccount";
import {
    createTransactionCC,
    createTransactionTransfer,
    getAllPaymentList,
    getPaymentMethods,
    getMethods,
    getTransactionRef
} from "../../services/api";
import {useSelector} from "react-redux";
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import Steps from "../../components/Steps";
import Wootric from "../../components/Wootric/Wootric";
import {IntercomAPI} from "react-intercom";
import {useFetch} from "../../helpers/hooks";

const PayInvoices = ({history, match}) => {
    const [activeTab, setActiveTab] = useState(0);
    const [paymentMethod, setPaymentMethod] = useState(-1);
    const [selectedCard, setSelectedCard] = useState(null);
    const [loading, setLoading] = useState(false);
    const [countryId, setCountryId] = useState(null);
    const user = useSelector(state => state.Account?.user)
    const selectedList = useSelector(state => state?.Payment?.toPay)
    if (!selectedList || selectedList?.length == 0) {
        history.push('payment')
    }
    console.log('ParamsPay', match.params);

    console.log("selectedList", selectedList, selectedList ? [].concat(...selectedList?.map(e => [...e.works?.map(w => w.id)])) : "");

    const paymentMethods = useFetch({
        autoFetch: true, initResult: null, action: getMethods, body: {
            payment_item_ids: selectedList ? [].concat(...selectedList?.map(e => [...e.works?.map(w => w.id)])) :[]
        }
    })
    const prepareTRX = useFetch({
        autoFetch: false, initResult: null, action: getTransactionRef, onComplete: (data) => {
            IntercomAPI('trackEvent', 'Clicked on Pay contractors', {
                pay_amount: data?.methods[0]?.subtotal,
                pay_currency: data?.currency?.code
            })
        }
    })

    useEffect(() => {
        console.log(selectedList, selectedCard);
    }, [selectedList, selectedCard])


    const processPayment = (ref) => {
        setLoading(true)
        if (paymentMethod == 1) {
            createTransactionCC(user?.token, {
                quote_id: prepareTRX.data?.quote_id,
                payment_method_id: selectedCard?.id,
            }).then(r => {
                setLoading(false)
                if (r.data.success) {
                    IntercomAPI('trackEvent', 'Payment step 4 - Payment Confirmation', {
                        pay_amount: paymentMethods.data?.methods[0]?.subtotal,
                        pay_currency: paymentMethods.data?.currency?.code,
                        method: paymentMethod,
                        transaction_id: r.data.data?.ref
                    })
                    setActiveTab(activeTab + 1)
                } else {
                    toastr.error(r.data.message)
                }
            }).catch(e => {
                setLoading(false)
            })
        } else {
            createTransactionTransfer(user?.token, {
                quote_id: prepareTRX.data?.quote_id,
                country_id: countryId,
            }).then(r => {
                setLoading(false)
                if (r.data.success) {
                    IntercomAPI('trackEvent', 'Payment step 4 - Payment Confirmation', {
                        pay_amount: paymentMethods.data?.methods[0]?.subtotal,
                        pay_currency: paymentMethods.data?.currency?.code,
                        method: paymentMethod,
                        transaction_id: r.data.data?.ref
                    })
                    setActiveTab(activeTab + 1)
                } else {
                    toastr.error(r.data.message)
                }
            }).catch(e => {
                setLoading(false)
            })
        }
    }
    return (
        <div className="page-content min-vh-100 p-0 m-0">
            <Container fluid>
                <Row className="justify-content-center">
                    <Col className="p-0">
                        <Card className="min-vh-100">
                            <CardBody className="pt-0">
                                <Row
                                    className="justify-content-between align-items-center  flex-nowrap border-bottom border-light sticky-top bg-white">
                                    <div className="p-3">
                                        <img height={32} src={require('./../../assets/images/RW-icon-Blue.png')}/>
                                    </div>
                                    <Col>
                                        <Steps activeTab={activeTab}
                                               data={['Payment methods', 'Account', 'Review', 'Pay']}/>
                                    </Col>
                                    <div className="justify-content-end p-3">
                                        <a onClick={() => {
                                            history.goBack()
                                        }}>
                                            <i className='bx bx-x-circle font-weight-light'
                                               style={{fontSize: '2rem'}}/>
                                        </a>
                                    </div>
                                </Row>
                                <Col>
                                    <TabContent activeTab={activeTab}
                                                className="twitter-bs-wizard-tab-content">
                                        <TabPane tabId={0}>
                                            <PaymentMethods
                                                data={paymentMethods.data}
                                                loading={paymentMethods.isLoading}
                                                onSelected={e => {
                                                    prepareTRX.startFetch({
                                                        payment_method_id: e,
                                                        payment_item_ids: selectedList ? [].concat(...selectedList?.map(e => [...e.works?.map(w => w.id)])) :[]
                                                    })
                                                    IntercomAPI('trackEvent', 'Payment step 1 - Method', {
                                                        pay_amount: paymentMethods.data?.methods[0]?.subtotal,
                                                        pay_currency: paymentMethods.data?.currency?.code,
                                                        method: e
                                                    })
                                                    setPaymentMethod(e)
                                                }}
                                                onNext={() => {
                                                    setActiveTab(activeTab + 1)
                                                }}/>
                                        </TabPane>
                                        <TabPane tabId={1}>
                                            {paymentMethod == 1 &&
                                            <CreditCards
                                                onNext={card => {
                                                    IntercomAPI('trackEvent', 'Payment step 2 - Account', {
                                                        pay_amount: paymentMethods.data?.methods[0]?.subtotal,
                                                        pay_currency: paymentMethods.data?.currency?.code,
                                                        method: paymentMethod,
                                                        account: card?.id
                                                    })
                                                    setSelectedCard(card)
                                                    setActiveTab(activeTab + 1)
                                                }}
                                                onAccountAdded={account => {
                                                    IntercomAPI('trackEvent', 'Payment step 2 - Added account', {
                                                        pay_amount: paymentMethods.data?.methods[0]?.subtotal,
                                                        pay_currency: paymentMethods.data?.currency?.code,
                                                        method: paymentMethod,
                                                        account: account?.id
                                                    })
                                                }}
                                            />}
                                            {paymentMethod == 3 &&
                                            <BankAccount
                                                onNext={(countryId) => {
                                                    setActiveTab(activeTab + 1)
                                                    setCountryId(countryId)
                                                }}/>}
                                        </TabPane>
                                        <TabPane tabId={2}>
                                            <PaymentReview
                                                onBack={() => setActiveTab(activeTab - 1)}
                                                onNext={processPayment}
                                                data={prepareTRX.data}
                                                advance={match.params.id}
                                                loading={loading}
                                                card={selectedCard}
                                                bankInfo={paymentMethod == 3}
                                            />
                                        </TabPane>
                                        <TabPane tabId={3}>
                                            <ConfirmationPage
                                                onComplete={() => {
                                                    history.push('/')
                                                }}
                                            />
                                        </TabPane>
                                    </TabContent>
                                    {[1, 2].includes(activeTab) && <Row className="justify-content-center mx-5">
                                        <Col className="p-0 py-5" lg={6}>
                                            <ul className="pager wizard twitter-bs-wizard-pager-link p-0 m-0">
                                                <Link className="text-muted font-size-13"
                                                      onClick={() => {
                                                          setActiveTab(activeTab - 1)
                                                      }}
                                                >
                                                    <i className="mdi mdi-arrow-left mr-1"></i> Back </Link>

                                            </ul>
                                        </Col>
                                    </Row>}
                                </Col>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            {activeTab == 3 && <Wootric/>}
        </div>
    );
};

export default PayInvoices;

import React, {useEffect, useState} from 'react';
import {Button, Card, CardBody, Col, Container, Row, Spinner} from "reactstrap";
import NoContent from "../../components/NoContent";
import PaymentList from "../Payment/paymentList";
import toastr from "toastr";
import {useDispatch, useSelector} from "react-redux";
import {getUpcomingPaymentList} from "../../services/api";
import {updateToPayList} from "../../store/payment/actions";

const UpcomingPayment = ({history, match}) => {
    const user = useSelector(state => state.Account?.user)
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currency, setCurrency] = useState(null);
    const [totalToPay, setTotalToPay] = useState(0);
    const updateInvoiceList = () => {
        getUpcomingPaymentList(user?.token).then(r => {
            if (r.data.success) {
                if (match.params?.id) {
                    setData(r.data.data.map(e => ({...e, selected: e.id == match.params?.id})))
                } else {
                    setData(r.data.data.map(e => ({...e, selected: true})))
                }
            } else {
                toastr.error(r.data.message)
            }
            setLoading(false)
            console.log(r);
        }).catch(e => {
            setLoading(false)
        })
    }


    useEffect(() => {
        const selectedInvoice = data.find(e => e.selected)
        setCurrency(selectedInvoice?.currency?.id)
        const selectedElements = data.filter(e => e.selected)
        if (selectedElements?.length > 1) {
            setTotalToPay(selectedElements?.reduce((prev, current) => prev?.amount ? prev?.amount + current?.amount : prev + current?.amount))
        } else if (selectedElements?.length == 1) {
            setTotalToPay(selectedElements[0]?.amount)
        }
        dispatch(updateToPayList(selectedElements))
    }, [data])

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency?.code || 'USD',
    });

    const dispatch = useDispatch();

    useEffect(() => {
        updateInvoiceList();
    }, [])

    return <div className="page-content">{loading ? <Container style={{minHeight: '30rem'}}>
        <Col style={{minHeight: '30rem'}}>
            <Row style={{minHeight: '30rem'}} className="justify-content-center align-items-center">
                <Spinner type="grow" className="mr-2" color="primary"/>
            </Row>
        </Col>
    </Container> : <Container fluid>
        {data?.length == 0 ? <Card>
            <CardBody><NoContent
                image={require('./../../assets/images/empty_transactions.png')}
            /></CardBody></Card> : <Row>
            <Col>
                <Card className="mb-1">
                    <CardBody>
                        <PaymentList
                            withAdvance data={data} currency={currency}
                            onInvoiceSelected={i => {
                                console.log('checkDebug', i, currency, data[i]);
                                if (currency && data[i].currency?.id != currency) {
                                    toastr.error('Please select invoices with the same currency', 'error')
                                } else {
                                    setData(data.map((inv, idx) => ({
                                        ...inv,
                                        selected: i == idx ? !inv.selected : inv.selected
                                    })))
                                }
                            }}
                            onAdvanceSet={e => {
                                dispatch(updateToPayList([e]))
                                history.push("/pay-invoices/" + e.advance)
                            }}
                        />
                    </CardBody>
                </Card>
            </Col>
        </Row>}
    </Container>}</div>;
};

export default UpcomingPayment;

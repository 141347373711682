import React, {useRef, useState} from 'react';
import {Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import classnames from 'classnames';

import {Link} from "react-router-dom";
import Users from "./Users";
import PaymentInfo from "./PaymentInfo";
import CompanyInfo from "./CompanyInfo";
import {useSelector} from 'react-redux';
import ContractorSettings from "../ContractorSettings";
import {userTypes} from "../../helpers/enum";


const CompanySetting = () => {

    const formRef = useRef(null);
    const [activeTab, setActiveTab] = useState('1');
    const display = useSelector(state => state.Account?.user?.type)
    const [loading, setLoading] = useState(false);

    return (
        display == userTypes.COMPANY ? <div className="page-content">
            <Container fluid>
                <div className="checkout-tabs">
                    <Row>
                        <Col lg="2">
                            <Nav className="flex-column" pills>
                                <NavItem>
                                    <NavLink
                                        className={classnames({active: activeTab === '1'})}
                                        onClick={() => {
                                            setActiveTab('1');
                                        }}
                                    >
                                        <i className="bx bxs-id-card d-block check-nav-icon mt-4 mb-2"></i>
                                        <p className="font-weight-bold mb-4">Company Info</p>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({active: activeTab === '2'})}
                                        onClick={() => {
                                            setActiveTab('2');
                                        }}
                                    >
                                        <i className="bx bx-money d-block check-nav-icon mt-4 mb-2"></i>
                                        <p className="font-weight-bold mb-4">Payment Info</p>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({active: activeTab === '3'})}
                                        onClick={() => {
                                            setActiveTab('3');
                                        }}
                                    >
                                        <i className="bx bx-group d-block check-nav-icon mt-4 mb-2"></i>
                                        <p className="font-weight-bold mb-4">Users</p>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </Col>
                        <Col lg="10">
                            <Card>
                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId="1">
                                        <CompanyInfo ref={formRef} onStart={() => {
                                            setLoading(true)
                                        }} onFinish={() => {
                                            setLoading(false)
                                        }}/>
                                    </TabPane>
                                    <TabPane tabId="2" id="v-pills-payment" role="tabpanel"
                                             aria-labelledby="v-pills-payment-tab">
                                        <PaymentInfo/>
                                    </TabPane>
                                    <TabPane tabId="3" id="v-pills-confirm" role="tabpanel">
                                        <Card className="shadow-none mb-0">
                                            <CardBody>
                                                <Users/>
                                            </CardBody>
                                        </Card>
                                    </TabPane>
                                </TabContent>
                            </Card>

                        </Col>
                    </Row>
                </div>
            </Container>
        </div> : <ContractorSettings/>
    );
}

export default CompanySetting;

import React, {useEffect, useState} from 'react';
import {Col, FormGroup, Input, Label, Modal, Row} from "reactstrap";
import Card from "reactstrap/es/Card";
import CardBody from "reactstrap/es/CardBody";
import Select from "react-select";
import {
    addPayPalAccount,
    createAccount,
    estimateAmount,
    getCurrencies,
    getRequirements,
    TWTransfer
} from "../../services/api";
import {AvField, AvForm} from 'availity-reactstrap-validation';
import {useSelector} from 'react-redux';
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import WithdrawMethods from "../ContractorSettings/WithdrawMethods";
import PayPalAccounts from "../ContractorSettings/PaypalAcounts";
import BankAccounts from "../withdrawProcess/BankAccounts";
import PaypalAccounts from "../withdrawProcess/PaypalAccounts";

const WithdrawalMethod = ({data, canWithdraw, paypal, profile}) => {
    console.log(profile);
    const [method, setMethod] = useState(0);

    const user = useSelector(state => state?.Account?.user)
    const [currencies, setCurrencies] = useState([]);
    const [selectedCurrency, setSelectedCurrency] = useState(currencies ? currencies[0] : null);
    const [fields, setFields] = useState([]);
    const [type, setType] = useState(null);
    const [types, setTypes] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showSignModal, setShowSignModal] = useState(false);


    function handleSelectCurrency(type) {
        setSelectedCurrency(type);
    }

    function transfer(targetAmount, account, target) {
        setLoading(true)
        estimateAmount({
            source: "AED",
            target,
            targetAmount
        }).then(r1 => {
            console.log(r1);
            if (r1.data.success) {
                TWTransfer({
                    account,
                    quote: r1.data?.data?.id, // id from estimate endpoint
                    purpose: "pay"
                }).then(r2 => {
                    console.log(r2);
                    if (r2.data.success) {
                        toastr.success(r2.data.message)
                        setShowSignModal(false)
                    }
                    setLoading(false)
                }).catch(e => {
                    setLoading(false)
                })
            } else {
                setLoading(false)
            }
        }).catch(e => {
            setLoading(false)
        })
    }

    function handleValidSubmit(event, values) {
        console.log(values);
        const newValues = {...values}
        delete newValues.legalType;
        console.log(user);
        const data = {
            currency: selectedCurrency?.value,
            user_id: 4,
            accountHolderName: `${user?.first_name} ${user?.last_name}`,
            type: type?.type,
            legalType: values?.legalType,
            details: {...newValues}
        }
        setLoading(true)
        createAccount(data).then(r => {
            console.log(r);
            if (r.data.success && canWithdraw) {
                transfer(405, r.data?.data?.id, selectedCurrency?.value)
            } else {
                setLoading(false)
                setShowSignModal(false)
            }
        }).catch(e => {
            setLoading(false)
        })
        console.log(data);
    }

    useEffect(() => {
        setLoading(true)
        getCurrencies().then(r => {
            setLoading(false)
            console.log(r);
            setCurrencies(r.data.data.map(e => ({label: e.code, value: e.code})))
        }).catch(e => {
            setLoading(false)
        })
    }, [])
    useEffect(() => {
        console.log(fields);
    }, [fields])
    useEffect(() => {
        console.log(type);
        if (type) {
            setFields(type?.fields)
        }
    }, [type])
    useEffect(() => {
        console.log(types);
        if (types?.length == 1) {
            setType(types[0])
        }
    }, [types])
    useEffect(() => {
        console.log(selectedCurrency);
        setFields([])
        setType(null)
        if (selectedCurrency) {
            setLoading(true)
            getRequirements({
                source: "AED",
                target: selectedCurrency?.value,
                targetAmount: 300
            }).then(r => {
                setLoading(false)
                console.log(r);
                setTypes(r.data.data.filter(t => t.type != 'email').map(e => ({...e, label: e.title, value: e.type})))
            }).catch(e => {
                setLoading(false)
            })
        }
    }, [selectedCurrency])

    useEffect(() => {
        console.log(method);
    }, [method])

    const FormAddAccount = () => (<AvForm onValidSubmit={(e, v) => {
        handleValidSubmit(e, v)
    }}>
        <div style={{minHeight: '400px'}}>
            <FormGroup md="4" className="select2-container mb-4">
                <Label htmlFor="cardnumberInput">Currency</Label>
                <Col md="4" className="p-0">
                    <Select
                        name="currency"
                        value={selectedCurrency}
                        onChange={(s) => {
                            handleSelectCurrency(s);
                        }}
                        options={currencies}
                    />
                </Col>
            </FormGroup>
            {types?.length > 1 && <FormGroup md="4" className="select2-container mb-4">
                <Label htmlFor="cardnumberInput">Type</Label>
                <Col md="4" className="p-0">
                    <Select
                        name="currency"
                        value={type}
                        onChange={(s) => {
                            setType(s);
                        }}
                        options={types}
                    />
                </Col>
            </FormGroup>}
            <Row>{fields.map(f => {
                return (<Col lg="6">
                    <FormGroup className="mt-4 mb-0">
                        <Label htmlFor="name">{f.name}</Label>
                        <AvField name={f.group[0]?.key} type={f.group[0].type == 'radio' ? 'select' : f.group[0].type}
                                 className="form-control" id={f.group[0].key}
                                 placeholder={f.name} key={f.group[0]?.key}
                                 value={f.group[0]?.valuesAllowed ? f.group[0]?.valuesAllowed[0]?.key : null}>
                            {f.group[0]?.valuesAllowed?.map(e => <option
                                value={e.key}>{e.name}</option>)}
                        </AvField>
                    </FormGroup>
                </Col>)
            })}
            </Row>
        </div>
        <div className="modal-footer"><Row className="justify-content-center">
            <button className="btn btn-primary waves-effect waves-light"
                    type="submit"> {loading &&
            <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>} {canWithdraw ? 'Withdraw' : 'Add account'}
            </button>
        </Row></div>
    </AvForm>)

    return (
        <>
            <Row>
                <Col>
                    <Label className="card-radio-label mb-3">
                        <Input
                            type="radio" name="pay-method" id="pay-methodoption1"
                            className="card-radio-input"
                            onClick={() => {
                                setMethod(0)
                            }}
                            defaultChecked
                        />

                        <div className="card-radio">
                            <i className="bx bxs-bank font-size-24 text-primary align-middle mr-2"></i>
                            <span>Wire Transfer</span>
                        </div>
                    </Label>
                </Col>


                <Col>
                    <Label className="card-radio-label mb-3">
                        <Input
                            type="radio" name="pay-method" id="pay-methodoption2"
                            className="card-radio-input" defaultChecked={method == 1}
                            onClick={() => {
                                setMethod(1)
                            }}
                        />

                        <div className="card-radio">
                            <i className="fab fa-cc-paypal font-size-24 text-primary align-middle mr-2"></i>
                            <span>Paypal</span>
                        </div>
                    </Label>
                </Col>

            </Row>
            <Card>

                {method == 0 && <CardBody>
                    <BankAccounts isEdit currency={profile?.currency?.code}/>
                </CardBody>}

                {method == 1 && <CardBody>
                    <div style={{minHeight: '400px'}}>
                        <PaypalAccounts isEdit/>
                    </div>
                </CardBody>}

            </Card>
            <Modal
                size="lg"
                isOpen={showSignModal}
                scrollable
                toggle={() => {
                    setShowSignModal(false)
                }}
            >
                <div className="modal-header">
                    <h5
                        className="modal-title mt-0"
                        id="myLargeModalLabel"
                    >
                        Add account
                    </h5>
                    <button
                        onClick={() => {
                            setShowSignModal(false)
                        }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <FormAddAccount/>
                </div>
            </Modal>
        </>
    );
}

const paypalAccounts = () => {

}

export default WithdrawalMethod;

import { UPDATE_USER_PROFILE_INFO } from './actionTypes';

const initialState = {
    userProfile: null
}

const profile = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_USER_PROFILE_INFO:
            state = {
                ...state,
                userProfile: action.payload
            }
            break;
    }
    return state;
}

export default profile;

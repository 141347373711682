import React, {useEffect, useRef, useState} from 'react';
import {Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import {Link} from "react-router-dom";
import classnames from 'classnames';
import ProfileInfo from "./ProfileInfo";
import Kyc from "./KYC";
import WithdrawalMethod from "../Withdrawal/WithdrawalMethod";
import {withRouter} from "react-router";
import {getContractorInfo} from "../../services/api";
import {useSelector} from "react-redux";
import {IntercomAPI} from "react-intercom";
import ChangePassword from "../../components/CommonForBoth/ChangePassword";
import {storage} from "../../helpers/config";

const ContractorSettings = (props) => {
    const [activeTab, setActiveTab] = useState('1');
    const [loading, setLoading] = useState(false);
    const formRef = useRef(null);
    const user = useSelector(state => state?.Account?.user)
    const [data, setData] = useState({});

    useEffect(() => {
        console.log(props.location);
        switch (props.location.hash) {
            case "#kyc":
                setActiveTab('3')
                break;
            case "#profile":
                setActiveTab('1')
                break;

        }
    }, [props.location])

    useEffect(() => {
            IntercomAPI('trackEvent', 'Clicked on profile settings')
            getContractorInfo(user?.token).then(r => {

                console.log(r);
                if (r.data.success) {
                    IntercomAPI('trackEvent', 'Viewed profile info')
                    setData({
                        ...r.data.data,
                        photo: r.data?.data?.photo ? `${storage}${r.data?.data?.photo}` : r.data?.data?.photo
                    })
                }
            })
        }
        , [])
    console.log(props.location);
    return (
        <div className="page-content">
            <Container fluid>
                <div className="checkout-tabs">
                    <Row>
                        <Col lg="2">
                            <Nav className="flex-column" pills>
                                <NavItem>
                                    <NavLink
                                        className={classnames({active: activeTab === '1'})}
                                        onClick={() => {
                                            setActiveTab('1');
                                        }}
                                    >
                                        <i className="bx bxs-user-detail d-block check-nav-icon mt-4 mb-2"></i>
                                        <p className="font-weight-bold mb-4">General Info</p>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({active: activeTab === '2'})}
                                        onClick={() => {
                                            setActiveTab('2');
                                        }}
                                    >
                                        <i className="bx bx-money d-block check-nav-icon mt-4 mb-2"></i>
                                        <p className="font-weight-bold mb-4">Withdrawal Methods</p>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({active: activeTab === '3'})}
                                        onClick={() => {
                                            setActiveTab('3');
                                        }}
                                    >
                                        <i className="bx bxs-check-shield d-block check-nav-icon mt-4 mb-2"></i>
                                        <p className="font-weight-bold mb-4">KYC</p>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({active: activeTab === '4'})}
                                        onClick={() => {
                                            setActiveTab('4');
                                        }}
                                    >
                                        <i className="bx bxs-key d-block check-nav-icon mt-4 mb-2"></i>
                                        <p className="font-weight-bold mb-4">Password</p>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </Col>
                        <Col lg="10">
                            <Card className="overflow-hidden pb-5">
                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId="1">
                                        <ProfileInfo ref={formRef} onStart={() => setLoading(true)}
                                                     onFinish={() => setLoading(false)}/>
                                    </TabPane>
                                    <TabPane tabId="2" id="v-pills-payment" role="tabpanel"
                                             aria-labelledby="v-pills-payment-tab">
                                        <CardBody>
                                            <WithdrawalMethod data={data['bank-accounts'] || []}
                                                              paypal={data?.paypal_accounts || []} profile={data}/>
                                        </CardBody>
                                    </TabPane>
                                    <TabPane tabId="3" id="v-pills-confirm" role="tabpanel">
                                        <Card className="shadow-none mb-0">
                                            <CardBody>
                                                <Kyc/>
                                            </CardBody>
                                        </Card>
                                    </TabPane>
                                    <TabPane tabId="4" id="v-pills-confirm" role="tabpanel">
                                        <Card className="shadow-none mb-0">
                                            <CardBody>
                                                <ChangePassword/>
                                            </CardBody>
                                        </Card>
                                    </TabPane>
                                </TabContent>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    );
}

export default withRouter(ContractorSettings);

import React, {useEffect, useState} from 'react';
import {CardBody, Col, FormGroup, Label} from "reactstrap";
import Dropzone from "react-dropzone";
import {Link} from "react-router-dom";
import Row from "reactstrap/es/Row";
import {getClientInfo, getContractorInfo, updateMemberProfile} from "../../services/api";
import {useDispatch, useSelector} from 'react-redux';
import {AvField, AvForm} from 'availity-reactstrap-validation';
import PhoneInput from "react-phone-input-2";
import profileImg from "../../assets/images/profile-img.png";
import {IntercomAPI} from "react-intercom";
import {updateUserInfo} from "../../store/auth/register/actions";
import {storage} from "../../helpers/config";

const ProfileInfo = React.forwardRef((props, ref) => {
    const [editable, setEditable] = useState(false);
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const user = useSelector(state => state?.Account?.user);
    const dispatch = useDispatch()

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    useEffect(() => {
        console.log('getContractorInfo');
        getClientInfo(user?.token).then(r => {
            console.log(r);
            if (r.data.success) {
                IntercomAPI('trackEvent', 'Viewed profile');
                setData({
                    ...r.data.data,
                    photo: r.data?.data?.photo ? `${storage}${r.data?.data?.photo}` : r.data?.data?.photo
                })
            }
        })
    }, [])
    return (
        <div>
            <div className="bg-soft-primary">
                <Row>
                    <Col>
                        <div className="text-primary p-3">
                            <h5 className="text-primary">Welcome Back !</h5>
                        </div>
                    </Col>
                    <Col lg={2} className="align-self-end">
                        <img src={profileImg} alt="" className="img-fluid"/>
                    </Col>
                </Row>
            </div>
            <CardBody className="pt-0">
                <Row className="justify-content-between">
                    <Col sm="2">
                        <div className="avatar-lg profile-user-wid mb-4">
                            {data?.photo ?
                                <img src={data?.photo} alt="" className="img-thumbnail rounded-circle"
                                     style={{height: "100%"}}></img> :
                                // <div className="rounded-circle header-profile-user">
                                <span
                                    className="avatar-title rounded-circle font-weight-bolder"
                                    style={{fontSize: "40px"}}>
                                                                                    {data?.first_name?.charAt(0)}
                                                                                </span>
                                // </div>
                            }
                            <div style={{
                                marginTop: "-40%",
                                marginLeft: "80%",
                            }}>
                                {editable && <div style={{position: 'absolute'}}>
                                    <Dropzone
                                        onDrop={acceptedFiles => {
                                            toBase64(acceptedFiles[0]).then(r => {
                                                console.log(r);
                                            })
                                        }}
                                    >
                                        {({getRootProps, getInputProps}) => (
                                            <div style={{
                                                zIndex: 99999999999,
                                            }}>
                                                <div
                                                    {...getRootProps()}
                                                >
                                                    <input {...getInputProps()} onChange={async (e) => {
                                                        console.log(e.target.files)
                                                        if (e.target.files[0]) {
                                                            IntercomAPI('trackEvent', 'Changed avatar')
                                                            const photo = await toBase64(e.target.files[0])
                                                            setData({...data, photo})
                                                        }
                                                    }}/>
                                                    <div className="avatar-xs mr-3">
                            <span
                                className="avatar-title rounded-circle bg-soft-dark text-white font-size-24"
                                style={{
                                    backgroundColor: 'ref'
                                }}>
                                                            <i className="bx bx-camera font-size-18"></i>
                            </span>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Dropzone>
                                </div>}
                            </div>
                        </div>
                    </Col>
                    <Link className="p-3"
                          onClick={() => setEditable(!editable)}>{editable ? 'View' : 'Edit Profile'}</Link>
                </Row>
                {/*<CardTitle>*/}
                {/*<Row className="justify-content-between p-2">*/}
                {/*    <span>Profile</span>*/}
                {/*    */}
                {/*</Row>*/}
                {/*</CardTitle>*/}
                <AvForm ref={ref} onValidSubmit={(e, v) => {
                    if (editable) {
                        setLoading(true)
                        const dataInfo = {
                            ...v,
                            phone: data?.phone,
                        }
                        if (data?.photo && !data?.photo?.startsWith("http")) {
                            dataInfo.photo = data?.photo
                        }
                        updateMemberProfile(user?.token, dataInfo).then(r => {
                            setLoading(false)
                            console.log(r);
                            if (r.data.success) {
                                setData({
                                    ...r.data.data,
                                    photo: r.data?.data?.photo ? `${storage}${r.data?.data?.photo}` : r.data?.data?.photo
                                });
                                dispatch(updateUserInfo(r.data?.data || {}))
                                setEditable(false)
                            }
                        }).catch(e => {
                            setLoading(false)
                        })
                    }
                    console.log(e, v);
                }}>
                    <FormGroup row>
                        <Label htmlFor="billing-name" md="2" className="col-form-label">First Name</Label>
                        <Col md="10">
                            {editable ?
                                <AvField type="text" name="first_name" value={data?.first_name} className="form-control"
                                         id="billing-first-name"/> : <p className="mt-2">
                                    {data?.first_name}
                                </p>}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label htmlFor="billing-name" md="2" className="col-form-label">Last Name</Label>
                        <Col md="10">
                            {editable ?
                                <AvField value={data?.last_name} name="last_name" type="text" className="form-control"
                                         id="billing-last-name"/> : <p className="mt-2">
                                    {data?.last_name}
                                </p>}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label htmlFor="billing-name" md="2" className="col-form-label">Phone</Label>
                        <Col md="10">
                            {editable ? <PhoneInput
                                // containerClass="form-control"
                                name="phone"
                                value={data?.phone}
                                inputClass="form-control"
                                inputStyle={{width: '100%'}}
                                country={'ae'}
                                enableAreaCodes
                                onChange={phone => setData({...data, phone})}
                            /> : <p className="mt-2">
                                {data?.phone}
                            </p>}
                        </Col>
                    </FormGroup>
                    {editable && <Row className="my-4">
                        <Col></Col>
                        <Col sm="6">
                            <div className="text-sm-right">
                                <Link to="#" onClick={() => {
                                    ref.current.submit()
                                }} className="btn btn-success">
                                    {loading &&
                                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>} Save </Link>
                            </div>
                        </Col>
                    </Row>}
                </AvForm>
            </CardBody>
        </div>
    );
})

export default ProfileInfo;

import React, {useEffect, useState} from 'react';
import {Col, Input, Label, Row} from "reactstrap";
import StripeForm from "../Payment/CheckoutForm";
import {addPaymentMethod, deleteCC, getContractors, getPaymentMethods} from "../../services/api";
import {useSelector} from "react-redux";
import toastr from "toastr";
import {useFetch} from "../../helpers/hooks";

const CreditCards = ({
                         onNext = () => {
                         }, onAccountAdded = () => {
    }, edit
                     }) => {
    const [cards, setCards] = useState([]);
    const [checkedCard, setCheckedCard] = useState(-1);
    const [editing, setEditing] = useState(false);
    const [actionLoading, setActionLoading] = useState(false);
    const user = useSelector(state => state.Account?.user)
    const deleteCard = useFetch({
        autoFetch: false, initResult: null, action: deleteCC, onComplete: () => {
            updateList()
        }
    })
    const updateList = () => {
        getPaymentMethods(user?.token).then(r => {
            console.log(r);
            if (r.data.success) {
                setCards(r.data.data)
            }
        })
    }
    useEffect(() => {
        updateList()
    }, [])
    const handleCCSubmitted = (stripData) => {
        console.log(stripData);
        if (stripData.error) {
            return
        }
        setActionLoading(true)
        addPaymentMethod(user?.token, {
            payment_method_id: stripData?.paymentMethod?.id

        }).then(r => {
            console.log(r);
            if (r.data.success) {
                setEditing(false)
                console.log(r.data);
                onAccountAdded(r.data.data)
                updateList()
                toastr.success(r.data.message)
            } else {
                setActionLoading(false)
            }
            console.log(r);
        }).catch(e => {
            setActionLoading(false)
        })
    }
    const CCItem = ({card, index}) => {
        const [deleting, setDeleting] = useState(false);
        return <Label className="card-radio-label mb-3">
            <Input type="radio" name="pay-method" id={"credit-card-" + index}
                   className="card-radio-input"
                   onClick={() => {
                       setCheckedCard(index)
                       onNext(card)
                   }}/>

            <div
                className={"card-radio p-2 rounded" + (checkedCard == index ? " border-primary" : " border-light")}>
                <Col>
                    <Row className="flex-nowrap">
                        <img className="rounded mr-2" alt="Skote" height="24"
                             src={card?.card?.logo}/>

                        <Col>
                            <h5 className={checkedCard == index ? "my-0 text-primary mb-2 bold" : "my-0 text-secondary mb-2 bold"}
                                style={{textJustify: 'center', alignItems: 'center'}}>
                                {card?.billing_details?.name || "Card Holder"}
                            </h5>
                            <p className="text-muted">**** **** **** {card?.card?.last4} <span
                                className="ml-3">{card?.card.exp_month}/{("" + card?.card?.exp_year).slice(2, 4)}</span>
                            </p>
                        </Col>
                        {deleting && deleteCard.isLoading ?
                            <i className="bx bx-loader bx-spin text-danger font-size-16 align-middle mr-2"></i> :
                            <a onClick={() => {
                                setDeleting(true)
                                deleteCard.startFetch({id: card?.id})
                            }}><i className="bx bx-trash text-danger"/></a>}
                    </Row>
                </Col>
            </div>
        </Label>
    }
    return (
        <Row className="justify-content-center">
            <Col className="p-0" md={8}>
                {!edit && <Row className="justify-content-center">
                    <Col md={8} className="border-bottom border-light">
                        <h1 className="font-weight-light text-center">Select an account</h1>
                    </Col>
                </Row>}
                {!editing ? <Row className="justify-content-center">
                    <Col md={8} className="pt-4 px-5">
                        {cards.map((e, i) => <CCItem card={e} index={i}/>)}
                        <a
                            onClick={() => setEditing(true)}
                            className="text-primary font-weight-bold px-0">+ Add New Card
                        </a>
                    </Col>
                </Row> : <Row className="justify-content-center mt-5">
                    <Col md={6}>
                        <StripeForm onSubmitted={handleCCSubmitted} loading={actionLoading}/>
                    </Col>
                </Row>
                }
            </Col>
        </Row>
    );
};

export default CreditCards;

import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga'
import {persistStore, persistReducer} from 'redux-persist';

import rootReducer from './reducers';
import rootSaga from './sagas';
import storage from 'redux-persist/lib/storage';
// import { asyncLocalStorage, asyncSessionStorage } from "redux-persist/storages"
const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['Account'],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));
sagaMiddleware.run(rootSaga);
export const persistor = persistStore(store);
export default store;

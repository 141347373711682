import React, {useEffect, useState} from 'react';

import {Col, Container, Form, FormGroup, Input, Label, Row, TabContent, TabPane} from "reactstrap";
import {Link} from "react-router-dom";
import UserTypeSelect from "./UserTypeSelect";
import {useDispatch, useSelector} from 'react-redux';
import {signup} from "../../services/api";
import {userTypes} from "../../helpers/enum";
import {withRouter} from "react-router";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
//Import Breadcrumb
import {registerUser, verifyOTPRequest} from "../../store/auth/register/actions";
import Select from "react-select";
import {AvField, AvForm} from "availity-reactstrap-validation";
import './../../pages/Authentication/style.css'
import ReactInputVerificationCode from 'react-input-verification-code';

const FormWizard = (props) => {

    const [activeTab, setActiveTab] = useState(1);
    const [userType, setUserType] = useState(-1);
    const [email, setEmail] = useState(null);
    const [code, setCode] = useState(null);
    const [firstName, setFirstName] = useState(null);
    const [lastName, setLastName] = useState(null);
    const [phone, setPhone] = useState(null);
    const [password, setPassword] = useState(null);
    const [companyName, setCompanyName] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedType, setSelectedType] = useState(null);

    const user = useSelector(state => state.Account?.user)
    const link = useSelector(state => state.Account?.link)
    const message = useSelector(state => state.Account?.registrationError)
    const companyTypes = useSelector(state => state.Layout?.staticData?.company_types)
    const isLogged = useSelector(state => state.Account?.loggedIn)
    const registerLoading = useSelector(state => state.Account?.loading || state.Account?.OTPloading)

    const handleTypeSelection = (s) => {
        setSelectedType(s)
    }

    function toggleTab(tab) {
        if (activeTab !== tab) {
            if (tab >= 1 && tab <= 4) {
                setActiveTab(tab);
            }
        }
    }

    const dispatch = useDispatch();
    useEffect(() => {
        if (user && user?.token) {
            toggleTab(activeTab + 1)
        }
    }, [user])

    useEffect(() => {
        if (message) {
            toastr.error(message, "error")
        }
    }, [message])
    useEffect(() => {
        if (isLogged) {
            if (link) {
                props.history.push(link)
            } else {
                props.history.push("/activity")
            }
        }
    }, [isLogged]);
    return (
        <>
            <div>
                <Container fluid={true} className="align-items-center">
                    <Row className="justify-content-center">
                        <Col xl={12} className="pt-3">
                            <div id="basic-pills-wizard" className="twitter-bs-wizard">
                                <Col xl={12} className="justify-content-center">
                                    <Row className="justify-content-center">
                                        <Col xl={9}>
                                            <Row className="align-items-center">
                                                {activeTab == 1 && <span className="bg-primary"
                                                                         style={{
                                                                             width: 10,
                                                                             height: 10,
                                                                             borderRadius: 5
                                                                         }}/>}
                                                <Col
                                                    className={activeTab >= 2 ? "bg-primary" : "bg-soft-secondary"}
                                                    style={{height: 2}}/>
                                                {activeTab == 2 && <span className="bg-primary"
                                                                         style={{
                                                                             width: 10,
                                                                             height: 10,
                                                                             borderRadius: 5
                                                                         }}/>}
                                                <Col
                                                    className={activeTab >= 3 ? "bg-primary" : "bg-soft-secondary"}
                                                    style={{height: 2}}/>
                                                {activeTab == 3 && <span className="bg-primary"
                                                                         style={{
                                                                             width: 10,
                                                                             height: 10,
                                                                             borderRadius: 5
                                                                         }}/>}
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-center">
                                        <Col xl={11}>
                                            <Row
                                                className="justify-content-between pt-3">
                                                <Col xl={3}
                                                     className={`${activeTab >= 1 ? "text-primary" : "text-muted"}`}
                                                     style={{textAlign: 'center'}}>Account type</Col>
                                                <Col xl={3}
                                                     className={`${activeTab >= 2 ? "text-primary" : "text-muted"}`}
                                                     style={{textAlign: 'center'}}>Email verification</Col>
                                                <Col xl={3}
                                                     className={`${activeTab >= 3 ? "text-primary" : "text-muted"}`}
                                                     style={{textAlign: 'center'}}>Your Info</Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                                <Row className="justify-content-center">
                                    <Col lg={8}>
                                        <div id="basic-pills-wizard" className="twitter-bs-wizard">
                                            <TabContent activeTab={activeTab} className="twitter-bs-wizard-tab-content">
                                                <TabPane tabId={1}>
                                                    <Row className="justify-content-center mb-3">
                                                        <h4>Account type</h4>
                                                    </Row>
                                                    <div>
                                                        <AvForm>
                                                            <Row className="justify-content-center">
                                                                <UserTypeSelect
                                                                    onUserTypeChanged={type => {
                                                                        setUserType(type)
                                                                    }}
                                                                    value={userType}
                                                                />

                                                            </Row>
                                                            {userType != -1 &&
                                                            <Row className="justify-content-center">
                                                                <Col>
                                                                    <FormGroup className="justify-content-center">
                                                                        <Label
                                                                            for="basicpill-pancard-input5">E-mail</Label>
                                                                        <AvField
                                                                            type="email"
                                                                            name="email"
                                                                            className="form-control"
                                                                            id="basicpill-pancard-input5"
                                                                            onChange={e => {
                                                                                setEmail(e.target.value);
                                                                            }}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>}
                                                        </AvForm>
                                                    </div>
                                                </TabPane>
                                                <TabPane tabId={2}>

                                                    <Row className="justify-content-center mb-3">
                                                        <h4>Check you email</h4>
                                                    </Row>
                                                    <Row className="justify-content-center mb-3">
                                                        <Col>
                                                            <p>
                                                                We've sent a confirmation code to <span
                                                                style={{fontWeight: '900'}}>{email}</span><br/> Enter
                                                                the
                                                                code soon,
                                                                as it
                                                                will expire shortly.
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                    <Form className="align-items-center">
                                                        <Row className="justify-content-center">
                                                            <Col className="align-items-center">
                                                                <FormGroup>

                                                                    <Row className="justify-content-center">
                                                                        <Col className="align-items-center">
                                                                            <Label for="basicpill-firstname-input1">Your
                                                                                Confirmation
                                                                                Code</Label>
                                                                            <Row className="justify-content-center p-4">
                                                                                <ReactInputVerificationCode
                                                                                    type='number'
                                                                                    length={4}
                                                                                    inputStyle={{
                                                                                        padding: '12px',
                                                                                        margin: '12px'
                                                                                    }}
                                                                                    onChange={(e) => {
                                                                                        setCode(e);
                                                                                    }}
                                                                                />
                                                                            </Row>
                                                                            <p>Haven't received our email? Try spam
                                                                                folder
                                                                                or <Link
                                                                                    onClick={() => {
                                                                                        signup(email, userType == 0 ? userTypes.CONTRACTOR : userTypes.COMPANY)
                                                                                            .then(r => {
                                                                                                console.log('signup_response', r);
                                                                                                setLoading(false)
                                                                                                if (!r.data.success) {
                                                                                                    toastr.error(r.data.message)
                                                                                                }
                                                                                            })
                                                                                            .catch(e => {
                                                                                                setLoading(false)
                                                                                            })
                                                                                    }}>resend it</Link></p>
                                                                        </Col>
                                                                    </Row>

                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </TabPane>
                                                <TabPane tabId={3}>
                                                    <Row className="justify-content-center mb-3">
                                                        <h4>General info</h4>
                                                    </Row>
                                                    <Form>
                                                        <Row>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Label for="basicpill-firstname-input1">First
                                                                        name</Label>
                                                                    <Input type="text" className="form-control"
                                                                           id="basicpill-firstname-input1"
                                                                           onChange={e => {
                                                                               setFirstName(e.target.value)
                                                                           }}/>
                                                                </FormGroup>

                                                                <FormGroup>
                                                                    <Label for="basicpill-lastname-input2">Last
                                                                        name</Label>
                                                                    <Input type="text" className="form-control"
                                                                           id="basicpill-lastname-input2"
                                                                           onChange={e => setLastName(e.target.value)}/>
                                                                </FormGroup>
                                                                {userType == 1 &&
                                                                <>
                                                                    <FormGroup>
                                                                        <Label for="basicpill-firstname-input1">Company
                                                                            name</Label>
                                                                        <Input type="text" className="form-control"
                                                                               id="basicpill-firstname-input1"
                                                                               onChange={e => setCompanyName(e.target.value)}/>
                                                                    </FormGroup>

                                                                    <FormGroup>
                                                                        <Label for="basicpill-firstname-input1">Company
                                                                            Type</Label>
                                                                        <Select
                                                                            value={selectedType}
                                                                            onChange={handleTypeSelection}
                                                                            options={companyTypes ? companyTypes.map(c => ({
                                                                                label: c.name,
                                                                                value: c.id,
                                                                            })) : []}
                                                                        />
                                                                    </FormGroup>
                                                                </>}

                                                                <FormGroup>
                                                                    <Label for="basicpill-phoneno-input3">Phone</Label>
                                                                    <PhoneInput
                                                                        inputClass="form-control"
                                                                        inputStyle={{
                                                                            width: '100%',
                                                                            paddingBottom: '10px'
                                                                        }}
                                                                        country={'ae'}
                                                                        enableAreaCodes
                                                                        onChange={phone => setPhone(phone)}
                                                                    />
                                                                </FormGroup>

                                                                <FormGroup>
                                                                    <Label for="basicpill-email-input4">Password</Label>
                                                                    <Input type="password" className="form-control"
                                                                           id="basicpill-email-input4"
                                                                           onChange={e => setPassword(e.target.value)}/>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </TabPane>
                                            </TabContent>
                                        </div>
                                    </Col>
                                </Row>
                                <ul className="pager wizard twitter-bs-wizard-pager-link">
                                    <li>
                                        {activeTab != 1 &&
                                        <button onClick={() => {
                                            if (activeTab == 3) {
                                                toggleTab(activeTab - 2);
                                            } else {
                                                toggleTab(activeTab - 1)
                                            }
                                        }} className="btn text-muted d-none d-sm-inline-block btn-link">
                                            <i className="mdi mdi-arrow-left mr-1"></i> Back
                                        </button>
                                        }
                                    </li>
                                    <li className={"next"}>
                                        <button onClick={() => {
                                            if (activeTab == 1) {
                                                if (userType == -1) {
                                                    toastr.error("Please set your account type", "Error")
                                                    return;
                                                }
                                                if (!email) {
                                                    toastr.error("Please set a valid Email", "Error")
                                                    return
                                                }
                                                setLoading(true)
                                                signup(email, userType == 0 ? userTypes.CONTRACTOR : userTypes.COMPANY).then(r => {
                                                    setLoading(false)
                                                    if (activeTab != 3 && r.data.success) {
                                                        setCode(null)
                                                        toggleTab(activeTab + 1);
                                                    } else {
                                                        toastr.error(r.data?.data?.error || r.data?.message, "Error")
                                                    }
                                                }).catch(e => {
                                                    toastr.error(e.toLocaleString())
                                                    setLoading(false)
                                                })
                                            }
                                            if (activeTab == 2) {
                                                if (!code) {
                                                    toastr.error("Set the confirmation code you received in your email")
                                                    return;
                                                }
                                                dispatch(verifyOTPRequest({
                                                    otp: code,
                                                    email,
                                                    type: userType == 0 ? userTypes.CONTRACTOR : userTypes.COMPANY
                                                }))
                                            }
                                            if (activeTab == 3) {
                                                if (!firstName || !lastName || !phone || !password) {
                                                    toastr.error("Make sure you fill all fields before submit")
                                                    return;
                                                }
                                                dispatch(registerUser(userType == 0 ? userTypes.CONTRACTOR : userTypes.COMPANY, {
                                                    email,
                                                    first_name: firstName,
                                                    last_name: lastName,
                                                    phone,
                                                    password,
                                                    company_name: companyName,
                                                    company_type_id: selectedType?.value
                                                }, user?.token))
                                            }


                                        }} type="button" className="btn btn-primary waves-effect waves-light">
                                            {(loading || registerLoading) &&
                                            <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>} {activeTab == 3 ? 'Register' : 'Next'}
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </div>
        </>
    );
}

export default withRouter(FormWizard);

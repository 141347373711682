import React, {useEffect} from 'react';

// MetisMenu
import MetisMenu from "metismenujs";
import {withRouter} from "react-router-dom";
import {Link} from "react-router-dom";

//i18n
import {withNamespaces} from 'react-i18next';
import {Button, Col, Row} from "reactstrap";
import { useSelector } from 'react-redux';
import {userTypes} from "../../helpers/enum";
import Footer from "./Footer";

const SidebarContent = (props) => {

    const display = useSelector(state => {
        console.log(state);
        return state.Account?.user?.type
    })
    console.log(display);
    // Use ComponentDidMount and ComponentDidUpdate method symultaniously
    useEffect(() => {

        var pathName = props.location.pathname;

        const initMenu = () => {
            new MetisMenu("#side-menu");
            var matchingMenuItem = null;
            var ul = document.getElementById("side-menu");
            var items = ul.getElementsByTagName("a");
            for (var i = 0; i < items.length; ++i) {
                if (pathName === items[i].pathname) {
                    matchingMenuItem = items[i];
                    break;
                }
            }
            if (matchingMenuItem) {
                activateParentDropdown(matchingMenuItem);
            }
        }
        initMenu();
    }, [props.location.pathname]);


    function activateParentDropdown(item) {
        item.classList.add("active");
        const parent = item.parentElement;

        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show");

                const parent3 = parent2.parentElement;

                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); //a
                    const parent4 = parent3.parentElement;
                    if (parent4) {
                        parent4.classList.add("mm-active");
                    }
                }
            }
            return false;
        }
        return false;
    };

    return (

        <React.Fragment>
            <div id="sidebar-menu">
                <Col>
                    <Row className="justify-content-center" style={{paddingBottom: '15px'}}>
                        <Link to="/contract/create">
                        <Button
                            color="success"
                            className="waves-effect waves-light px-4 py-2"
                        ><i style={{"margin-right": "10px"}} className="fas fa-plus"></i>
                            New Contract
                        </Button>
                        </Link>
                    </Row>
                </Col>
                <ul className="metismenu list-unstyled" id="side-menu">
                    <li>
                        <Link to="/activity" className="waves-effect font-size-14">
                            <i className="fas fa-clipboard-list"></i>
                            <span>{display == userTypes.COMPANY ? props.t('Activity') : props.t('Overview')}</span>
                        </Link>
                        <Link to="/contracts" className="waves-effect font-size-14">
                            <i className="fas fa-file-contract"></i>
                            <span>{props.t('Contracts')}</span>
                        </Link>
                        <Link to="/invoices" className="waves-effect font-size-14">
                            <i className="fas fa-file-invoice"></i>
                            <span>{props.t('Invoices')}</span>
                        </Link>
                        {display == userTypes.COMPANY && <Link to="/documents" className="waves-effect font-size-14">
                            <i className="fas fa-folder-open"></i>
                            <span>{props.t('Documents')}</span>
                        </Link>}
                        <Link to="/history" className="waves-effect font-size-14">
                            <i className="fas fa-history"></i>
                            <span>{props.t('Transactions')}</span>
                        </Link>
                    </li>
                </ul>
            </div>
        </React.Fragment>
    );
}

export default withRouter(withNamespaces()(SidebarContent));



import React, {useEffect, useState} from 'react';
import {
    CardBody,
    Col,
    Container,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label, Spinner
} from "reactstrap";
import Select from "react-select";
import Dropzone from "react-dropzone";
import Row from "reactstrap/es/Row";
import PhoneInput from "react-phone-input-2";
import AddressPicker from "../../components/AddressPicker";
import {Link} from "react-router-dom";
import {AvField, AvForm} from 'availity-reactstrap-validation';
import {getContractorInfo, updateContractorProfile} from "../../services/api";
import {useDispatch, useSelector} from 'react-redux';
import profileImg from "../../assets/images/profile-img.png";
import {updateUserInfo} from "../../store/auth/register/actions";
import {IntercomAPI} from "react-intercom";
import DatePicker from "react-datepicker";
import {CalendarIcon} from "../Contract/CreateContract/FormWizard";
import moment from "moment";
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'
import {useFetch} from "../../helpers/hooks";
import {load} from "dotenv";
import {storage} from "../../helpers/config";

const ProfileInfo = React.forwardRef((props, ref) => {
    const [selectedType, setSelectedType] = useState(null);
    const [editable, setEditable] = useState(false);
    const [data, setData] = useState({});
    const [completeAddress, setCompleteAddress] = useState({});
    const [documentTypes, setDocumentTypes] = useState(null);
    const [documentNumber, setDocumentNumber] = useState(null);
    const [loading, setLoading] = useState(true);
    const [citResidence, setCitResidence] = useState(null);
    const [taxResidence, setTaxResidence] = useState(null);
    const [birthDate, setBirthDate] = useState(null);

    const updateContractor = useFetch({
        autoFetch: false, initResult: null, action: updateContractorProfile, onComplete: data => {
            IntercomAPI('trackEvent', 'Edited profile')
            setData({
                ...data,
                photo: data?.photo ? `${storage}${data?.photo}` : data?.photo
            })
            dispatch(updateUserInfo(data || {}))
            setEditable(false)
        }
    })
    const dispatch = useDispatch();

    const user = useSelector(state => state?.Account?.user)
    const staticData = useSelector(state => state?.Layout?.staticData)

    function handleSelectType(type) {
        setSelectedType(type);
    }



    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    useEffect(() => {
            getContractorInfo(user?.token).then(r => {
                setLoading(false)
                console.log(r);
                if (r.data.success) {
                    setData({
                        ...r.data.data,
                        photo: r.data?.data?.photo ? `${storage}${r.data?.data?.photo}` : r.data?.data?.photo
                    })
                    setDocumentNumber(r?.data?.data?.document?.number)
                    setBirthDate(r.data.data.birth_date)
                    const cit = r.data?.data?.Country_of_Citizenship
                    const tax = r.data?.data?.Country_of_Tax_Residence
                    if (cit) {
                        setCitResidence({value: cit?.id, label: cit?.name})
                    }
                    if (tax) {
                        setTaxResidence({value: tax?.id, label: tax?.name})
                    }
                }
            }).catch(() => setLoading(false))
        }
        , [])

    useEffect(() => {
        setSelectedType({
            value: data?.currency?.id,
            label: data?.currency?.name
        });
    }, [data])

    useEffect(() => {
        console.log("Debugloading", data);
    }, [data])

    return (
        <div>
            <div className="bg-soft-primary">
                <Row>
                    <Col>
                        <div className="text-primary p-3">
                            <h5 className="text-primary">Welcome Back !</h5>
                        </div>
                    </Col>
                    <Col md='2' className="align-self-end">
                        <img src={profileImg} alt="" className="img-fluid"/>
                    </Col>
                </Row>
            </div>
            {loading ? <Container style={{minHeight: '30rem'}}>
                <Col style={{minHeight: '30rem'}}>
                    <Row style={{minHeight: '30rem'}} className="justify-content-center align-items-center">
                        <Spinner type="grow" className="mr-2" color="primary"/>
                    </Row>
                </Col>
            </Container> : <CardBody className="pt-0">
                <Row className="justify-content-between">
                    <Col sm="2">
                        <div className="avatar-lg profile-user-wid mb-4">
                            {data?.photo ?
                                <img
                                    src={data?.photo}
                                    alt=""
                                    className="img-thumbnail rounded-circle avatar-lg"
                                ></img> :
                                // <div className="rounded-circle header-profile-user">
                                <span
                                    className="avatar-title rounded-circle font-weight-bolder"
                                    style={{fontSize: "40px"}}>
                                                                                    {data?.first_name?.charAt(0)}
                                                                                </span>
                                // </div>
                            }
                            <div style={{
                                marginTop: "-40%",
                                marginLeft: "80%",
                            }}>
                                {editable && <div style={{position: 'absolute'}}>
                                    <Dropzone
                                        onDrop={acceptedFiles => {
                                            console.log(acceptedFiles)
                                            // file = acceptedFiles[0]
                                            // handleAcceptedFiles(acceptedFiles)
                                            toBase64(acceptedFiles[0]).then(r => {
                                                console.log(r);
                                            })
                                        }}

                                    >
                                        {({getRootProps, getInputProps}) => (
                                            <div>
                                                <div
                                                    {...getRootProps()}
                                                >
                                                    <input {...getInputProps()} onChange={async (e) => {
                                                        console.log(e.target.files)
                                                        if (e.target.files[0]) {
                                                            IntercomAPI('trackEvent', 'Changed avatar')
                                                            const photo = await toBase64(e.target.files[0])
                                                            setData({...data, photo})
                                                        }
                                                    }}/>
                                                    <div className="avatar-xs mr-3">
                            <span
                                className="avatar-title rounded-circle bg-soft-dark text-white font-size-24"
                            >
                                                            <i className="bx bx-camera font-size-18"></i>
                            </span>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Dropzone>
                                </div>}
                            </div>
                        </div>
                    </Col>
                    <Link className="p-3"
                          onClick={() => setEditable(!editable)}>{editable ? 'View' : 'Edit Profile'}</Link>
                </Row>
                <AvForm className="p-3" ref={ref} onValidSubmit={(e, v) => {
                    if (editable) {
                        const dataInfo = {
                            ...v,
                            country_id: completeAddress?.country?.value,
                            state_id: completeAddress?.state?.value,
                            city_id: completeAddress?.city?.value,
                            currency_id: selectedType?.value,
                            phone: data?.phone,
                            birth_date: birthDate,
                            document_type: documentTypes?.value,
                            document_number: documentNumber,
                            Country_of_Citizenship: citResidence?.value,
                            Country_of_Tax_Residence: taxResidence?.value
                        };
                        console.log(dataInfo);
                        if (!completeAddress?.country?.value ||
                            !completeAddress?.state?.value ||
                            !completeAddress?.city?.value ||
                            !citResidence?.value ||
                            !taxResidence?.value ||
                            !selectedType?.value ||
                            !data?.phone ||
                            !birthDate ||
                            !documentTypes?.value ||
                            !documentNumber) {
                            toastr.error("You should fill all required fields")
                            return
                        }
                        props.onStart()
                        if (data?.photo && !data?.photo?.startsWith("http")) {
                            dataInfo.photo = data?.photo
                        }
                        console.log(dataInfo);
                        updateContractor.startFetch(dataInfo)
                    }
                    console.log(e, v);
                }}>
                    <FormGroup className="mb-4" row>
                        <Label md="3" className="col-form-label">First Name {editable && <span
                            className="text-danger">*</span>}</Label>
                        <Col md="9" className="p-0">
                            {editable ?
                                <AvField name="first_name" value={data?.first_name} type="text" className="form-control"
                                         id="billing-first-name"/> :
                                <p className="mt-2">{data?.first_name}</p>}
                        </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                        <Label md="3" className="col-form-label">Last Name {editable && <span
                            className="text-danger">*</span>}</Label>
                        <Col md="9" className="p-0">
                            {editable ?
                                <AvField name="last_name" value={data?.last_name} type="text" className="form-control"
                                         id="billing-last-name"/> :
                                <p className="mt-2">{data?.last_name}</p>}
                        </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                        <Label md="3" className="col-form-label">Phone {editable && <span
                            className="text-danger">*</span>}</Label>
                        <Col md="9" className="p-0">
                            {editable ? <PhoneInput
                                containerClass="mb-3"
                                name="phone"
                                value={data?.phone}
                                inputClass="form-control"
                                inputStyle={{width: '100%'}}
                                country={'ae'}
                                enableAreaCodes
                                onChange={phone => setData({...data, phone})}
                            /> : <p className="mt-2">
                                {data?.phone}
                            </p>}
                        </Col>
                    </FormGroup>
                    <FormGroup className="select2-container mb-4" row>
                        <Label md="3" className="col-form-label">Country of Citizenship {editable && <span
                            className="text-danger">*</span>}</Label>
                        <Col md="9" className="p-0">
                            {editable ? <Select
                                className="mb-3"
                                value={citResidence}
                                onChange={(s) => {
                                    setCitResidence(s);
                                }}
                                options={staticData?.countries?.map(e => ({value: e.id, label: e.name}))}
                            /> : <p className="mt-2">
                                {citResidence?.label}
                            </p>}
                        </Col>
                    </FormGroup><FormGroup className="select2-container mb-4" row>
                    <Label md="3" className="col-form-label">Country of Tax Residence {editable && <span
                        className="text-danger">*</span>}</Label>
                    <Col md="9" className="p-0">
                        {editable ? <Select
                            className="mb-3"
                            value={taxResidence}
                            onChange={(s) => {
                                setTaxResidence(s);
                            }}
                            options={staticData?.countries?.map(e => ({value: e.id, label: e.name}))}
                        /> : <p className="mt-2">
                            {taxResidence?.label}
                        </p>}
                    </Col>
                </FormGroup><FormGroup className="select2-container mb-4" row>
                    <Label md="3" className="col-form-label">Tax ID (optional)</Label>
                    <Col md="9" className="p-0">
                        {editable ? <AvField value={data?.Tax_ID} name="Tax_ID" className="form-control"
                                             id="Tax_ID"></AvField> : <p className="mt-2">
                            {data?.Tax_ID}
                        </p>}
                    </Col>
                </FormGroup>
                    <FormGroup className="select2-container mb-4" row>
                        <Label md="3" className="col-form-label">Address {editable && <span
                            className="text-danger">*</span>}</Label>
                        <Col md="9" className="p-0">
                            {editable ? <AvField value={data?.address} name="address" className="form-control"
                                                 id="company-address"></AvField> : <p className="mt-2">
                                {data?.address}
                            </p>}
                        </Col>
                    </FormGroup>
                    <AddressPicker
                        data={data}
                        editable={editable}
                        onChange={setCompleteAddress}
                    />
                    <FormGroup className="select2-container mb-4" row>
                        <Label md="3" className="col-form-label">Currency {editable && <span
                            className="text-danger">*</span>}</Label>
                        <Col md="9" className="p-0">
                            {editable ? <Select
                                className="mb-3"
                                value={selectedType}
                                onChange={(s) => {
                                    handleSelectType(s);
                                }}
                                options={staticData?.currencies?.map(e => ({value: e.id, label: e.name}))}
                            /> : <p className="mt-2">
                                {data?.currency?.name}
                            </p>}
                        </Col>
                    </FormGroup>
                    <FormGroup className="select2-container mb-4" row>
                        <Label md="3" className="col-form-label">ID / Passport {editable && <span
                            className="text-danger">*</span>}</Label>
                        <Col md="9" className="p-0">

                            {editable ? <InputGroup className="mb-3">
                                    <Input
                                        placeholder="ID / Passport" name="document_type"
                                        type="text"
                                        defaultValue={data?.document?.number}
                                        onChange={(e) => {
                                            console.log(e.target.value);
                                            setDocumentNumber(e.target.value)
                                        }}/>
                                    <InputGroupAddon
                                        style={{width: '25%', zIndex: 2}}
                                        addonType="append">
                                        <InputGroupText className="p-0"
                                                        style={{width: '100%'}}>
                                            <div style={{width: '100%'}}>
                                                <Select
                                                    className="border-0"
                                                    name="currency_id"
                                                    value={documentTypes}
                                                    onChange={(s) => {
                                                        setDocumentTypes(s);
                                                    }}
                                                    styles={customStyles}
                                                    options={staticData?.document_types?.map(e => ({
                                                        value: e.id, label: e.name
                                                    }))}
                                                />
                                            </div>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup> :
                                <p className="mt-2">
                                    {data?.document?.number}
                                </p>}
                        </Col>
                    </FormGroup>
                    <FormGroup className="mb-4" row>
                        <Label md="3" className="col-form-label">Birth Date {editable && <span
                            className="text-danger">*</span>}</Label>
                        <Col md="9" className="p-0">
                            {editable ? <><DatePicker
                                    name="start-date"
                                    className="form-control"
                                    selected={birthDate ? new Date(birthDate) : null}
                                    onChange={(v) => {
                                        setBirthDate(moment(v).format('YYYY-MM-DD'))
                                    }}
                                    style={{
                                        width: '100%'
                                    }}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                />
                                    <CalendarIcon/></> :
                                <p className="mt-2">
                                    {data?.birth_date}
                                </p>}
                        </Col>
                    </FormGroup>
                    {editable && <Col className="p-0 m-0">
                        <Row className="p-0 m-0 justify-content-end">

                            <div className="text-sm-right">
                                <Link to="#" onClick={() => {
                                    ref.current.submit()
                                }} className="btn btn-success">
                                    {updateContractor.isLoading &&
                                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>} Save </Link>
                            </div>
                        </Row>
                    </Col>
                    }
                </AvForm>
            </CardBody>}
        </div>
    );
})

const customStyles = {

    dropdownIndicator: () => ({
        // color: '#556ee6',
        paddingRight: '10px',
        paddingLeft: '10px'
    }),
    indicatorSeparator: () => ({
        // width: 0
    }),
    control: () => ({
        display: 'flex',
        flexDirection: 'row',
        // backgroundColor: 'red'
    }),

}

export default ProfileInfo;

import React, {Component} from 'react';
import {Col, Card, CardBody, Row, Button} from "reactstrap";
import {Link} from "react-router-dom";

class MiniWidget extends Component {
    render() {
        const {simple, withdraw, pay} = this.props
        return (
            <React.Fragment>
                {
                    this.props.reports.map((report, key) => {
                        let formatter = null;
                        if (report.value?.currency) {
                            formatter = new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: report.value?.currency || "USD",
                            });
                        }
                        console.log(report);
                        return <Col sm="4" key={key}>
                            <Card className="h-75 px-2">
                                <CardBody>
                                    <Row className="justify-content-between align-items-center mb-2">
                                        <h5 className="mb-0">{report.title}</h5>

                                        <span
                                            className="text-primary font-size-20">
                                                            <i className={report.icon}></i>
                                                        </span>
                                    </Row>
                                    <Row className="text-muted justify-content-between align-items-center">
                                        <h2 style={{color: '#495057'}}>{formatter ? formatter.format(report?.value?.value) : report?.value?.amount}</h2>
                                        {!simple && <div className="d-flex">
                                            <div>
                                                    <span style={{color: '#495057'}}
                                                          className={"badge badge-soft-" + report.color + " font-size-12 ml-2"}> {report.badgeValue} </span>

                                            </div>
                                            <span style={{color: '#495057'}}
                                                  className="ml-2 text-truncate">{report.desc}</span>
                                        </div>}
                                        {!((withdraw && key == 0) || (pay && key == 1)) &&
                                        <Link to={report.link} className="text-primary ml-2">View detail</Link>}
                                        {withdraw && key == 0 && report.value?.value != 0 && <div>
                                            <Link to={'/withdraw'}>
                                                <Button type="button" color="primary">{"Withdraw"}</Button>
                                            </Link>
                                        </div>}
                                        {pay && key == 1 && report.value?.value != 0 && <div>
                                            <Link to={'/payment'}>
                                                <Button type="button" color="primary">{"Mass Pay"}</Button>
                                            </Link>
                                        </div>}
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    }
                )
                }

            </React.Fragment>
        );
    }
}

export default MiniWidget;

import React, {useState} from 'react';
import UiCards from "../Cards/UiCards";
import {Card, CardBody, CardHeader, CardText, CardTitle, Col, Container, Input, Label, Row} from "reactstrap";
import {useDispatch} from 'react-redux';
import {updateDisplay} from "../../store/layout/actions";

const UserTypeSelect = ({onUserTypeChanged, value}) => {
    const [selected, setSelected] = useState(value);
    const options = [
        {title: 'Contractor', description: "Get paid by my client"},
        {title: 'Company', description: "Pay my remote team"}];
    const dispatch = useDispatch();
    return (
        <Container fluid={true}>
            <Row className="justify-content-between">
                <Col>{options.map((e, i) =>
                    <a href="#">
                        <Card
                            outline color={i == selected ? "primary" : "secondary"}
                            className={i == selected ? "text-primary border" : "text-secondary border"}>
                            <CardHeader className="bg-transparent">
                                <Row className="align-items-start pl-2">
                                    <div className="custom-control custom-radio custom-control-inline mr-2">
                                        <Input onClick={() => {
                                            setSelected(i);
                                            onUserTypeChanged(i)
                                        }} type="radio" value={selected} id={`CTRadioInline${i}`}
                                               name="customRadioInline1" className="custom-control-input"/>
                                        <Label className="custom-control-label mr-0" htmlFor={`CTRadioInline${i}`}>
                                            <Col>
                                                <h5 className={i == selected ? "my-0 text-primary mb-2 bold" : "my-0 text-secondary mb-2 bold"}
                                                    style={{textJustify: 'center', alignItems: 'center'}}>
                                                    {e.title}
                                                </h5>
                                                <p className="text-secondary text-muted font-size-12 mb-0">{e.description}</p>
                                            </Col>
                                        </Label>
                                    </div>
                                </Row>
                            </CardHeader>
                        </Card>
                    </a>
                )}</Col>
            </Row>
        </Container>
    );
}

export default UserTypeSelect;

import React from 'react';
import {Col, Container, Row} from "reactstrap";
import {withNamespaces} from 'react-i18next';
import FormWizard from "./FormWizard";

const CreateContract = (props) => {
    return (
        <div className="page-content">
            <Container fluid>
                <Row className="justify-content-center">
                    <Col md="10">
                        <FormWizard/>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default withNamespaces()(CreateContract);

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import {logout, logoutUser} from '../../store/actions';

const Logout = (props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        // props.logoutUser(props.history);
        dispatch(logout())
        props.history.push('/login')
      });

    return (
            <></>
          );
    }

export default withRouter(Logout);

import React, {useState} from 'react';
import {declineMilestone, declineWork} from "../../../../services/api";
import {FormGroup, Input, Label, Modal} from "reactstrap";
import {useSelector} from "react-redux";
import {IntercomAPI} from "react-intercom";

const DeclineModal = ({isOpen, toggle, onDeclined, isMilestone, e, data}) => {
    const [comment, setComment] = useState(null);
    const user = useSelector(state => state?.Account?.user)
    return (
        <Modal
            isOpen={isOpen}
            toggle={toggle}
        >
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title mt-0">Decline</h5>
                    <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={toggle}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <FormGroup>
                        <Label>Comment</Label>
                        <Input
                            name={`name`}
                            placeholder="Comment"
                            type="text"
                            className="form-control"
                            onChange={e => setComment(e.target.value)}
                            id="basicpill-pancard-input5"/>
                    </FormGroup>
                </div>
                <div className="modal-footer">
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => {
                            if (isMilestone){
                                declineMilestone(user.token, {work_id: e?.id, comment}).then(r => {
                                    if (r.data.success) {
                                        toggle()
                                        onDeclined()
                                    }
                                    console.log(r);
                                }).catch(e => {
                                    console.log(e);
                                });
                            }else {
                                declineWork(user.token, {work_id: e?.id, comment}).then(r => {
                                    if (r.data.success) {
                                        IntercomAPI('trackEvent','Declined work', {
                                            contract_id: data.ref,
                                            contract_type: data.type,
                                            work_name: e?.details,
                                            work_value: e?.qty,
                                            currency: data.currency?.code
                                        })
                                        toggle()
                                        onDeclined()
                                    }
                                    console.log(r);
                                }).catch(e => {
                                    console.log(e);
                                })
                            }
                        }}
                    >
                        Decline
                    </button>
                    <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                        onClick={toggle}
                    >
                        Cancel
                    </button>
                </div>
            </div>

        </Modal>
    );
};

export default DeclineModal;

import React from 'react';
import {Badge, Col, Row, Table} from "reactstrap";
import {Link} from "react-router-dom";
import {storage} from "../../../helpers/config";
import {useSelector} from "react-redux";
import NoContent from "../../../components/NoContent";
import {withRouter} from "react-router";

const ContractList = (props) => {
    const formatter = new Intl.NumberFormat('en-US', {
        // style: 'currency',
        // currency: '',
        maximumSignificantDigits: 3
    });
    const user = useSelector(state => state?.Account?.user)

    const getStatusColor = (status) => {
        switch (status){
            case "Ongoing":
                return "success";
            case "Ended":
                return "danger";
            case "Terminated":
                return "danger";
            default:
                return "warning"
        }
    }
    return props.Orders && props.Orders?.length > 0 ? (
            <div className="table-responsive">
                <Table className="table table-centered table-nowrap text-muted">
                    <thead className="has-border-top-width-5">
                    <tr>
                        <th className="border-top-0">Contract ID</th>
                        <th className="border-top-0">Created on</th>
                        <th className="border-top-0">Name</th>
                        <th className="border-top-0">Type</th>
                        <th className="text-right border-top-0">Rate</th>
                        <th className="border-top-0">{user?.type == 'client' ? "Contractor" : "Client"}</th>
                        <th className="border-top-0">Status</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        props.Orders.map((order, key) => {
                                const secondPart = user?.type == 'client' ? order?.contractor : order?.client
                                const formatter = new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: order?.currency?.code || "USD",
                                });
                                return (<tr key={"_order_" + key}>
                                    <td>
                                        <Link className="text-muted border-bottom border-secondary" to={`/contract/detail?id=${order.ref}`}>
                                            {order.ref}
                                        </Link>
                                    </td>
                                    <td>
                                        {order.created_at}
                                    </td>
                                    <td>
                                        {order.name || ""}
                                    </td>
                                    <td>
                                        {order.type}
                                    </td>
                                    <td className="text-right">
                                        {order.total}
                                    </td>
                                    <td>
                                        <Col>
                                            <Row className="align-items-center flex-nowrap">
                                                {secondPart ?
                                                    !secondPart?.photo
                                                        ? <div className="avatar-xs">
                                            <span
                                                className="avatar-title rounded-circle">
                                            {secondPart?.first_name ? secondPart?.first_name?.charAt(0) : secondPart?.email?.charAt(0)}
                                            </span>
                                                        </div>
                                                        : <div>
                                                            <img className="rounded-circle avatar-xs"
                                                                 src={`${storage}${secondPart?.photo}`} alt=""/>
                                                        </div>
                                                    : <div className="py-2"><Link to={`/contract/detail?id=${order.ref}`}
                                                                                  className="font-weight-bold">Invite</Link>
                                                    </div>}
                                                <div
                                                    style={{marginLeft: "10px"}}>{secondPart?.first_name || secondPart?.first_name ? `${secondPart?.first_name || ""} ${secondPart?.last_name || ""}` : secondPart?.email}</div>
                                            </Row>
                                        </Col>
                                    </td>
                                    <td>
                                        <div>
                                            <Badge
                                                className={`font-size-12 rounded badge-${getStatusColor(order?.status?.name)}`}
                                                color="white"
                                                pill>{order?.status?.name}</Badge>
                                        </div>
                                    </td>
                                </tr>)
                            }
                        )
                    }

                    </tbody>
                </Table>
            </div>
        ) :
        <NoContent
            image={require('./../../../assets/images/empty_contract.png')}
            subtitle={user?.type == "client" ? undefined : "Manage contracts, invoices and get paid in +126 currencies"}
            title={''}
            withAction
            actionTitle="New Contract"
            onAction={() => {
                props.history.push('/contract/create')
            }}
        />
        ;
}

export default withRouter(ContractList);

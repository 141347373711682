import React from 'react';
import {Col, Row, Button} from "reactstrap";

const ConfirmationPage = ({onComplete, data}) => {
    return (
        <Row className="justify-content-center">
            <Col className="p-0" xl={8}>
                <Row className="justify-content-center">
                    <Col xl={8} className="border-bottom border-light">
                        <h1 className="font-weight-light text-center">Withdrawal processing</h1>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col xl={8}>
                        <Row className="justify-content-center py-5" style={{fontSize: '10rem'}}>
                            <i className="bx bx-check-circle text-primary"/>
                        </Row>
                        <Row className="justify-content-center text-center font-size-16">
                            <p>Your withdrawal request has been received and currently processing.
                                Allow 1 to 5 business days to receive your funds.
                                Transaction ID: {data?.ref} </p>
                        </Row>
                    </Col>
                    <Col xl={6}>
                        <Button onClick={onComplete} className="btn-block my-2 font-weight-bold" color="primary">Go to Home page</Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default ConfirmationPage;
